define("ember-svg-jar/inlined/apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>AppsIcon@2x</title><g fill=\"#919EAB\" fill-rule=\"evenodd\"><path d=\"M17.1 14.066l-6.65 2.216v-5.597l6.65-2.217v5.598zM1.9 8.468l6.65 2.217v5.597L1.9 14.066V8.468zM18.605 6.38a.945.945 0 00-.855-.13L9.5 9 1.25 6.25a.944.944 0 00-.855.13.946.946 0 00-.395.77v7.6c0 .41.261.772.65.901l8.55 2.85a.93.93 0 00.6 0l8.55-2.85a.949.949 0 00.65-.9v-7.6a.946.946 0 00-.395-.77z\"/><path d=\"M9.5 4.3c-1.574 0-2.85.639-2.85 1.425 0 .787 1.276 1.425 2.85 1.425s2.85-.638 2.85-1.425c0-.786-1.276-1.425-2.85-1.425M3.8 5.25c1.574 0 2.85-.638 2.85-1.425C6.65 3.04 5.374 2.4 3.8 2.4S.95 3.04.95 3.825c0 .787 1.276 1.425 2.85 1.425M15.2 5.25c1.574 0 2.85-.638 2.85-1.425 0-.786-1.276-1.425-2.85-1.425s-2.85.639-2.85 1.425c0 .787 1.276 1.425 2.85 1.425M9.5 3.35c1.574 0 2.85-.638 2.85-1.425C12.35 1.14 11.074.5 9.5.5s-2.85.639-2.85 1.425c0 .787 1.276 1.425 2.85 1.425\"/></g>",
    "attrs": {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});