define("ember-smile-core/components/polaris-liquid-if", ["exports", "@ember/component", "ember-smile-core/templates/components/polaris-liquid-if"], function (_exports, _component, _polarisLiquidIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is to be used where we want a Polaris component such as a layout section
   * whose entire content is wrapped in a liquid-if. If we don't use this component, Polaris
   * styles will add padding between the surrounding components, messing up the layout.
   */
  var PolarisLiquidIfComponent = _component.default.extend({
    tagName: '',
    layout: _polarisLiquidIf.default,

    /**
     * The condition to pass to the liquid-if.
     *
     * @public
     * @type {bool}
     * @default false
     */
    condition: false,

    /**
     * The Polaris component the liquid-if is to be wrapped in.
     * If null, we'll use a `no-op` component.
     *
     * @public
     * @type {Component}
     * @default null
     */
    wrapperComponent: null,

    /**
     * Classes to apply to the Polaris wrapper component when `condition` is truthy.
     *
     * @public
     * @type {String}
     * @default null
     */
    wrapperComponentClasses: null,

    /**
     * Class to apply to the liquid-if component (for custom animations)
     * @type {String}
     * @public
     */
    liquidIfClasses: 'polaris-liquid-if-overflow-visible'
  });

  PolarisLiquidIfComponent.reopenClass({
    positionalParams: ['condition']
  });
  var _default = PolarisLiquidIfComponent;
  _exports.default = _default;
});