define("ember-smile-core/components/polaris-resource-list/row", ["exports", "@ember/component", "@ember/object", "@ember/string", "ember-smile-core/templates/components/polaris-resource-list/row"], function (_exports, _component, _object, _string, _row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alignments = ['center', 'top'];
  /**
   * Row to be rendered inside a
   * `polaris-resource-list/item`
   * to enable table-style rendering.
   */

  var _default = _component.default.extend({
    classNames: ['Polaris-ResourceList-Row'],
    classNameBindings: ['alignmentClass'],
    layout: _row.default,

    /**
     * List of cells to render in the row.
     *
     * @property cells
     * @type {Array}
     * @default null
     * @public
     */
    cells: null,

    /**
     * Vertical alignment between the cells.
     *
     * @property alignment
     * @type {String}
     * @default 'center'
     * @public
     */
    alignment: 'center',
    alignmentClass: (0, _object.computed)('alignment', function () {
      var alignment = this.alignment;

      if (alignments.indexOf(alignment) === -1) {
        alignment = alignments[0];
      }

      return "Polaris-ResourceList-Row--alignment".concat((0, _string.classify)(alignment));
    }).readOnly()
  });

  _exports.default = _default;
});