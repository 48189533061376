define("ember-svg-jar/inlined/avatar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-4{fill:#8dc958}</style></defs><path d=\"M0 0h100v100H0z\" fill=\"#245b48\"/><path d=\"M69 0v65.65l-2-.01v19.85l2 .02V100H0V0h69z\" fill=\"#2db167\"/><path d=\"M67 65.64v19.85l-25.57-.19C32 85.22 25.6 81.46 25.68 72.06s.25-6.74.25-6.74z\" fill=\"#fff\"/><path class=\"cls-4\" d=\"M86.99 58H69V0h10.93l7.06 58z\"/><path d=\"M24.53 40.29a12.23 12.23 0 0124.46 0\" fill=\"#ebedf1\" opacity=\".2\"/><path class=\"cls-4\" d=\"M59.62 58A10.69 10.69 0 0181 58z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});