define("ember-svg-jar/inlined/polaris/checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#919EAB\" fill-rule=\"evenodd\"><path d=\"M8.315 13.859l-3.182-3.417a.506.506 0 010-.684l.643-.683a.437.437 0 01.642 0l2.22 2.393 4.942-5.327a.437.437 0 01.643 0l.643.684a.504.504 0 010 .683l-5.91 6.35a.437.437 0 01-.642 0\"/><path d=\"M8.315 13.859l-3.182-3.417a.506.506 0 010-.684l.643-.683a.437.437 0 01.642 0l2.22 2.393 4.942-5.327a.437.437 0 01.643 0l.643.684a.504.504 0 010 .683l-5.91 6.35a.437.437 0 01-.642 0\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});