define("ember-svg-jar/inlined/polaris/avatar-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M-.01 0h100v100h-100z\" fill=\"#1e265c\"/><path d=\"M-.01 0h69.02v100H-.01z\" fill=\"#5d6cc1\"/><path d=\"M68.93 65.88l-24.44-.19-.05 6.09c0 5.22 3.48 9.47 8.69 9.52l15.8.12z\" fill=\"#fff\"/><path d=\"M68.91 11.3l19.17 46.29-19.17-.16V11.3z\" fill=\"#ffc04d\"/><path d=\"M23.25 36.43a3.22 3.22 0 100 6.44m21.01-6.44a3.22 3.22 0 000 6.44m-13.55 3.74a3.22 3.22 0 100 6.44m3.22-25.51a3.22 3.22 0 000 6.44\" stroke=\"#fff\" stroke-miterlimit=\"10\" fill=\"#fff\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});