define("ember-smile-core/templates/components/polaris-app-bottom-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PKjvtdyb",
    "block": "[[[6,[39,0],null,[[\"name\"],[\"polaris-app-bottom-bar\"]],[[\"default\"],[[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"bottom-bar-container\"],[12],[1,\"\\n      \"],[46,[30,1],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"bottomBar\"],false,[\"from-elsewhere\",\"if\",\"component\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-app-bottom-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});