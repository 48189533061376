define("ember-smile-core/adapters/errors", ["exports", "@ember-data/adapter/error", "ember-smile-core/adapters/errors/bad-request", "ember-smile-core/adapters/errors/payment-required", "ember-ajax/errors"], function (_exports, _error, _badRequest, _paymentRequired, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBadRequestError = isBadRequestError;
  _exports.isUnauthorizedError = isUnauthorizedError;
  _exports.isPaymentRequiredError = isPaymentRequiredError;
  _exports.isForbiddenError = isForbiddenError;
  _exports.isNotFoundError = isNotFoundError;
  _exports.isInvalidError = isInvalidError;
  _exports.isAbortError = isAbortError;
  _exports.isTimeoutError = isTimeoutError;
  _exports.isServerError = isServerError;
  Object.defineProperty(_exports, "AdapterError", {
    enumerable: true,
    get: function get() {
      return _error.default;
    }
  });
  Object.defineProperty(_exports, "InvalidError", {
    enumerable: true,
    get: function get() {
      return _error.InvalidError;
    }
  });
  Object.defineProperty(_exports, "UnauthorizedError", {
    enumerable: true,
    get: function get() {
      return _error.UnauthorizedError;
    }
  });
  Object.defineProperty(_exports, "ForbiddenError", {
    enumerable: true,
    get: function get() {
      return _error.ForbiddenError;
    }
  });
  Object.defineProperty(_exports, "NotFoundError", {
    enumerable: true,
    get: function get() {
      return _error.NotFoundError;
    }
  });
  Object.defineProperty(_exports, "ConflictError", {
    enumerable: true,
    get: function get() {
      return _error.ConflictError;
    }
  });
  Object.defineProperty(_exports, "ServerError", {
    enumerable: true,
    get: function get() {
      return _error.ServerError;
    }
  });
  Object.defineProperty(_exports, "TimeoutError", {
    enumerable: true,
    get: function get() {
      return _error.TimeoutError;
    }
  });
  Object.defineProperty(_exports, "AbortError", {
    enumerable: true,
    get: function get() {
      return _error.AbortError;
    }
  });
  Object.defineProperty(_exports, "errorsHashToArray", {
    enumerable: true,
    get: function get() {
      return _error.errorsHashToArray;
    }
  });
  Object.defineProperty(_exports, "errorsArrayToHash", {
    enumerable: true,
    get: function get() {
      return _error.errorsArrayToHash;
    }
  });
  Object.defineProperty(_exports, "BadRequestError", {
    enumerable: true,
    get: function get() {
      return _badRequest.default;
    }
  });
  Object.defineProperty(_exports, "PaymentRequiredError", {
    enumerable: true,
    get: function get() {
      return _paymentRequired.default;
    }
  });

  /**
   * Checks if the given error is an ember-data OR ember-ajax BAD REQUEST error,
   * or if error is equal to 400
   */
  function isBadRequestError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isBadRequestError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _badRequest.default;
    } else {
      return error === 400;
    }
  }
  /**
   * Checks if the given error is an ember-data OR ember-ajax UNAUTHORIZED error,
   * or if error is equal to 401
   */


  function isUnauthorizedError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isUnauthorizedError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _error.UnauthorizedError;
    } else {
      return error === 401;
    }
  }
  /**
   * Checks if the given error is an ember-data PAYMENT REQUIRED error,
   * or if error is equal to 402
   */


  function isPaymentRequiredError(error) {
    if (error instanceof _error.default) {
      return error instanceof _paymentRequired.default;
    } else {
      return error === 402;
    }
  }
  /**
   * Checks if the given error is an ember-data OR ember-ajax FORBIDDEN error,
   * or if error is equal to 403
   */


  function isForbiddenError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isForbiddenError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _error.ForbiddenError;
    } else {
      return error === 403;
    }
  }
  /**
   * Checks if the given error is an ember-data OR ember-ajax NOT FOUND error,
   * or if error is equal to 404
   */


  function isNotFoundError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isNotFoundError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _error.NotFoundError;
    } else {
      return error === 404;
    }
  }
  /**
   * Checks if the given error is an ember-data OR ember-ajax INVALID error,
   * or if error is equal to 422
   */


  function isInvalidError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isInvalidError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _error.InvalidError;
    } else {
      return error === 422;
    }
  }
  /**
   * Checks if the given error is an ember-data OR ember-ajax ABORT error,
   * or if error is equal to 0
   */


  function isAbortError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isAbortError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _error.AbortError;
    } else {
      return error === 0;
    }
  }
  /**
   * Checks if the given error is an ember-data OR ember-ajax ABORT error,
   * or if error is equal to 0
   */


  function isTimeoutError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isTimeoutError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _error.TimeoutError;
    }
  }
  /**
   * Checks if the given error is an ember-data OR ember-ajax SERVER error,
   * or if error is >= 500 & < 600
   */


  function isServerError(error) {
    if ((0, _errors.isAjaxError)(error)) {
      return (0, _errors.isServerError)(error);
    } else if (error instanceof _error.default) {
      return error instanceof _error.ServerError;
    } else {
      return error >= 500 && error < 600;
    }
  }
});