define("@smile-io/ember-smile-polaris/templates/components/polaris-date-picker/day", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oH3uBB20",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"button\"],[24,\"data-test-date-picker-day\",\"\"],[16,\"data-test-date-picker-date\",[30,0,[\"dataTestDatePickerDate\"]]],[24,\"role\",\"gridcell\"],[16,0,[30,0,[\"dayButtonClasses\"]]],[16,\"tabindex\",[30,0,[\"tabIndex\"]]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,\"aria-selected\",[30,0,[\"selected\"]]],[16,\"aria-disabled\",[30,0,[\"disabled\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"onClick\"]],[30,1]],null]],null],[4,[38,1],[\"mouseover\",[28,[37,2],[[30,0,[\"onHover\"]],[30,1]],null]],null],[4,[38,1],[\"focus\",[28,[37,2],[[30,0,[\"onFocus\"]],[30,1]],null]],null],[12],[1,\"\\n    \"],[1,[30,0,[\"date\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,\"data-test-date-picker-day-empty\",\"\"],[14,0,\"Polaris-DatePicker__EmptyDay\"],[12],[13],[1,\"\\n\"]],[]]]],[\"@day\"],false,[\"if\",\"on\",\"fn\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-date-picker/day.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});