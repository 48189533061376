define("@smile-io/ember-smile-polaris/templates/components/polaris-breadcrumbs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Rluk0xe4",
    "block": "[[[11,\"nav\"],[24,\"role\",\"navigation\"],[16,0,[30,1]],[24,\"data-test-breadcrumbs\",\"\"],[17,2],[12],[1,\"\\n\"],[41,[30,0,[\"breadcrumb\",\"url\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"Polaris-Breadcrumbs__Breadcrumb\"]],[[\"@url\",\"@onMouseUp\"],[[30,0,[\"breadcrumb\",\"url\"]],[30,0,[\"handleMouseUpByBlurring\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,\"data-test-breadcrumb-icon\",\"\"],[14,0,\"Polaris-Breadcrumbs__Icon\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@source\"],[\"chevron-left\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,\"data-test-breadcrumb-content\",\"\"],[14,0,\"Polaris-Breadcrumbs__Content\"],[12],[1,\"\\n        \"],[1,[30,0,[\"breadcrumb\",\"content\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"button\"],[24,0,\"Polaris-Breadcrumbs__Breadcrumb\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,3],[\"mouseup\",[30,0,[\"handleMouseUpByBlurring\"]]],null],[12],[1,\"\\n      \"],[10,1],[14,\"data-test-breadcrumb-icon\",\"\"],[14,0,\"Polaris-Breadcrumbs__Icon\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@source\"],[\"chevron-left\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,\"data-test-breadcrumb-content\",\"\"],[14,0,\"Polaris-Breadcrumbs__Content\"],[12],[1,\"\\n        \"],[1,[30,0,[\"breadcrumb\",\"content\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\"],false,[\"if\",\"polaris-unstyled-link\",\"polaris-icon\",\"on\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-breadcrumbs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});