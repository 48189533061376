define("@smile-io/ember-smile-polaris/templates/components/polaris-data-table/total", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "39o41mWl",
    "block": "[[[8,[39,0],null,[[\"@total\",\"@truncate\",\"@height\",\"@fixed\",\"@text\",\"@contentType\"],[true,[30,1],[28,[37,1],[[30,2],1],null],[28,[37,2],[[30,3],0],null],[52,[28,[37,2],[[30,3],0],null],[30,4],[33,4]],[30,0,[\"contentType\"]]]],null],[1,\"\\n\"]],[\"@truncate\",\"@heights\",\"@index\",\"@totalsRowHeading\"],false,[\"polaris-data-table/cell\",\"get\",\"eq\",\"if\",\"total\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-data-table/total.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});