define("ember-smile-core/components/polaris-app-top-bar", ["exports", "@ember/component", "@ember/object/computed", "ember-smile-core/templates/components/polaris-app-top-bar", "ember-smile-core/utils/computed/fallback-if-undefined"], function (_exports, _component, _computed, _polarisAppTopBar, _fallbackIfUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'header',
    classNames: ['polaris-app-top-bar'],
    layout: _polarisAppTopBar.default,

    /**
     * User profile trigger component.
     *
     * @type {String}
     * @public
     * @default null
     */
    profileTriggerComponent: null,

    /**
     * User profile drop-down menu component.
     *
     * @type {String}
     * @public
     * @default null
     */
    profileMenuComponent: null,

    /**
     * For branding purposes, logo SVG
     * @public
     */
    logoIcon: null,

    /**
     * Main app home route.
     * When the logo is clicked in the top-bar we route users to this page
     *
     * @public
     */
    homeRoute: null,

    /**
     * App branding component rendered on the left-side of the top-bar. Overwrite if needed.
     * NOTE: Not shown on mobile.
     *
     * @type {String}
     * @public
     * @default polaris-app-top-bar-branding
     */
    brandingComponent: (0, _fallbackIfUndefined.default)('polaris-app-top-bar-branding'),

    /**
     * User profile component displayed in the right side of the top-bar. Overwrite if needed.
     * NOTE: will only be rendered if `profileTriggerComponent` and `profileMenuComponent` are present
     * and not on mobile.
     *
     * @type {String}
     * @public
     * @default polaris-app-top-bar-profile
     */
    profileComponent: (0, _fallbackIfUndefined.default)('polaris-app-top-bar-profile'),

    /**
     * Action triggered when button to open/close navigation is clicked
     * NOTE: Only on mobile (nav is fixed on desktop)
     *
     * @type {Func}
     * @public
     * @default no-op
     */
    onToggleNav: function onToggleNav() {},
    hasProfileComponents: (0, _computed.and)('profileTriggerComponent', 'profileMenuComponent').readOnly()
  });

  _exports.default = _default;
});