define("@smile-io/ember-smile-polaris/templates/components/polaris-resource-list/checkable-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eh8DvOjk",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[17,1],[4,[38,0],[\"click\",[30,0,[\"toggleAll\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-ResourceList-CheckableButton__Checkbox\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@labelHidden\",\"@label\",\"@checked\",\"@disabled\"],[true,[30,2],[30,3],[30,4]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"Polaris-ResourceList-CheckableButton__Label\"],[12],[1,[30,5]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@accessibilityLabel\",\"@selected\",\"@disabled\",\"@label\"],false,[\"on\",\"polaris-checkbox\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-resource-list/checkable-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});