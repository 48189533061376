define("@smile-io/ember-smile-polaris/templates/components/polaris-radio-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tNEZdoy8",
    "block": "[[[8,[39,0],null,[[\"@inputId\",\"@label\",\"@labelHidden\",\"@disabled\",\"@helpText\"],[[30,0,[\"_id\"]],[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[14,\"data-test-radio-button\",\"true\"],[14,0,\"Polaris-RadioButton\"],[12],[1,\"\\n    \"],[11,\"input\"],[24,\"data-test-radio-button-input\",\"\"],[24,0,\"Polaris-RadioButton__Input\"],[16,1,[30,0,[\"_id\"]]],[16,3,[30,5]],[16,2,[30,6]],[16,\"checked\",[30,7]],[16,\"disabled\",[30,3]],[16,\"aria-describedby\",[30,0,[\"describedBy\"]]],[24,4,\"radio\"],[4,[38,1],[\"change\",[30,0,[\"handleChange\"]]],null],[4,[38,1],[\"focus\",[28,[37,2],[[30,8],[30,0,[\"onFocus\"]]],null]],null],[4,[38,1],[\"blur\",[28,[37,2],[[30,9],[30,0,[\"onBlur\"]]],null]],null],[12],[13],[1,\"\\n\\n    \"],[10,1],[14,\"data-test-radio-button-backdrop\",\"true\"],[14,0,\"Polaris-RadioButton__Backdrop\"],[12],[13],[1,\"\\n    \"],[10,1],[14,\"data-test-radio-button-icon\",\"true\"],[14,0,\"Polaris-RadioButton__Icon\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@label\",\"@labelHidden\",\"@disabled\",\"@helpText\",\"@name\",\"@value\",\"@checked\",\"@onFocus\",\"@onBlur\"],false,[\"polaris-choice\",\"on\",\"or\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-radio-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});