define("ember-svg-jar/inlined/export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.293 13.707l-3-3a.999.999 0 111.414-1.414L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a.999.999 0 111.414 1.414l-3 3a.999.999 0 01-1.414 0zM17 16a1 1 0 110 2H3a1 1 0 110-2h14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});