define("ember-template-compiler/lib/plugins/transform-has-block-syntax", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformHasBlockSyntax;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   {{hasBlock}}
    ```
  
    with
  
    ```handlebars
   {{has-block}}
    ```
  
    @private
    @class TransformHasBlockSyntax
  */
  var TRANSFORMATIONS = {
    hasBlock: 'has-block',
    hasBlockParams: 'has-block-params'
  };

  function transformHasBlockSyntax(env) {
    var b = env.syntax.builders;
    var moduleName = env.meta.moduleName;

    function emitDeprecationMessage(node, name) {
      var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
      (true && !(false) && (0, _debug.deprecate)("`" + name + "` is deprecated. Use `" + TRANSFORMATIONS[name] + "` instead. " + sourceInformation, false, {
        id: 'has-block-and-has-block-params',
        until: '4.0.0',
        url: 'https://deprecations.emberjs.com/v3.x#toc_has-block-and-has-block-params',
        for: 'ember-source',
        since: {
          enabled: '3.25.0'
        }
      }));
    }

    return {
      name: 'transform-has-block-syntax',
      visitor: {
        PathExpression: function PathExpression(node) {
          if (TRANSFORMATIONS[node.original]) {
            emitDeprecationMessage(node, node.original);
            return b.sexpr(b.path(TRANSFORMATIONS[node.original]));
          }
        },
        MustacheStatement: function MustacheStatement(node) {
          if ((0, _utils.isPath)(node.path) && TRANSFORMATIONS[node.path.original]) {
            emitDeprecationMessage(node, node.path.original);
            return b.mustache(b.path(TRANSFORMATIONS[node.path.original]), node.params, node.hash, undefined, node.loc);
          }
        },
        SubExpression: function SubExpression(node) {
          if ((0, _utils.isPath)(node.path) && TRANSFORMATIONS[node.path.original]) {
            emitDeprecationMessage(node, node.path.original);
            return b.sexpr(b.path(TRANSFORMATIONS[node.path.original]), node.params, node.hash);
          }
        }
      }
    };
  }
});