define("ember-smile-core/templates/components/auth-recover-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NCf1EbOY",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onSubmit\"],[[28,[37,1],[[30,0,[\"recover\"]],[30,0,[\"recoverChangeset\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@vertical\",\"@spacing\"],[true,\"loose\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@dataTestTextField\",\"@label\",\"@type\",\"@autoFocus\",\"@ariaAutocomplete\",\"@autoComplete\",\"@value\",\"@error\",\"@onChange\"],[\"recover-email-field\",\"Email\",\"email\",true,\"email\",true,[30,0,[\"recoverChangeset\",\"email\"]],[30,0,[\"recoverChangeset\",\"error\",\"email\",\"validation\",\"firstObject\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"recoverChangeset\",\"email\"]]],null]],null]]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@vertical\",\"@spacing\"],[true,\"tight\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,6],[[24,0,\"recover-button\"]],[[\"@text\",\"@primary\",\"@submit\",\"@loading\",\"@disabled\",\"@onClick\"],[\"Email instructions\",true,true,[30,0,[\"recover\",\"isRunning\"]],[28,[37,7],[[30,0,[\"recoverChangeset\",\"isInvalid\"]],[30,0,[\"recoverChangeset\",\"isPristine\"]]],null],[28,[37,1],[[30,0,[\"recover\"]],[30,0,[\"recoverChangeset\"]]],null]]],null],[1,\"\\n\\n      \"],[8,[39,6],[[24,0,\"return-to-login-link\"]],[[\"@plain\",\"@text\",\"@onClick\"],[true,\"Return to login\",[28,[37,4],[[30,2]],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@onReturnToLogin\"],false,[\"polaris-form\",\"perform\",\"polaris-stack\",\"polaris-text-field\",\"fn\",\"mut\",\"polaris-button\",\"or\"]]",
    "moduleName": "ember-smile-core/templates/components/auth-recover-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});