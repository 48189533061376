define("ember-smile-core/templates/components/trackable-task/status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xvwrWAYX",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,0,[30,2]]],[[\"@status\",\"@title\",\"@icon\",\"@primaryAction\",\"@secondaryAction\",\"@onDismiss\"],[[30,0,[\"alertType\"]],[30,1,[\"name\"]],[52,[51,[30,0,[\"isFinished\"]]],\"smile/sync\"],[30,0,[\"bannerAction\"]],[52,[30,0,[\"showRollbackButton\"]],[28,[37,3],null,[[\"text\",\"onAction\"],[\"Undo\",[28,[37,4],[[30,0],[30,0,[\"onRollbackTask\"]]],null]]]]],[52,[30,0,[\"showCloseButton\"]],[30,3]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,5],[[33,6,[\"isRunning\"]],[33,6,[\"progressMax\"]],[28,[37,7],[[33,8]],null]],null],[[[1,\"      \"],[46,[33,8],null,[[\"task\"],[[33,6]]],null],[1,\"\\n\"]],[]],[[[41,[51,[33,10]],[[[1,\"      \"],[1,[33,6,[\"statusDescription\"]]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@task\",\"@bannerClasses\",\"@onDismiss\"],false,[\"if\",\"polaris-banner\",\"unless\",\"hash\",\"action\",\"and\",\"task\",\"component-exists\",\"progressComponent\",\"component\",\"hasDownloadableFile\"]]",
    "moduleName": "ember-smile-core/templates/components/trackable-task/status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});