define("@smile-io/ember-smile-polaris/templates/components/polaris-empty-search-result", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PsiVHDpV",
    "block": "[[[8,[39,0],null,[[\"@alignment\",\"@vertical\"],[\"center\",true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[1,\"      \"],[10,\"img\"],[15,\"src\",[28,[37,2],[[30,3],\"/@smile-io/ember-smile-polaris/illustrations/empty-search.svg\"],null]],[14,\"alt\",\"Empty search results\"],[14,0,\"Polaris-EmptySearchResult__Image\"],[14,\"draggable\",\"false\"],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@size\",\"@text\"],[\"small\",[30,4]]],null],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"    \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@variation\"],[\"subdued\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,2],[12],[1,[28,[35,5],[[30,5]],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[1]]]]],[1,\"\\n\"]],[\"stack\",\"@withIllustration\",\"@illustrationSrc\",\"@title\",\"@description\"],false,[\"polaris-stack\",\"if\",\"or\",\"polaris-display-text\",\"polaris-text-style\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-empty-search-result.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});