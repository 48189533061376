define("@smile-io/ember-smile-polaris/utils/data-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.measureColumn = measureColumn;
  _exports.isEdgeVisible = isEdgeVisible;
  _exports.getPrevAndCurrentColumns = getPrevAndCurrentColumns;

  function measureColumn(tableData) {
    return function (column, index) {
      var firstVisibleColumnIndex = tableData.firstVisibleColumnIndex,
          tableStart = tableData.tableLeftVisibleEdge,
          tableEnd = tableData.tableRightVisibleEdge,
          fixedColumnWidth = tableData.fixedColumnWidth;
      var leftEdge = column.offsetLeft + fixedColumnWidth;
      var rightEdge = leftEdge + column.offsetWidth;
      var isVisibleLeft = isEdgeVisible(leftEdge, tableStart, tableEnd);
      var isVisibleRight = isEdgeVisible(rightEdge, tableStart, tableEnd);
      var isVisible = isVisibleLeft || isVisibleRight;

      if (isVisible) {
        tableData.firstVisibleColumnIndex = Math.min(firstVisibleColumnIndex, index);
      }

      return {
        leftEdge: leftEdge,
        rightEdge: rightEdge,
        isVisible: isVisible
      };
    };
  }

  function isEdgeVisible(position, start, end) {
    var minVisiblePixels = 30;
    return position >= start + minVisiblePixels && position <= end - minVisiblePixels;
  }

  function getPrevAndCurrentColumns(tableData, columnData) {
    var firstVisibleColumnIndex = tableData.firstVisibleColumnIndex;
    var previousColumnIndex = Math.max(firstVisibleColumnIndex - 1, 0);
    var previousColumn = columnData[previousColumnIndex];
    var currentColumn = columnData[firstVisibleColumnIndex];
    return {
      previousColumn: previousColumn,
      currentColumn: currentColumn
    };
  }
});