define("ember-power-select-with-create/components/power-select-with-create/suggested-option", ["exports", "@ember/component", "ember-power-select-with-create/templates/components/power-select-with-create/suggested-option"], function (_exports, _component, _suggestedOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _suggestedOption.default
  });

  _exports.default = _default;
});