define("@smile-io/ember-smile-polaris/helpers/is-component-definition", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isComponentDefinition = isComponentDefinition;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // TODO look into getting rid of this concept
  function isComponentDefinition(content) {
    var _Object$getOwnPropert, _content$constructor;

    if ((0, _utils.typeOf)(content) !== 'object') {
      return false;
    } // Glimmer now uses Symbol keys in its component definitions so we check those first.


    var symbolPropKeys = (_Object$getOwnPropert = Object.getOwnPropertySymbols) === null || _Object$getOwnPropert === void 0 ? void 0 : _Object$getOwnPropert.call(Object, content);

    if (symbolPropKeys === null || symbolPropKeys === void 0 ? void 0 : symbolPropKeys.length) {
      var isGlimmerComponentDefinition = symbolPropKeys.some(function (symbolPropKey) {
        var propValue = content[symbolPropKey];
        return propValue && Object.keys(propValue).some(function (key) {
          return key === 'ComponentClass';
        });
      });

      if (isGlimmerComponentDefinition) {
        return true;
      }
    }

    var contentPropNames = Object.keys(content);
    var isPreOctaneComponentDefinition = contentPropNames.some(function (propName) {
      return propName.indexOf('COMPONENT DEFINITION') > -1;
    });
    return isPreOctaneComponentDefinition || ((_content$constructor = content.constructor) === null || _content$constructor === void 0 ? void 0 : _content$constructor.name) === 'CurriedComponentDefinition';
  }

  var _default = (0, _helper.helper)(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        content = _ref2[0];

    return isComponentDefinition(content);
  });

  _exports.default = _default;
});