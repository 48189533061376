define("@smile-io/ember-smile-polaris/templates/components/polaris-page/header/rollup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TNEPwjdf",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"Polaris-Page-Header__Rollup\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"activator\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,2],[\"isMobile\"],null],[[[1,\"          \"],[8,[39,3],null,[[\"@text\",\"@disclosure\"],[\"Actions\",true]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,3],null,[[\"@plain\",\"@icon\"],[true,\"horizontal-dots\"]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@items\",\"@sections\",\"@onActionAnyItem\"],[[30,3],[30,4],[28,[37,5],[[30,0],[30,2,[\"close\"]]],null]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@hasRollup\",\"popover\",\"@secondaryActions\",\"@actionGroupSections\"],false,[\"if\",\"polaris-popover\",\"media\",\"polaris-button\",\"polaris-action-list\",\"action\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-page/header/rollup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});