define("ember-svg-jar/inlined/avatar-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#ffc04d}.cls-2{fill:#5d6cc1}</style></defs><path class=\"cls-1\" d=\"M0 0h100v100H0z\"/><path class=\"cls-2\" d=\"M0 0h69.41v100H0z\"/><path class=\"cls-1\" d=\"M70.21 80.88h-15.1c-4.81 0-5.68-5.84-5.68-5.84h20.78\"/><path class=\"cls-2\" d=\"M82 60.48l-14 .25V0h10.17C80.59 20.14 82 60.48 82 60.48z\"/><path d=\"M57.71 60.48a5.44 5.44 0 1110.87 0\" fill=\"#41236e\"/><circle cx=\"24.77\" cy=\"40.19\" r=\"11.22\" fill=\"#ebedf1\" opacity=\".2\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});