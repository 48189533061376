define("ember-smile-core/utils/external-links", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openExternalLink = openExternalLink;

  /**
   * Utility to open an external link.
   *
   * @param {String} link                 The link to open.
   * @param {Object} options              Options for opening the link.
   * @property {Boolean} options.newTab   When true (default), opens the link in a new tab.
   * @property {Boolean} options.replace  When true (default), replaces the current URL (skipping history).
   *                                      Set to false, if you want to preserve history. Only applies
   *                                      when `newTab` is `false`
   */
  function openExternalLink(link) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$newTab = _ref.newTab,
        newTab = _ref$newTab === void 0 ? true : _ref$newTab,
        _ref$replace = _ref.replace,
        replace = _ref$replace === void 0 ? true : _ref$replace;

    if (!newTab) {
      if (replace) {
        window.location.replace(link);
      } else {
        window.location.assign(link);
      }

      return;
    }

    var newWindow = window.open();
    (false && !(newWindow) && (0, _debug.assert)('[ember-smile-core/utils/external-links] popup blocked', newWindow));

    if (newWindow) {
      // Reset `opener` to fix possible vulnerability.
      // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
      newWindow.opener = null; // Navigate the new window to the link URL.

      newWindow.location = link;
    } else {
      // Fall back to opening the link in the current window if opening a new window failed.
      window.location = link;
    }
  }
});