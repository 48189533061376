define("@smile-io/ember-smile-polaris/templates/components/polaris-popover/content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IWn9ih5m",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[30,1],[[24,0,\"Polaris-PositionedOverlay\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,\"data-polaris-overlay\",\"true\"],[15,0,[29,[\"\\n        Polaris-Popover\\n        \",[52,[30,2],\"Polaris-Popover--fullWidth\"],\"\\n      \"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"Polaris-Popover__Tip\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"Polaris-Popover__FocusTracker\"],[14,\"tabindex\",\"0\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"Polaris-Popover__Wrapper\"],[12],[1,\"\\n        \"],[10,0],[14,\"tabindex\",\"-1\"],[15,0,[29,[\"Polaris-Popover__Content \",[30,3],\" \",[52,[30,4],\"Polaris-Popover__Content--fullHeight\"]]]],[12],[1,\"\\n          \"],[10,0],[14,0,\"Polaris-Popover__Pane Polaris-Scrollable Polaris-Scrollable--vertical\"],[14,\"data-polaris-scrollable\",\"true\"],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"              \"],[10,0],[14,0,\"Polaris-Popover__Section\"],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"                  \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"                  \"],[1,[30,6]],[1,\"\\n\"]],[]]],[1,\"              \"],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,7]],[[[1,\"                \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[30,6]],[1,\"\\n\"]],[]]]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"Polaris-Popover__FocusTracker\"],[14,\"tabindex\",\"0\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@contentComponent\",\"@fullWidth\",\"@contentClass\",\"@fullHeight\",\"@sectioned\",\"@text\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-popover/content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});