define("@smile-io/ember-smile-polaris/templates/components/polaris-layout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Nt2unVqT",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-Layout \",[30,1]]]],[17,2],[12],[1,\"\\n\"],[41,[30,0,[\"sectioned\"]],[[[1,\"    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,[[28,[37,4],null,[[\"section\",\"annotatedSection\"],[[50,\"polaris-layout/section\",0,null,null],[50,\"polaris-layout/annotated-section\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,3]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[48,[30,4]],[[[1,\"      \"],[18,4,[[28,[37,4],null,[[\"section\",\"annotatedSection\"],[[50,\"polaris-layout/section\",0,null,null],[50,\"polaris-layout/annotated-section\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,3]],[1,\"\\n\"]],[]]]],[]]],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@text\",\"&default\"],false,[\"if\",\"polaris-layout/section\",\"has-block\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-layout.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});