define("ember-smile-core/components/constrained-image", ["exports", "@ember/component", "@ember/object", "@ember/string", "ember-smile-core/templates/components/constrained-image"], function (_exports, _component, _object, _string, _constrainedImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders an image constrained to the passed-in width and height.
   * The image will be rendered as large as possible inside those
   * dimensions without clipping any edges.
   */
  var _default = _component.default.extend({
    tagName: '',
    layout: _constrainedImage.default,

    /**
     * Source of the image to display.
     *
     * @property {source}
     * @type {String}
     * @default null
     * @public
     */
    source: null,

    /**
     * Width of the image to display.
     *
     * @property {width}
     * @type {String}
     * @default '100%'
     * @public
     */
    width: '100%',

    /**
     * Height of the image to display.
     *
     * @property {height}
     * @type {String}
     * @default '100%'
     * @public
     */
    height: '100%',

    /**
     * Computed style to handle sizing.
     *
     * @property {imageContainerStyle}
     * @type {Handlebars.SafeString}
     * @private
     */
    imageContainerStyle: (0, _object.computed)('width', 'height', function () {
      var width = this.width,
          height = this.height;
      return (0, _string.htmlSafe)("\n      width: ".concat(width, ";\n      height: ").concat(height, ";\n    "));
    }).readOnly()
  });

  _exports.default = _default;
});