define("ember-smile-core/templates/components/smile-s3-uploader/invalid-files-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "peYiIGwb",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"title\",\"status\",\"withinContentContainer\"],[[28,[37,3],[\"The following \",[28,[37,4],[[33,1,[\"length\"]],\"file\"],[[\"without-count\"],[true]]],\" couldn’t be uploaded\"],null],\"critical\",[33,5]]],[[\"default\"],[[[[6,[39,6],null,[[\"type\"],[\"bullets\"]],[[\"default\"],[[[[42,[28,[37,8],[[28,[37,8],[[33,9]],null]],null],null,[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"          \\\"\"],[1,[30,2,[\"name\"]]],[1,\"\\\" is not supported. File type must be \"],[1,[34,10]],[1,\".\\n\"]],[]]]]]],[2]],null],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[33,11]],null]],null],null,[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"          \"],[1,[30,3,[\"invalidReasonText\"]]],[1,\"\\n\"]],[]]]]]],[3]],null]],[1]]]]]],[]]]]]],[]],null]],[\"list\",\"file\",\"invalidFile\"],false,[\"if\",\"nonUploadableFiles\",\"polaris-banner\",\"concat\",\"pluralize\",\"withinContentContainer\",\"polaris-list\",\"each\",\"-track-array\",\"rejectedFiles\",\"readableAcceptedFileTypes\",\"invalidFiles\"]]",
    "moduleName": "ember-smile-core/templates/components/smile-s3-uploader/invalid-files-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});