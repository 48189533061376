define("ember-smile-core/templates/components/toggleable-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WbtKcr2K",
    "block": "[[[6,[39,0],null,[[\"onClick\"],[[28,[37,1],[[30,0],[33,2],[33,3]],null]]],[[\"default\"],[[[[6,[39,4],null,[[\"spacing\",\"alignment\"],[\"none\",\"center\"]],[[\"default\"],[[[[41,[33,6],[[[1,\"      \"],[8,[39,7],[[24,0,\"chevron\"]],[[\"@source\"],[\"caret-down\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,1],[12],[1,[34,8]],[13],[1,\"\\n\\n\"],[41,[51,[33,6]],[[[1,\"      \"],[8,[39,7],[[24,0,\"chevron\"]],[[\"@source\"],[\"caret-down\"]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[]]]]]],[],false,[\"polaris-link\",\"action\",\"onToggle\",\"isToggled\",\"polaris-stack\",\"if\",\"isIconBeforeText\",\"polaris-icon\",\"text\",\"unless\"]]",
    "moduleName": "ember-smile-core/templates/components/toggleable-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});