define("ember-svg-jar/inlined/smile/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#757575\" d=\"M20 7.452v2.964c2.388 1.387 4 3.965 4 6.917 0 4.412-3.588 8-8 8s-8-3.588-8-8c0-2.953 1.612-5.531 4-6.917V7.452c-3.904 1.587-6.667 5.415-6.667 9.881C5.333 23.214 10.118 28 16 28s10.667-4.785 10.667-10.667c0-4.467-2.763-8.295-6.667-9.881z\"/><path fill=\"#757575\" d=\"M14.667 4h2.667v10.667h-2.667V4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});