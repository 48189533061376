define("ember-smile-core/templates/components/polaris-modals/default", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8ENXqBKW",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"alignment\"],[\"center\"]],[[\"default\"],[[[[6,[30,2,[\"item\"]],null,[[\"fill\"],[true]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],null,[[\"size\",\"text\"],[\"small\",[33,3]]]]],[1,\"\\n\"]],[]]]]],[41,[33,5],[[[6,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"          \"],[8,[39,6],[[24,0,\"polaris-modal-close-btn\"]],[[\"@onClick\"],[[28,[37,7],[[30,0],\"closeModal\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,8],null,[[\"source\"],[\"cancel\"]]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[2]]]]]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[41,[48,[30,3]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[34,11]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"distribution\"],[\"trailing\"]],[[\"default\"],[[[[6,[39,12],null,null,[[\"default\"],[[[[41,[33,13],[[[1,\"          \"],[1,[28,[35,14],null,[[\"text\",\"disabled\",\"onClick\"],[\"Cancel\",[33,15],[28,[37,7],[[30,0],\"closeModal\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[1,[28,[35,14],null,[[\"primary\",\"destructive\",\"text\",\"loading\",\"onClick\"],[[33,16],[33,17],[33,18],[33,15],[28,[37,7],[[30,0],\"confirm\"],null]]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]]]]]],[1]]]]]],[\"card\",\"stack\",\"&default\"],false,[\"polaris-card\",\"polaris-stack\",\"polaris-display-text\",\"title\",\"if\",\"showCloseIcon\",\"polaris-link\",\"action\",\"polaris-icon\",\"has-block\",\"yield\",\"body\",\"polaris-button-group\",\"showCancelBtn\",\"polaris-button\",\"isActionRunning\",\"primary\",\"destructive\",\"confirmBtnText\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-modals/default.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});