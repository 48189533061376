define("ember-smile-core/components/footer-banner", ["exports", "@ember/component", "ember-smile-core/templates/components/footer-banner"], function (_exports, _component, _footerBanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: 'footer-banner',
    layout: _footerBanner.default
  });

  _exports.default = _default;
});