define("@smile-io/ember-smile-polaris/templates/components/polaris-connected", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4m6uIG3M",
    "block": "[[[41,[30,0,[\"hasConnection\"]],[[[1,\"  \"],[11,0],[24,0,\"Polaris-Connected\"],[16,\"data-test-connected\",[30,0,[\"dataTestConnected\"]]],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,1],[[24,\"data-test-connected-item\",\"left\"]],[[\"@position\"],[\"left\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[[30,2]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-connected-item\",\"primary\"]],[[\"@position\"],[\"primary\"]],[[\"default\"],[[[[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"      \"],[8,[39,1],[[24,\"data-test-connected-item\",\"right\"]],[[\"@position\"],[\"right\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[[30,3]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,4,null],[1,\"\\n\"]],[]]]],[\"&attrs\",\"@left\",\"@right\",\"&default\"],false,[\"if\",\"polaris-connected/item\",\"render-content\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-connected.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});