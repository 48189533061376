define("ember-smile-core/components/tab-panel-card/header", ["exports", "@ember/component", "@ember/object/computed", "ember-smile-core/templates/components/tab-panel-card/header"], function (_exports, _component, _computed, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _header.default,

    /**
     * The title for the tab panel card.
     *
     * @type {String}
     * @default null
     * @public
     */
    title: null,

    /**
     * The title size. Any size value supported by `{{polaris-display-text}}`
     *
     * @type {String}
     * @default 'medium'
     * @public
     */
    titleSize: 'medium',

    /**
     * Optional progress to be shown in the header.
     *
     * @type {Number}
     * @default null
     * @public
     */
    progress: null,

    /**
     * When true, will show skeleton UI
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    isLoading: false,

    /**
     * Allows changing what's used to render the title
     *
     * @type {String|Component}
     * @default 'polaris-display-text'
     * @public
     */
    titleComponent: 'polaris-display-text',
    progressBarComponent: 'polaris-progress-bar',
    showProgress: (0, _computed.notEmpty)('progress').readOnly()
  });

  _exports.default = _default;
});