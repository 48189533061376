define("ember-changeset/utils/is-changeset", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isChangeset;
  _exports.CHANGESET = void 0;
  var CHANGESET = '__CHANGESET__';
  _exports.CHANGESET = CHANGESET;

  function isChangeset(changeset) {
    return (0, _object.get)(changeset, '__changeset__') === CHANGESET;
  }
});