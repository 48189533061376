define("@smile-io/ember-smile-polaris/utils/normalize-auto-complete", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeAutoComplete = normalizeAutoComplete;
  _exports.normalizeAutoCompleteProperty = normalizeAutoCompleteProperty;

  /**
   * Normalizes a boolean as a HTML auto-complete value
   *
   * @param {Boolean} autoComplete
   */
  function normalizeAutoComplete(autoComplete) {
    if (autoComplete == null) {
      return autoComplete;
    }

    return autoComplete ? 'on' : 'off';
  }
  /**
   * Takes a property name and normalizes as a HTML auto-complete value
   *
   * @param {String} autoCompleteProperty
   */


  function normalizeAutoCompleteProperty(autoCompleteProperty) {
    return (0, _object.computed)(autoCompleteProperty, function () {
      var autoComplete = this.get(autoCompleteProperty);
      return normalizeAutoComplete(autoComplete);
    }).readOnly();
  }
});