define("ember-smile-core/components/tab-panel/tab", ["exports", "@ember/component", "@ember/debug", "ember-smile-core/templates/components/tab-panel/tab"], function (_exports, _component, _debug, _tab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    attributeBindings: ['role'],
    classNames: ['Tab-Panel__Tab'],
    classNameBindings: ['tab.isComplete:Tab-Panel__Tab--is-complete', 'isActive:Tab-Panel__Tab--is-active', 'isLoading:Tab-Panel__Tab--is-loading'],
    layout: _tab.default,

    /**
     * The object that defines the tab.
     *
     * @type {Object}
     * @param tab.text {String} Text to be rendered in the tab
     * @param tab.icon {String} Icon source for the tab
     * @default null
     * @public
     * @required
     */
    tab: null,

    /**
     * Role HTML attribute applied to this component's element
     *
     * @type {String}
     * @default 'presentation'
     * @public
     */
    role: 'presentation',

    /**
     * Callback triggered when tab is clicked
     *
     * @type {Function}
     * @default no-op
     * @public
     */
    onClick: function onClick() {},
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.tab || this.isLoading) && (0, _debug.assert)('[tab-panel/tab] Missing required `tab` param!', this.tab || this.isLoading));
    }
  });

  _exports.default = _default;
});