define("ember-smile-core/templates/components/polaris-liquid-if", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WxBP3FoY",
    "block": "[[[44,[[28,[37,1],[[33,2],[50,\"no-op\",0,null,null]],null]],[[[1,\"  \"],[8,[30,1],[[16,0,[52,[33,5],[33,6],\"polaris-liquid-if-empty\"]]],null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,7],[[33,5]],[[\"class\"],[[33,8]]],[[\"default\"],[[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]]]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"WrapperComponent\",\"&default\"],false,[\"let\",\"or\",\"wrapperComponent\",\"component\",\"if\",\"condition\",\"wrapperComponentClasses\",\"liquid-if\",\"liquidIfClasses\",\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-liquid-if.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});