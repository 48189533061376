define("@smile-io/ember-smile-polaris/templates/components/polaris-inline-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YfTMOk6/",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[24,0,\"Polaris-InlineError\"],[16,1,[29,[[30,2],\"Error\"]]],[16,\"data-test-inline-error\",[30,0,[\"dataTestInlineError\"]]],[17,3],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-InlineError__Icon\"],[15,\"data-test-inline-error-icon\",[30,0,[\"dataTestInlineErrorIcon\"]]],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@source\"],[\"alert\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,2],[[30,1]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@message\",\"@fieldID\",\"&attrs\"],false,[\"if\",\"polaris-icon\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-inline-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});