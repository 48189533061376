define("liquid-fire/components/liquid-bind", ["exports", "@ember/object", "@ember/component", "liquid-fire/templates/components/liquid-bind"], function (_exports, _object, _component, _liquidBind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LiquidBind = _component.default.extend({
    layout: _liquidBind.default,
    tagName: '',
    positionalParams: ['value'],
    // needed for Ember 1.13.[0-5] and 2.0.0-beta.[1-3] support
    forwardMatchContext: (0, _object.computed)('matchContext', function () {
      var m = this.get('matchContext');

      if (!m) {
        m = {};
      }

      if (!m.helperName) {
        m.helperName = 'liquid-bind';
      }

      return m;
    })
  });

  LiquidBind.reopenClass({
    positionalParams: ['value']
  });
  var _default = LiquidBind;
  _exports.default = _default;
});