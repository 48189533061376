define("@smile-io/ember-smile-polaris/mixins/components/svg-handling", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/internals", "@ember/utils", "@ember/runloop"], function (_exports, _mixin, _object, _internals, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-new-mixins */

  /**
   * This mixin is intended for use with components that render an SVG icon
   * via the `svg-jar` helper.
   *
   * The Polaris React implementation checks the
   * `fill` attribute on SVG elements and their children to ensure SVGs
   * always respect whatever `color` they're passed.
   *
   * In this mixin, we automate that process - all that's needed is to
   * include this mixin in the component that renders the SVG, and pass
   * `id=svgElementId` to the `svg-jar` invocation.
   *
   * TODO: this currently only supports having a single SVG per component.
   * This should be fine for now though since this mixin only gets used
   * in components whose sole responsibility is to render a single SVG.
   */
  var _default = _mixin.default.create({
    /**
     * Generated unique ID to be set on the rendered SVG element.
     *
     * @type {string}
     */
    svgElementId: (0, _object.computed)(function () {
      // We append '-svg' to the component's Ember-generated GUID,
      // because Ember will automatically use that GUID as the ID
      // for the root element of non-tagless components.
      return "".concat((0, _internals.guidFor)(this), "-svg");
    }).readOnly(),

    /**
     *
     * @type {DOMNode}
     */
    get svgElement() {
      return document.querySelector("#".concat(this.svgElementId));
    },

    removeFillsFromSvgElement: function removeFillsFromSvgElement() {
      this.removeSvgFills(this.svgElement);
    },

    /**
     * Helper method to process the `fill` attribute
     * on a given SVG element and its children.
     */
    removeSvgFills: function removeSvgFills(elem) {
      if ((0, _utils.isNone)(elem) || !elem.hasChildNodes()) {
        return;
      }

      var children = elem.childNodes;

      for (var i = 0, len = children.length; i < len; i++) {
        var child = children[i];

        if (child.tagName === 'g') {
          child.removeAttribute('fill');
          this.removeSvgFills(child);
        } else {
          var fill = child.getAttribute('fill'); // This is what Shopify does too in @shopify/images/icon-loader.js

          var newFill = fill && fill.indexOf('#FFF') !== -1 ? 'currentColor' : '';
          child.setAttribute('fill', newFill);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.removeFillsFromSvgElement();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      (0, _runloop.scheduleOnce)('afterRender', this, this.removeFillsFromSvgElement);
    }
  });

  _exports.default = _default;
});