define("ember-smile-core/components/actionable-list/item", ["exports", "@ember/component", "ember-smile-core/templates/components/actionable-list/item"], function (_exports, _component, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * List item intended used by `actionable-list`.
   * Renders an (optional) image, text, and an (optional) action component.
   */
  var _default = _component.default.extend({
    classNames: ['actionable-list-item'],
    layout: _item.default,

    /**
     * URL to an image to render.
     *
     * @property image
     * @type {String}
     * @default null
     * @public
     */
    image: null,

    /**
     * Content text to render.
     * If this component is used in block form,
     * the block content will be rendered instead.
     *
     * @property text
     * @type {String}
     * @default null
     * @public
     */
    text: null,

    /**
     * Action component to render.
     *
     * @property actionComponent
     * @type {String|Component}
     * @default null
     * @public
     */
    actionComponent: null
  });

  _exports.default = _default;
});