define("@smile-io/ember-smile-polaris/templates/components/polaris-badge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Pag9neLP",
    "block": "[[[11,1],[16,0,[29,[\"Polaris-Badge \",[30,0,[\"statusClass\"]],\" \",[30,0,[\"progressClass\"]],\" \",[30,0,[\"sizeClass\"]],\" \",[30,1]]]],[17,2],[12],[1,\"\\n\"],[41,[30,0,[\"statusDescription\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@text\"],[[30,0,[\"statusDescription\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"progressDescription\"]],[[[1,\"    \"],[10,1],[14,0,\"Polaris-Badge__Pip\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@text\"],[[30,0,[\"progressDescription\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,3]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@text\",\"&default\"],false,[\"if\",\"polaris-visually-hidden\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-badge.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});