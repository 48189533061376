define("@smile-io/ember-smile-polaris/templates/components/polaris-text-field/resizer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mVlRpGoI",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-TextField__Resizer \",[30,1]]]],[24,\"aria-hidden\",\"true\"],[24,\"data-test-text-field-resizer\",\"\"],[17,2],[4,[38,0],[\"resize\",[30,0,[\"handleHeightCheck\"]]],null],[4,[38,1],[[30,0,[\"handleHeightCheck\"]]],null],[4,[38,2],[[30,0,[\"handleHeightCheck\"]],[30,3],[30,4],[30,5]],null],[12],[1,\"\\n  \"],[11,0],[24,0,\"Polaris-TextField__DummyInput\"],[4,[38,1],[[30,0,[\"setContentNode\"]]],null],[12],[1,[30,0,[\"finalContents\"]]],[13],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"    \"],[11,0],[24,0,\"Polaris-TextField__DummyInput\"],[4,[38,1],[[30,0,[\"setMinimumLinesNode\"]]],null],[12],[1,[30,0,[\"contentsForMinimumLines\"]]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@contents\",\"@currentHeight\",\"@minimumLines\"],false,[\"on\",\"did-insert\",\"did-update\",\"if\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-text-field/resizer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});