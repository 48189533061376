define("ember-smile-core/components/polaris-app-nav-heading", ["exports", "@ember/component", "@ember/object/computed", "@ember/utils", "ember-smile-core/templates/components/polaris-app-nav-heading"], function (_exports, _component, _computed, _utils, _polarisAppNavHeading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * App navigation heading sub-component.
   * The navigation heading is composed of a `label` with an optional action to be performed when clicked (`onClickLabel`)
   * and an optional icon, shown to the right of the label, that does some action.
   */
  var _default = _component.default.extend({
    classNames: ['polaris-app-nav-heading'],
    layout: _polarisAppNavHeading.default,

    /**
     * Navigation heading text
     *
     * @type {String}
     * @public
     * @default null
     */
    label: null,

    /**
     * Action triggered when heading label is clicked
     *
     * @type {Func}
     * @public
     * @default null
     */
    onClickLabel: null,

    /**
     * Right-side action icon SVG.
     *
     * @type {String}
     * @public
     * @default null
     */
    icon: null,

    /**
     * Action triggered when the icon is clicked
     *
     * @type {Func}
     * @public
     * @default null
     */
    onClickIcon: null,
    hasLabelAction: (0, _computed.bool)('onClickLabel').readOnly(),
    actions: {
      clickLabel: function clickLabel() {
        var onClickLabel = this.get('onClickLabel');

        if ((0, _utils.isNone)(onClickLabel)) {
          return;
        }

        return onClickLabel();
      }
    }
  });

  _exports.default = _default;
});