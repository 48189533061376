define("@smile-io/ember-smile-polaris/templates/components/polaris-page/header/action-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RpAtRfzJ",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-Header-ActionGroup \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"activator\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@disclosure\",\"@icon\",\"@text\",\"@onAction\"],[true,[30,4],[30,5],[28,[37,2],[[30,0],[30,3,[\"toggle\"]]],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@items\",\"@onActionAnyItem\"],[[30,6],[28,[37,2],[[30,0],[30,3,[\"close\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,7],[[[1,\"        \"],[10,0],[14,0,\"Polaris-Header-ActionGroup__Details\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[[30,7]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"popover\",\"@icon\",\"@title\",\"@groupActions\",\"@details\"],false,[\"polaris-popover\",\"polaris-page/action\",\"action\",\"polaris-action-list\",\"if\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-page/header/action-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});