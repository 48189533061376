define("ember-svg-jar/inlined/polaris/embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17 13a1 1 0 011 1v3a1 1 0 01-1 1H3a1 1 0 01-1-1v-3a1 1 0 112 0v2h12v-2a1 1 0 011-1zm0-11a1 1 0 011 1v3a1 1 0 11-2 0V4H4v2a1 1 0 11-2 0V3a1 1 0 011-1h14zm.555 7.168a1.001 1.001 0 010 1.664l-3 2a1 1 0 01-1.109-1.664L15.198 10l-1.752-1.168a1 1 0 111.109-1.664l3 2zM6.832 7.445a1 1 0 01-.277 1.387L4.803 10l1.752 1.168a1 1 0 11-1.11 1.664l-3-2a1.001 1.001 0 010-1.664l3-2a1 1 0 011.387.277zM9 14.001a1 1 0 01-.948-1.317l2-6a1 1 0 011.896.633l-2 6A.999.999 0 019 14z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});