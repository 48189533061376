define("@smile-io/ember-smile-polaris/templates/components/polaris-layout/section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7HA5Dskr",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[17,1],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,2]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@text\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-layout/section.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});