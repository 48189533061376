define("ember-smile-core/components/polaris-app", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/service", "ember-smile-core/templates/components/polaris-app"], function (_exports, _component, _object, _computed, _utils, _service, _polarisApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _polarisApp.default,
    elsewhere: (0, _service.inject)('ember-elsewhere'),

    /**
     * Top bar content component. Should be implemented in the host-app
     *
     * @type {String}
     * @public
     * @default null
     */
    topBarContentComponent: null,

    /**
     * Navigation sidebar content to render. Will get `nav` passed in, which exposes section and item component to build
     * up the app navigation.
     *
     * @type {String}
     * @public
     * @default null
     */
    navContentComponent: null,

    /**
     * Whether we are on a mobile view
     * Used for the navigation for example: on mobile, it's togglable, while on desktop is locked/fixed
     *
     * @type {Boolean}
     * @public
     * @default false
     */
    isOnMobile: false,

    /**
     * Controls whether the navigation sidebar is enabled or not
     * NOTE: This doesn't mean that it's currently being shown
     *
     * @type {Boolean}
     * @public
     * @default false
     */
    isNavEnabled: false,

    /**
     * Controls whther the top-bar is visible or not
     *
     * @type {Boolean}
     * @public
     * @default false
     */
    isTopBarVisible: false,

    /**
     * App logo icon SVG shown in the top bar, if present
     *
     * @type {String}
     * @public
     * @default null
     */
    logoIcon: null,

    /**
     * Route used for top bar when clicking on the app logo icon
     *
     * @type {String}
     * @public
     * @default null
     */
    homeRoute: null,

    /**
     * Icon SVG to use for the navigation toggle button
     *
     * @type {String}
     * @public
     * @default null
     */
    navToggleIcon: null,

    /**
     * Color to use for the navigation toggle button icon
     *
     * @type {String}
     * @public
     * @default null
     */
    navToggleIconColor: null,
    hasBottomBar: (0, _computed.bool)('elsewhere.actives.polaris-app-bottom-bar').readOnly(),
    appClasses: (0, _object.computed)('isTopBarVisible', 'isNavEnabled', 'hasBottomBar', 'isOnMobile', function () {
      var classes = ['polaris-app'];

      if (this.get('isTopBarVisible')) {
        classes.push('with-top-bar');
      }

      if (this.get('hasBottomBar')) {
        classes.push('with-bottom-bar');
      }

      if (this.get('isNavEnabled')) {
        classes.push('with-nav');
      }

      if (this.isOnMobile) {
        classes.push('condensed-mode');
      }

      return classes.join(' ');
    }).readOnly(),
    isNavOpen: (0, _object.computed)('navContentComponent', 'isOnMobile', 'isNavEnabled', {
      get: function get() {
        // If nothing to show or not enabled, bail
        if ((0, _utils.isBlank)(this.get('navContentComponent')) || !this.get('isNavEnabled')) {
          return false;
        }

        return !this.get('isOnMobile');
      },
      set: function set(_, value) {
        // If nothing to show or not enabled, bail
        if ((0, _utils.isBlank)(this.get('navContentComponent')) || !this.get('isNavEnabled')) {
          return false;
        } // If on mobile, we always have a locked/opened navigation, so no toggling


        if (!this.get('isOnMobile')) {
          return true;
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});