define("ember-svg-jar/inlined/flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M2 3h11v4h6l-2 4 2 4H8v-4H3\"/><path d=\"M16.105 11.447L17.381 14H9v-2h4a1 1 0 001-1V8h3.38l-1.274 2.552a.993.993 0 000 .895zM2.69 4H12v6H4.027L2.692 4zm15.43 7l1.774-3.553A1 1 0 0019 6h-5V3c0-.554-.447-1-1-1H2.248L1.976.782a1 1 0 10-1.953.434l4 18a1.006 1.006 0 001.193.76 1 1 0 00.76-1.194L4.47 12H7v3a1 1 0 001 1h11c.346 0 .67-.18.85-.476a.993.993 0 00.044-.972l-1.775-3.553z\" fill=\"#919EAB\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});