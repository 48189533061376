define("ember-smile-core/templates/components/popup-color-picker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "x8VADYag",
    "block": "[[[6,[39,0],null,[[\"onClose\"],[[28,[37,1],[[30,0],[33,2]],null]]],[[\"default\"],[[[[6,[30,1,[\"activator\"]],null,null,[[\"default\"],[[[[6,[39,3],null,[[\"customWidth\",\"label\",\"inputId\"],[true,[33,4],[33,5]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,6],null,[[\"color\"],[[33,7]]]]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n\"],[41,[33,9],[[[1,\"    \"],[10,2],[15,1,[36,10]],[14,0,\"Polaris-Labelled__HelpText\"],[12],[1,\"\\n      \"],[1,[28,[35,11],[[33,9]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[6,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[6,[39,12],null,null,[[\"default\"],[[[[6,[30,2,[\"section\"]],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,13],null,[[\"color\",\"onChange\"],[[33,14],[28,[37,1],[[30,0],[33,15]],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"section\"]],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,16],null,[[\"value\",\"error\",\"onChange\"],[[33,7],[33,17],[28,[37,1],[[30,0],[33,18]],null]]]]],[1,\"\\n\"]],[]]]]]],[2]]]]]],[]]]]]],[1]]]]]],[\"popover\",\"card\"],false,[\"polaris-popover\",\"action\",\"onPopoverClosed\",\"polaris-choice\",\"label\",\"choiceId\",\"polaris-color-preview\",\"value\",\"if\",\"helpText\",\"hintId\",\"render-content\",\"polaris-card\",\"polaris-color-picker\",\"color\",\"updateColorValue\",\"polaris-text-field\",\"error\",\"onChange\"]]",
    "moduleName": "ember-smile-core/templates/components/popup-color-picker.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});