define("@smile-io/ember-smile-polaris/templates/components/polaris-date-picker/month", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JJXOc++i",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-DatePicker__Month \",[30,1]]]],[24,\"role\",\"grid\"],[24,\"data-test-date-picker-month\",\"\"],[17,2],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-date-picker-title\",\"\"],[15,0,[29,[\"Polaris-DatePicker__Title \",[52,[30,0,[\"current\"]],\"Polaris-DatePicker__Month--current\"]]]],[12],[1,\"\\n    \"],[1,[30,0,[\"monthDisplayName\"]]],[1,\" \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,\"data-test-date-picker-week-heading\",\"\"],[14,\"role\",\"rowheader\"],[14,0,\"Polaris-DatePicker__WeekHeadings\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"weekdays\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@title\",\"@current\",\"@label\"],[[30,4,[\"title\"]],[30,4,[\"current\"]],[30,4,[\"label\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"weeks\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,\"data-test-date-picker-week\",\"\"],[14,\"role\",\"row\"],[14,0,\"Polaris-DatePicker__Week\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,5]],null]],null],null,[[[41,[30,6],[[[1,\"          \"],[8,[39,4],null,[[\"@day\",\"@focusedDate\",\"@selectedDates\",\"@disableDatesBefore\",\"@disableDatesAfter\",\"@hoverDate\",\"@allowRange\",\"@onFocus\",\"@onClick\",\"@onHover\"],[[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[28,[37,5],[[30,0,[\"handleDateClick\"]],[30,6]],null],[30,14]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,\"data-test-date-picker-day-empty\",\"\"],[14,0,\"Polaris-DatePicker__EmptyDay\"],[12],[13],[1,\"\\n\"]],[]]]],[6]],null],[1,\"    \"],[13],[1,\"\\n\"]],[5]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@year\",\"weekday\",\"week\",\"day\",\"@focusedDate\",\"@selected\",\"@disableDatesBefore\",\"@disableDatesAfter\",\"@hoverDate\",\"@allowRange\",\"@onFocus\",\"@onHover\"],false,[\"if\",\"each\",\"-track-array\",\"polaris-date-picker/weekday\",\"polaris-date-picker/day\",\"fn\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-date-picker/month.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});