define("@smile-io/ember-smile-polaris/templates/components/polaris-sticky", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iLJSaObo",
    "block": "[[[11,0],[16,0,[30,1]],[17,2],[4,[38,0],[[30,0,[\"registerStickyItem\"]]],null],[12],[1,\"\\n  \"],[11,0],[4,[38,0],[[30,0,[\"setPlaceHolderNode\"]]],null],[12],[13],[1,\"\\n  \"],[11,0],[16,5,[30,0,[\"style\"]]],[4,[38,0],[[30,0,[\"setStickyNode\"]]],null],[12],[1,\"\\n    \"],[18,3,[[28,[37,2],null,[[\"isSticky\"],[[30,0,[\"isSticky\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"&default\"],false,[\"did-insert\",\"yield\",\"hash\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-sticky.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});