define("ember-smile-core/templates/components/callout-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Vy5OwPAj",
    "block": "[[[10,0],[14,0,\"Callout-Panel__Content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"Callout-Panel__Title\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"text\"],[[33,1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[6,[39,2],null,null,[[\"default\"],[[[[41,[48,[30,2]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[34,6]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"],[41,[33,7],[[[1,\"    \"],[10,0],[14,0,\"Callout-Panel__Buttons\"],[12],[1,\"\\n\"],[44,[[50,\"polaris-button\",0,null,[[\"text\",\"size\",\"disabled\",\"loading\",\"primary\",\"external\",\"url\",\"onClick\"],[[33,7,[\"text\"]],[33,7,[\"size\"]],[33,7,[\"disabled\"]],[33,7,[\"loading\"]],[33,7,[\"primary\"]],[33,7,[\"external\"]],[33,7,[\"url\"]],[28,[37,10],[[30,0],\"click\",[33,7]],null]]]]],[[[41,[30,1],[[[41,[33,11],[[[6,[39,12],null,null,[[\"default\"],[[[[1,\"            \"],[46,[30,1],null,null,null],[1,\"\\n\\n            \"],[1,[28,[35,13],null,[[\"plain\",\"text\",\"size\",\"disabled\",\"loading\",\"external\",\"url\",\"onClick\"],[[52,[28,[37,14],[[33,11,[\"plain\"]]],null],true,[33,11,[\"plain\"]]],[33,11,[\"text\"]],[33,11,[\"size\"]],[33,11,[\"disabled\"]],[33,11,[\"loading\"]],[33,11,[\"external\"]],[33,11,[\"url\"]],[28,[37,10],[[30,0],\"click\",[33,11]],null]]]]],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"          \"],[46,[30,1],null,null,null],[1,\"\\n\"]],[]]]],[]],null]],[1]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[33,15],[[[1,\"  \"],[10,\"img\"],[15,\"src\",[36,15]],[14,\"alt\",\"\"],[14,0,\"Callout-Panel__Image\"],[12],[13],[1,\"\\n\"]],[]],null]],[\"primaryButton\",\"&default\"],false,[\"polaris-heading\",\"title\",\"polaris-text-container\",\"if\",\"has-block\",\"yield\",\"text\",\"primaryAction\",\"let\",\"component\",\"action\",\"secondaryAction\",\"polaris-button-group\",\"polaris-button\",\"is-empty\",\"illustration\"]]",
    "moduleName": "ember-smile-core/templates/components/callout-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});