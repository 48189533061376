define("ember-svg-jar/inlined/polaris/alert-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#231F20\" fill-rule=\"evenodd\" d=\"M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-7a1 1 0 001-1V6a1 1 0 10-2 0v4a1 1 0 001 1m0 1.9a1.1 1.1 0 100 2.2 1.1 1.1 0 000-2.2\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});