define("@smile-io/ember-smile-polaris/utils/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleMouseUpByBlurring = handleMouseUpByBlurring;

  function handleMouseUpByBlurring(_ref) {
    var currentTarget = _ref.currentTarget;
    currentTarget.blur();
  }
});