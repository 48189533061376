define("ember-smile-core/components/popup-menu-trigger", ["exports", "@ember/component", "ember-smile-core/templates/components/popup-menu-trigger"], function (_exports, _component, _popupMenuTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Wrapper component which overlays a trigger for a popup menu
   * in the corner of the block content.
   */
  var _default = _component.default.extend({
    classNames: ['popup-menu-trigger'],
    layout: _popupMenuTrigger.default,

    /**
     * The items to render in the popup content's `polaris-action-list`.
     *
     * @property items
     * @type {Object[]}
     * @default null
     * @public
     */
    items: null
  });

  _exports.default = _default;
});