define("ember-svg-jar/inlined/smile/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#919EAB\" fill-rule=\"evenodd\"><path d=\"M17.36.702H2.584C1.567.702.74 1.489.74 2.456v16.667h1.846v-1.755H17.36v1.755h1.847V2.456c0-.967-.828-1.754-1.847-1.754zm0 1.754v6.14H9.971V4.211h-2.77v4.385H6.28V4.211h-2.77v4.385h-.924v-6.14H17.36zm-4.618 8.772v4.386h-.923v-4.386h-2.77v4.386h-.923v-4.386h-2.77v4.386h-2.77v-5.263h14.773v5.263h-1.847v-4.386h-2.77z\"/><path d=\"M13.48 3.334l1.66 4.091-2.584.947-1.661-4.091z\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});