define("ember-smile-core/templates/components/polaris-color-preview", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8F3vg6GM",
    "block": "[[[10,0],[14,0,\"color-preview\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"color-preview-inner\"],[15,5,[36,0]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"previewStyle\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-color-preview.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});