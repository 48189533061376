define("ember-smile-core/components/trackable-task/status", ["exports", "@ember/component", "@ember/object", "@ember/debug", "@ember/object/computed", "@ember/string", "rsvp", "ember-changeset/utils/is-changeset", "ember-smile-core/objects/poller", "ember-smile-core/utils/external-links", "ember-smile-core/templates/components/trackable-task/status"], function (_exports, _component, _object, _debug, _computed, _string, _rsvp, _isChangeset, _poller, _externalLinks, _status) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusTypes = {
    succeeded: 'success',
    failed: 'danger',
    cancelled: 'warning'
  };

  var _default = _component.default.extend({
    classNames: ['trackable-task-status'],
    classNameBindings: ['isFinished:is-finished:is-running'],
    layout: _status.default,

    /**
     * The task to show the status for.
     *
     * @type {TrackableTask}
     * @public
     */
    task: null,

    /**
     * Classes to apply to the banner that will display the task's status.
     *
     * @type {String}
     * @public
     */
    bannerClasses: null,

    /**
     * Interval to wait between refreshing the task details.
     *
     * @type {number}
     * @public
     */
    pollIntervalMs: 1000,

    /**
     * Component to render to show task progress.
     * Will be passed the task object to give it access to task information.
     * Defaults to `trackable-task/<task-type>/progress`.
     *
     * @type {Component}
     * @public
     */
    progressComponent: (0, _object.computed)('task.type', function () {
      var _this$task;

      var taskType = (_this$task = this.task) === null || _this$task === void 0 ? void 0 : _this$task.type;
      return taskType ? "trackable-task/".concat((0, _string.dasherize)(taskType), "/progress") : null;
    }),

    /**
     * Action fired when merchant cancels the task.
     *
     * @type {Function}
     * @public
     */
    onCancelTask: _rsvp.resolve,

    /**
     * Action fired when merchant rolls back the task.
     *
     * @type {Function}
     * @public
     */
    onRollbackTask: _rsvp.resolve,

    /**
     * Action fired when merchant presses the close button.
     *
     * @type {Function}
     * @public
     */
    onDismiss: function onDismiss() {},

    /**
     * Action fired when trackable task is finished.
     *
     * @type {Function}
     * @public
     */
    onFinish: function onFinish() {},
    poller: null,
    isStarted: (0, _computed.bool)('task.startedAt').readOnly(),
    isFinished: (0, _computed.bool)('task.finishedAt').readOnly(),
    showCloseButton: (0, _computed.reads)('isFinished'),
    showCancelButton: (0, _computed.readOnly)('task.supportsCancel'),
    showRollbackButton: (0, _computed.readOnly)('task.supportsRollback'),
    hasDownloadableFile: (0, _computed.and)('isFinished', 'task.downloadableFileUrl').readOnly(),
    bannerAction: (0, _object.computed)('showCancelButton', 'hasDownloadableFile', 'task.downloadableFileUrl', function () {
      var _this = this;

      if (this.get('showCancelButton')) {
        return {
          text: 'Cancel',
          onAction: function onAction() {
            return _this.onCancelTask.apply(_this, arguments);
          }
        };
      }

      if (this.get('hasDownloadableFile')) {
        return {
          text: 'Download CSV',
          onAction: function onAction() {
            return (0, _externalLinks.openExternalLink)(_this.get('task.downloadableFileUrl'));
          }
        };
      }

      return null;
    }),
    alertType: (0, _object.computed)('isFinished', 'task.finishedStatus', function () {
      if (this.get('isFinished')) {
        return statusTypes[this.get('task.finishedStatus')];
      }

      return 'info';
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var task = this.get('task');
      (false && !(task) && (0, _debug.assert)('[trackable-task-status] missing `task` parameter', task));
      var poller = this.get('poller') || this.set('poller', _poller.default.create({
        intervalMs: this.get('pollIntervalMs'),
        pollAction: function pollAction(poller) {
          if (_this2.get('isFinished')) {
            poller.stop();

            _this2.get('onFinish')();

            return;
          }

          var task = _this2.get('task'); // Because ember changeset now supports nested objects,
          // it wraps the task in a changeset which causes this
          // component to misbehave.
          // So we access the original task.


          if ((0, _isChangeset.default)(task)) {
            task = task.content;
          }

          return task.reload().catch(function (err) {
            // Handle 404s occurring when the task being polled is destroyed after completion.
            // TODO #error-handling
            var status = (0, _object.get)(err, 'errors.firstObject.status');

            if (status === '404') {
              poller.stop();

              _this2.get('onFinish')();
            }
          });
        }
      }));
      poller.start();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('poller').destroy();
      this.set('poller', null);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});