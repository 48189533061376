define("ember-smile-core/helpers/component-exists", ["exports", "@ember/application", "@ember/utils", "@ember/component/helper"], function (_exports, _application, _utils, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentExists = componentExists;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /*
   * Looks up if a component exists without actually instantiating it.
   *
   * Usage:
   *   import { componentExists } from 'ember-smile-core/helpers/component-exists';
   *
   *   ....
   *   if (componentExists(someString)) {
   *    // code here, if component with `someString` name actually exists
   *   }
   */
  function componentExists(componentName, context) {
    if ((0, _utils.isBlank)(componentName)) {
      return false;
    }

    var owner = (0, _application.getOwner)(context);
    var lookup = owner.lookup('component-lookup:main');

    try {
      if (!lookup.componentFor) {
        return !!lookup.lookupFactory(componentName);
      }

      return !!(lookup.componentFor(componentName, owner) || lookup.layoutFor(componentName, owner));
    } catch (err) {
      return false;
    }
  }
  /*
   * Looks up if a component exists without actually instantiating it.
   *
   * Usage:
   *    {{#if (component-exists <param>)}}
   *
   *  * param - Component name to look for.
   */


  var _default = _helper.default.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          componentName = _ref2[0];

      return componentExists(componentName, this);
    }
  });

  _exports.default = _default;
});