define("ember-smile-core/templates/components/tab-panel-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kwZ00AZA",
    "block": "[[[8,[39,0],[[24,0,\"Tab-PanelCard\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[18,1,[[28,[37,2],null,[[\"header\",\"tabPanel\"],[[50,[33,4],0,null,[[\"isLoading\"],[[33,5]]]],[50,[33,6],0,null,[[\"isLoading\"],[[33,5]]]]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"polaris-card\",\"yield\",\"hash\",\"component\",\"header\",\"isLoading\",\"tabPanel\"]]",
    "moduleName": "ember-smile-core/templates/components/tab-panel-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});