define("ember-smile-core/components/chip-button", ["exports", "@ember/component", "ember-smile-core/utils/noop", "ember-smile-core/templates/components/chip-button"], function (_exports, _component, _noop, _chipButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function mapEventToAction(actionName) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return function (event) {
      var _this$actionName;

      event.preventDefault();
      return (_this$actionName = this[actionName]) === null || _this$actionName === void 0 ? void 0 : _this$actionName.call.apply(_this$actionName, [this].concat(args));
    };
  }

  var _default = _component.default.extend({
    tagName: 'button',
    attributeBindings: ['role'],
    classNames: ['Chip-Button'],
    classNameBindings: ['checked:Chip-Button--checked'],
    layout: _chipButton.default,

    /**
     * Button text
     *
     * @property text
     * @type {String}
     * @default null
     * @public
     */
    text: null,

    /**
     * HTML role binding
     *
     * @property role
     * @type {String}
     * @default 'button'
     * @public
     */
    role: 'button',

    /**
     * Callback when radio button is toggled
     *
     * @property onChange
     * @type {Function}
     * @default noop
     * @public
     */
    onChange: _noop.default,

    /**
     * Callback when radio button is focussed
     *
     * @property onFocus
     * @type {Function}
     * @default noop
     * @public
     */
    onFocus: _noop.default,

    /**
     * Callback when focus is removed
     *
     * @property onBlur
     * @type {Function}
     * @default noop
     * @public
     */
    onBlur: _noop.default,

    /**
     * Action handlers.
     */
    click: mapEventToAction('onChange'),
    focusIn: mapEventToAction('onFocus'),
    focusOut: mapEventToAction('onBlur')
  });

  _exports.default = _default;
});