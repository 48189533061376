define("ember-smile-core/templates/components/tab-panel-card/header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Un60eQHC",
    "block": "[[[8,[39,0],[[24,0,\"Tab-PanelCard__Header\"]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[51,[33,2]],[[[44,[[28,[37,4],null,[[\"title\"],[[50,[33,6],0,null,[[\"tagName\",\"text\",\"size\"],[\"h2\",[33,7],[33,8]]]]]]]],[[[41,[48,[30,3]],[[[1,\"        \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]],[[[6,[39,12],null,[[\"spacing\",\"alignment\",\"distribution\"],[\"loose\",\"center\",\"equalSpacing\"]],[[\"default\"],[[[[6,[30,2,[\"item\"]],null,[[\"fill\"],[true]],[[\"default\"],[[[[1,\"            \"],[46,[30,1,[\"title\"]],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[41,[33,13],[[[1,\"            \"],[8,[30,2,[\"item\"]],[[24,0,\"Tab-PanelCard__Progress\"]],null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,12],null,[[\"spacing\",\"alignment\"],[\"extraTight\",\"center\"]],[[\"default\"],[[[[41,[33,14],[[[6,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"                    \"],[1,[28,[35,15],null,[[\"text\"],[[33,14]]]]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[6,[30,2,[\"item\"]],null,[[\"fill\"],[true]],[[\"default\"],[[[[1,\"                  \"],[46,[33,16],null,[[\"size\",\"progress\"],[\"small\",[33,17]]],null],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"            \"]],[]]]]],[1,\"\\n\"]],[]],null]],[2]]]]]],[]]]],[1]]]],[]],[[[1,\"    \"],[1,[34,18]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"header\",\"stack\",\"&default\"],false,[\"polaris-card/section\",\"unless\",\"isLoading\",\"let\",\"hash\",\"component\",\"titleComponent\",\"title\",\"titleSize\",\"if\",\"has-block\",\"yield\",\"polaris-stack\",\"showProgress\",\"progressText\",\"polaris-caption\",\"progressBarComponent\",\"progress\",\"polaris-skeleton-display-text\"]]",
    "moduleName": "ember-smile-core/templates/components/tab-panel-card/header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});