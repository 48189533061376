define("@smile-io/ember-smile-polaris/templates/components/polaris-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zMHYjtMO",
    "block": "[[[11,0],[24,\"data-test-label\",\"\"],[16,0,[29,[\"Polaris-Label \",[52,[30,1],\"Polaris-Label--hidden\"]]]],[17,2],[12],[1,\"\\n  \"],[10,\"label\"],[15,1,[30,0,[\"labelId\"]]],[15,\"htmlFor\",[30,3]],[14,0,\"Polaris-Label__Text\"],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"      \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,3],[[30,4]],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@hidden\",\"&attrs\",\"@id\",\"@text\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-label.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});