define("@smile-io/ember-smile-polaris/templates/components/polaris-empty-state", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8HWw1dlS",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-EmptyState \",[30,1],\" \",[52,[30,2],\"Polaris-EmptyState--imageContained\"]]]],[24,\"data-test-empty-state\",\"\"],[17,3],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-empty-state-section\",\"\"],[14,0,\"Polaris-EmptyState__Section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-EmptyState__DetailsContainer\"],[12],[1,\"\\n\"],[44,[[50,\"polaris-empty-state/details\",0,null,[[\"heading\",\"text\",\"primaryAction\",\"secondaryAction\"],[[30,4],[30,5],[30,0,[\"mainAction\"]],[30,6]]]]],[[[41,[48,[30,9]],[[[1,\"          \"],[8,[30,7],null,null,[[\"default\"],[[[[1,\"\\n            \"],[18,9,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[30,7],null,null,null],[1,\"\\n\"]],[]]]],[7]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"Polaris-EmptyState__ImageContainer\"],[12],[1,\"\\n\"],[1,\"      \"],[10,\"img\"],[14,\"data-test-empty-state-image\",\"\"],[14,\"role\",\"presentation\"],[14,\"alt\",\"\"],[14,0,\"Polaris-EmptyState__Image\"],[15,\"src\",[30,8]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@imageContained\",\"&attrs\",\"@heading\",\"@text\",\"@secondaryAction\",\"Details\",\"@image\",\"&default\"],false,[\"if\",\"let\",\"component\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-empty-state.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});