define("@smile-io/ember-smile-polaris/templates/components/polaris-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HB4MvHgr",
    "block": "[[[8,[39,0],[[17,1]],[[\"@inputId\",\"@label\",\"@labelHidden\",\"@helpText\",\"@error\",\"@disabled\"],[[30,0,[\"_id\"]],[30,2],[30,0,[\"labelHidden\"]],[30,3],[30,4],[30,0,[\"disabled\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[15,0,[29,[\"Polaris-Checkbox \",[52,[30,4],\"Polaris-Checkbox--error\"]]]],[12],[1,\"\\n    \"],[10,\"input\"],[14,\"data-test-checkbox-input\",\"\"],[15,\"data-test-checkbox-input-checked\",[30,0,[\"isChecked\"]]],[14,\"role\",\"checkbox\"],[15,1,[30,0,[\"_id\"]]],[15,3,[36,2]],[15,2,[36,3]],[15,\"checked\",[30,0,[\"isChecked\"]]],[15,\"disabled\",[30,0,[\"disabled\"]]],[15,0,[30,0,[\"checkboxClasses\"]]],[15,\"aria-checked\",[30,0,[\"checkedState\"]]],[15,\"aria-invalid\",[52,[30,4],\"true\",\"false\"]],[15,\"aria-describedby\",[30,0,[\"describedBy\"]]],[15,\"indeterminate\",[52,[30,0,[\"isIndeterminate\"]],\"true\"]],[15,\"onchange\",[30,0,[\"handleChange\"]]],[15,\"onfocus\",[30,0,[\"onFocus\"]]],[15,\"onblur\",[30,0,[\"onBlur\"]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"Polaris-Checkbox__Backdrop\"],[12],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"Polaris-Checkbox__Icon\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@source\"],[[52,[30,0,[\"isIndeterminate\"]],\"subtract\",\"checkmark\"]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@label\",\"@helpText\",\"@error\"],false,[\"polaris-choice\",\"if\",\"name\",\"value\",\"polaris-icon\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});