define('ember-context/components/context-provider', ['exports', 'ember-context/templates/components/context-provider'], function (exports, _contextProvider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    layout: _contextProvider.default,

    /**
     * The current value of the context.
     *
     * @property value
     * @type {any}
     * @public
     */
    value: null,

    /**
     * Unique provider ID, used to select the
     * appropriate provider for a given consumer.
     *
     * @property _providerId
     * @type {String}
     * @private
     */
    _providerId: null
  });
});