define("@smile-io/ember-smile-polaris/templates/components/polaris-skeleton-body-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+m3Btrfs",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-SkeletonBodyText__SkeletonBodyTextContainer \",[30,1],\" \"]]],[17,2],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"dummyLines\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"Polaris-SkeletonBodyText\"],[12],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-skeleton-body-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});