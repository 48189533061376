define("ember-svg-jar/inlined/drag-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#31373D\" fill-rule=\"evenodd\" d=\"M19.317 12.052L14 10.28V5a1 1 0 00-1-1H5a1 1 0 00-1 1v8a1 1 0 001 1h5.28l1.772 5.317a.998.998 0 001.843.13l1.85-3.701 3.703-1.851a1 1 0 00-.131-1.843zM2 16a1 1 0 10-2 0v1a1 1 0 001 1h1a1 1 0 100-2M16 2a1 1 0 102 0V1a1 1 0 00-1-1h-1a1 1 0 100 2M6 2h1a1 1 0 100-2H6a1 1 0 100 2m5 0h1a1 1 0 100-2h-1a1 1 0 100 2M2 0H1a1 1 0 00-1 1v1a1 1 0 102 0 1 1 0 100-2M1 8a1 1 0 001-1V6a1 1 0 10-2 0v1a1 1 0 001 1m0 5a1 1 0 001-1v-1a1 1 0 10-2 0v1a1 1 0 001 1m13.553 1.105a1.002 1.002 0 00-.447.447l-.921 1.841-1.604-4.812 4.813 1.604-1.841.92zM6 12.002V6h6v3.612l-1.683-.561a.998.998 0 10-1.265 1.265L9.612 12H6zM7 16H6a1 1 0 100 2h1a1 1 0 100-2M17 5a1 1 0 00-1 1v1a1 1 0 102 0V6a1 1 0 00-1-1\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});