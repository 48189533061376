define("ember-smile-core/templates/components/data-loader", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zNxgdpm6",
    "block": "[[[18,1,[[33,1]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"publicApi\"]]",
    "moduleName": "ember-smile-core/templates/components/data-loader.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});