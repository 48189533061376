define("ember-smile-core/components/polaris-app-bottom-bar", ["exports", "@ember/component", "ember-smile-core/templates/components/polaris-app-bottom-bar"], function (_exports, _component, _polarisAppBottomBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['polaris-app-bottom-bar'],
    layout: _polarisAppBottomBar.default
  });

  _exports.default = _default;
});