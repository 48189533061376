define("@smile-io/ember-smile-polaris/templates/components/polaris-page/action-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "L+n/8S2C",
    "block": "[[[11,1],[16,0,[29,[\"Polaris-Header-Action__ActionIcon \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@source\",\"@keepFills\"],[[30,3],false]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@icon\"],false,[\"polaris-icon\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-page/action-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});