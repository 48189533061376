define("ember-smile-core/components/read-only-text-field", ["exports", "@ember/component", "@ember/object/computed", "@ember/application/deprecations", "ember-smile-core/templates/components/read-only-text-field"], function (_exports, _component, _computed, _deprecations, _readOnlyTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _readOnlyTextField.default,

    /**
     * When true, will render a copy button
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    copyable: false,

    /**
     * When true, will render a delete button
     *
     * @type {Boolean}
     * @public
     * @deprecated In favor of just passing a `onDelete` callback
     */
    deleteable: null,

    /**
     * When true, will render a togglable Show/Hide button.
     * Text field's value is masked on initial render, by default.
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    hideable: false,

    /**
     * The text-field's value
     *
     * @type {String}
     * @default null
     * @public
     */
    value: null,

    /**
     * The text-field's label
     *
     * @type {String}
     * @default null
     * @public
     */
    label: null,

    /**
     * The text-field's help text
     *
     * @type {String}
     * @default null
     * @public
     * @deprecated Use `helpText` instead
     */
    hint: null,

    /**
     * The text-field's help text
     *
     * @type {String}
     * @default null
     * @public
     */
    helpText: null,

    /**
     * When present, a delete button will be rendered and this callback will be invoked
     * when the button is pressed
     *
     * @type {Function}
     * @default no-op
     * @public
     */
    onDelete: null,

    /**
     * When true, the text field's value is masked
     *
     * @type {Boolean}
     * @public
     */
    isMasked: (0, _computed.reads)('hideable'),

    /**
     * Callback for when the copy was successful
     *
     * @type {Function}
     * @default no-op
     * @public
     */
    onCopySuccess: function onCopySuccess() {},

    /**
     * Callback for when the copy was unsuccessful
     *
     * @type {Function}
     * @default no-op
     * @public
     */
    onCopyError: function onCopyError() {},
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.deleteable === null) && (0, _deprecations.deprecate)('[ember-smile-core/read-only-text-field] The `deleteable` attribute is deprecated in favor of using the `onDelete` callback!', this.deleteable === null, {
        id: 'ember-smile-core.read-only-text-field.deleteable',
        until: '4.0.0'
      }));
      (false && !(this.hint === null) && (0, _deprecations.deprecate)('[ember-smile-core/read-only-text-field] The `hint` attribute is deprecated in favor of `helpText` attribute!', this.hint === null, {
        id: 'ember-smile-core.read-only-text-field.hint',
        until: '4.0.0'
      }));
    }
  });

  _exports.default = _default;
});