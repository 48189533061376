define("ember-smile-core/templates/components/polaris-tabs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "advHgGGu",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,\"ul\"],[14,\"role\",\"tablist\"],[15,0,[29,[\"Polaris-Tabs\\n    \",[52,[33,1],\"Polaris-Tabs--fitted\"],\"\\n    \",[52,[33,2],\"with-border-top\"],\"\\n  \"]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,\"role\",\"presentation\"],[14,0,\"Polaris-Tabs__TabContainer\"],[12],[1,\"\\n\"],[41,[30,1,[\"action\"]],[[[1,\"          \"],[8,[39,6],null,[[\"@class\",\"@onClick\"],[[29,[\"Polaris-Tabs__Tab \",[52,[30,1,[\"activeWhen\"]],\"Polaris-Tabs__Tab--selected\"]]],[28,[37,7],[[30,0],[30,1,[\"action\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[14,0,\"Polaris-Tabs__Title\"],[12],[1,[30,1,[\"text\"]]],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],[[[6,[39,8],null,[[\"params\",\"class\",\"activeClass\"],[[30,1],\"Polaris-Tabs__Tab\",\"Polaris-Tabs__Tab--selected\"]],[[\"default\"],[[[[1,\"            \"],[10,1],[14,0,\"Polaris-Tabs__Title\"],[12],[1,[30,1,[\"text\"]]],[13],[1,\"\\n\"]],[]]]]]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"tab\"],false,[\"if\",\"fitted\",\"withBorderTop\",\"each\",\"-track-array\",\"tabs\",\"polaris-link\",\"action\",\"dynamic-link\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-tabs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});