define("ember-smile-core/components/callout-panel", ["exports", "@ember/component", "ember-smile-core/templates/components/callout-panel"], function (_exports, _component, _calloutPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Callout panel.
   *
   * This is mostly the polaris-callout-card, except the wrapping card:
   * has a title, text, illustration and 1 or 2 buttons.
   */
  var _default = _component.default.extend({
    classNames: ['Callout-Panel'],
    layout: _calloutPanel.default,

    /**
     * The content to display inside the callout panel.
     *
     * This component can be used in block form,
     * in which case the block content will be used
     * instead of `text`
     *
     * @property text
     * @type {String}
     * @default null
     */
    text: null,

    /**
     * The title of the panel
     *
     * @property title
     * @type {String}
     * @default null
     */
    title: null,

    /**
     * URL to the panel illustration
     *
     * @property illustration
     * @type {String}
     * @default null
     */
    illustration: null,

    /**
     * Primary action for the panel
     *
     * @property primaryAction
     * @type {Object}
     * @default null
     */
    primaryAction: null,

    /**
     * Secondary action for the panel
     *
     * @property secondaryAction
     * @type {Object}
     * @default null
     */
    secondaryAction: null,
    actions: {
      click: function click(action) {
        if (typeof action.onAction !== 'function') {
          return;
        }

        return action.onAction();
      }
    }
  });

  _exports.default = _default;
});