define("ember-smile-core/templates/components/popup-menu-trigger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bPQKcTXI",
    "block": "[[[8,[39,0],null,[[\"@contentClass\",\"@renderInPlace\"],[[30,1],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"activator\"]],[[24,0,\"activator\"]],null,[[\"default\"],[[[[1,\"\\n\"],[1,\"    \"],[1,[28,[35,1],null,[[\"source\",\"color\"],[\"ellipsis\",\"inkLighter\"]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[6,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"items\",\"onActionAnyItem\"],[[33,3],[28,[37,4],[[30,0],[30,2,[\"close\"]]],null]]]]],[1,\"\\n\"]],[]]]]]],[2]]]]],[1,\"\\n\\n\"],[18,3,null],[1,\"\\n\"]],[\"@contentClass\",\"popover\",\"&default\"],false,[\"polaris-popover\",\"polaris-icon\",\"polaris-action-list\",\"items\",\"action\",\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/popup-menu-trigger.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});