define("@smile-io/ember-smile-polaris/templates/components/polaris-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ck1EyJR1",
    "block": "[[[11,\"img\"],[24,\"alt\",\"\"],[16,0,[30,0,[\"spinnerClass\"]]],[24,\"draggable\",\"false\"],[24,\"role\",\"status\"],[24,\"data-test-spinner\",\"\"],[17,1],[16,\"src\",[30,0,[\"spinnerSVG\"]]],[16,\"aria-label\",[30,2]],[12],[13],[1,\"\\n\"]],[\"&attrs\",\"@accessibilityLabel\"],false,[]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});