define("ember-smile-core/components/popup-color-picker", ["exports", "@ember/component", "@ember/object", "@ember/object/internals", "@smile-io/ember-smile-polaris/utils/color", "ember-smile-core/utils/noop", "ember-smile-core/templates/components/popup-color-picker"], function (_exports, _component, _object, _internals, _color, _noop, _popupColorPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _popupColorPicker.default,

    /**
     * Label for color picker control.
     *
     * @property label
     * @type {String}
     * @default null
     * @public
     */
    label: null,

    /**
     * Hint to display beneath picker activator.
     *
     * @property helpText
     * @type {String|Component|Object}
     * @default null
     * @public
     */
    helpText: null,

    /**
     * Error to display (if any).
     *
     * @property error
     * @type {String}
     * @default null
     * @public
     */
    error: null,

    /**
     * The color value string.
     *
     * @property value
     * @type {String}
     * @default null
     * @public
     */
    value: null,

    /**
     * Action fired when the color popover is closed.
     *
     * @property onPopoverClosed
     * @type {Function}
     * @default noop
     * @public
     */
    onPopoverClosed: _noop.default,

    /**
     * Action fired when the color value is updated.
     *
     * @property onChange
     * @type {Function}
     * @default noop
     * @public
     */
    onChange: _noop.default,
    choiceId: (0, _object.computed)(function () {
      return "popup-color-picker-".concat((0, _internals.guidFor)(this));
    }),

    /**
     * The color object used for selecting colors
     * in the `polaris-color-picker`.
     *
     * @property color
     * @type {Object}
     * @private
     */
    color: (0, _object.computed)('value', function () {
      return (0, _color.hexToHsb)(this.get('value'));
    }),
    updateColorValue: function updateColorValue(value) {
      // Call the update action with a hex color.
      this.get('onChange')((0, _color.hsbToHex)(value));
    }
  });

  _exports.default = _default;
});