define("@smile-io/ember-smile-polaris/utils/dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.wrapChildren = wrapChildren;
  _exports.rejectNodesByClassName = rejectNodesByClassName;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * Utility to wrap nodes in wrapper nodes
   *
   * @param {Array} nodesToWrap   List of nodes to be wrapped
   * @param {HtmlNode} wrapper    The node to wrap matching child nodes in.
   *
   */
  function wrapChildren(nodesToWrap, wrapper) {
    var clonedWrapper;
    nodesToWrap.forEach(function (node) {
      clonedWrapper = wrapper.cloneNode();
      node.parentNode.insertBefore(clonedWrapper, node);
      clonedWrapper.appendChild(node);
    });
  }
  /**
   * Convert an htmlCollection into a filtered array.
   *
   */


  function rejectNodesByClassName(htmlCollection, className) {
    return _toConsumableArray(htmlCollection).filter(function (el) {
      return !el.classList.contains(className);
    });
  }
});