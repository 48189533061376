define("ember-route-helpers/utils/mount-point", ["exports", "@ember/application/deprecations", "@ember/utils", "@ember/debug"], function (_exports, _deprecations, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prefixMountPoint = prefixMountPoint;
  _exports.getMountPoint = getMountPoint;

  /**
   * Prefix a property (usually a routeName) with the mount point, if is an engine.
   *
   * @param  {String} mountPoint
   * @param  {String} propValue
   * @return {String}
   */
  function prefixMountPoint(mountPoint, propValue) {
    if ((0, _utils.typeOf)(propValue) !== 'string') {
      (false && !((0, _utils.typeOf)(propValue) !== 'string') && (0, _debug.assert)('propValue argument must be an string', (0, _utils.typeOf)(propValue) !== 'string'));
    }

    if ((0, _utils.typeOf)(mountPoint) !== 'string' || (0, _utils.isBlank)(mountPoint)) {
      return propValue;
    }

    if (propValue === 'application') {
      (false && !(false) && (0, _deprecations.deprecate)("Use \"".concat(mountPoint, "\" instead of \"").concat(propValue, "\" as the routeName of this helper."), false, {
        id: 'ember-route-helpers',
        until: '2.2.0'
      }));
      return mountPoint;
    }

    (false && !(false) && (0, _deprecations.deprecate)("Use \"".concat(mountPoint, ".").concat(propValue, "\" instead of \"").concat(propValue, "\" as the routeName of this helper."), false, {
      id: 'ember-route-helpers',
      until: '2.2.0'
    }));
    return "".concat(mountPoint, ".").concat(propValue);
  }
  /**
   * Check if an element is into an engine and returns its mountPoint.
   *
   * @method getMountPoint
   * @param {Object} owner
   * @return {string|Boolean}. Mount point if is engine; false otherwise.
   */


  function getMountPoint(owner) {
    if (owner && typeof owner.mountPoint === 'string') {
      return owner.mountPoint;
    }

    return false;
  }
});