define("ember-svg-jar/inlined/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 8c0-2.967-2.167-5.432-5-5.91V1a1 1 0 10-2 0v1.09C6.167 2.568 4 5.033 4 8c0 2.957 0 4.586-1.707 6.293A1 1 0 003 16h4.183A2.909 2.909 0 007 17c0 1.654 1.345 3 3 3s3-1.346 3-3c0-.353-.07-.687-.184-1H17a1 1 0 00.707-1.707C16 12.586 16 10.957 16 8zM5.011 14C6 12.208 6 10.285 6 8c0-2.206 1.794-4 4-4s4 1.794 4 4c0 2.285 0 4.208.989 6H5.011zM11 17a1.001 1.001 0 01-2 0 1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});