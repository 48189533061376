define('ember-context/components/context-consumer', ['exports', 'ember-context/templates/components/context-consumer'], function (exports, _contextConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    layout: _contextConsumer.default
  });
});