define("ember-svg-jar/inlined/glyph-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>glyph-grey</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M0 5.53A5.531 5.531 0 015.53 0h24.94A5.531 5.531 0 0136 5.53v24.94A5.531 5.531 0 0130.47 36H5.53A5.531 5.531 0 010 30.47V5.53z\" fill=\"#C3C9DE\"/><path d=\"M24.17 14.4H23.09c-.216 0-.4.077-.556.233a.731.731 0 00-.235.5c-.003.116-.02.799-.058 1.23-.117 1.25-.532 2.255-1.243 3.016-.706.756-1.704 1.136-2.996 1.142-1.292-.006-2.291-.386-2.998-1.142-.711-.761-1.126-1.766-1.243-3.016-.039-.431-.055-1.114-.058-1.23a.731.731 0 00-.235-.5.756.756 0 00-.556-.233H11.83c-.216 0-.4.077-.557.233-.14.142-.233.282-.233.528 0 0 .038 1.123.058 1.347.176 1.993.863 3.579 2.063 4.76 1.188 1.17 2.793 1.761 4.815 1.772h.048c2.022-.011 3.627-.601 4.815-1.772 1.2-1.181 1.887-2.767 2.063-4.76.02-.224.058-1.347.058-1.347 0-.246-.093-.386-.233-.528a.759.759 0 00-.557-.233z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});