define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "activity-definition/custom-card": "__9eb73",
    "collapsible-list": "__72cbf",
    "icon-upload": "__5885c",
    "oauth2-application/api-permissions-card": "__9a4d2",
    "oauth2-permission-group/pick-permission": "__4d897",
    "partner-user-profile": "__d59fd",
    "resources-card": "__45bb0",
    "route-page/apps/app": "__85355",
    "settings-page": "__b6f64",
    "webhook-event-definition/view-item": "__65f20"
  };
  _exports.default = _default;
});