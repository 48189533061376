define("@smile-io/ember-smile-polaris/templates/components/polaris-stack", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "heKggYll",
    "block": "[[[11,0],[16,\"data-test-stack\",true],[16,0,[30,0,[\"cssClasses\"]]],[17,1],[4,[38,0],[[30,0,[\"setupAutoWrapper\"]]],null],[4,[38,1],[[30,0,[\"teardownAutoWrapper\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[28,[37,5],null,[[\"item\"],[[50,\"polaris-stack/item\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,7]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"text\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-stack.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});