define("ember-smile-core/components/editable-list/existing-items", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/string", "ember-inflector", "ember-smile-core/templates/components/editable-list/existing-items"], function (_exports, _component, _object, _computed, _string, _emberInflector, _existingItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _existingItems.default,

    /**
     * If true, will yield the list of items so user controls display totally.
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    overwriteListLayout: false,
    isEditMode: (0, _computed.reads)('editableList.isEditMode'),
    title: (0, _computed.or)('editableList.itemsTitle', 'defaultTitle'),
    hideNoItemsText: (0, _computed.reads)('editableList.hideNoItemsText'),
    itemName: (0, _computed.reads)('editableList.itemName'),
    placeholder: (0, _computed.reads)('editableList.itemPlaceholder'),
    canDeleteExistingItems: (0, _computed.reads)('editableList.canDeleteExistingItems'),
    defaultTitle: (0, _object.computed)('itemName', function () {
      return (0, _string.capitalize)((0, _emberInflector.pluralize)(this.itemName));
    }).readOnly()
  });

  _exports.default = _default;
});