define("@smile-io/ember-smile-polaris/components/component-proxy", ["exports", "@ember/component", "@ember/object", "@ember/template-compilation", "@ember/polyfills"], function (_exports, _component, _object, _templateCompilation, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Renders a named component with a passed-in hash of properties.
  // This effectively allows us to spread/splat a props hash onto
  // another component.
  //
  // Example - dynamically rendering a my-button component with some props:
  //   {{component-proxy
  //     componentName="my-button"
  //     props=(hash
  //       buttonText="Click me"
  //       onClick=(action "onButtonClicked")
  //     )
  //   }}
  var _default = _component.default.extend({
    tagName: '',

    /**
     * Dynamically-generated layout to render the proxied component.
     */
    layout: (0, _object.computed)('componentName', 'propsString', function () {
      var componentName = this.componentName,
          propsString = this.propsString;
      return (0, _templateCompilation.compileTemplate)("\n      {{#if hasBlock}}\n        {{#component \"".concat(componentName, "\"").concat(propsString, "}}\n          {{yield}}\n        {{/component}}\n      {{else}}\n        {{component \"").concat(componentName, "\"").concat(propsString, "}}\n      {{/if}}\n    "));
    }).readOnly(),

    /**
     * The name of the component to render.
     * @type {String}
     * @public
     * @required
     */
    componentName: null,

    /**
     * Optional hash of properties to pass to the component being rendered.
     * @type {Object}
     * @public
     */
    props: null,

    /**
     * List of properties that were previously set via the `props` hash.
     * @type {Array}
     */
    propNames: null,

    /**
     * String representing the properties to pass to the proxied component.
     * @type {String}
     */
    propsString: (0, _object.computed)('propNames.[]', function () {
      var propNames = this.propNames || [];
      return propNames.reduce(function (propsString, propName) {
        return "".concat(propsString, " ").concat(propName, "=").concat(propName);
      }, '');
    }).readOnly(),
    updateProps: function updateProps() {
      // Unset any properties that were set previously and are now undefined,
      // spread the new passed-in props hash into our component's context,
      // and make a note of which properties are currently set.
      var oldPropNames = this.propNames || [];
      var props = this.props || {};
      var propNames = Object.keys(props);
      var propsToUnset = oldPropNames.reduce(function (propsToUnset, propName) {
        propsToUnset[propName] = undefined;
        return propsToUnset;
      }, {});
      var newProps = (0, _polyfills.assign)(propsToUnset, props, {
        propNames: propNames
      });
      this.setProperties(newProps);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.updateProps();
    }
  });

  _exports.default = _default;
});