define("ember-smile-core/components/polaris-app-nav-item", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop", "ember-smile-core/templates/components/polaris-app-nav-item", "@ember/object/computed"], function (_exports, _component, _object, _service, _runloop, _polarisAppNavItem, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _polarisAppNavItem.default,
    router: (0, _service.inject)(),

    /**
     * Navigation item icon SVG
     *
     * @type {String}
     * @public
     * @default null
     */
    icon: null,

    /**
     * Navigation item label
     *
     * @type {String}
     * @public
     * @default null
     */
    label: null,

    /**
     * Navigation item extra label text (displayed at the right of the label),
     * if no `navActionParams` is present
     *
     * @type {String}
     * @default null
     * @public
     */
    labelExtra: null,

    /**
     * Allows us to render additional content under the main nav item entry.
     *
     * @type {String|Component|Object}
     * @default null
     * @public
     */
    details: null,

    /**
     * Params object in a `dynamic-link` compatible format
     * See https://github.com/asross/dynamic-link
     *
     * @type {Object}
     * @public
     * @default null
     */
    linkParams: null,

    /**
     * Whether this navigation item is the child of another one.
     * NOTE: only one-level deep supported.
     *
     * @type {Boolean}
     * @public
     * @default false
     */
    isChild: false,

    /**
     * Navigation action to display at the right of the main navigation label. Optional.
     * Has form
     *    { icon: ..., iconClasses: ..., label, params: ... }
     *
     *  - label:          Optional. Text to display.
     *  - badgeStatus:    Optional. Defines the type of badge e.g. info
     *  - badgeText:      Optional. Text to display on Badge e.g. Beta
     *  - icon:           Optional. An SVG icon to render.
     *  - iconClasses:    Optional. Add a class to style the SVG.
     *  - linkParams:     Required (exception: badges use nav-item's linkParams). Params object in `dynamic-link` compatible format.
     *                    See https://github.com/asross/dynamic-link
     *
     * @type {Object}
     * @public
     * @default null
     */
    navActionParams: null,

    /**
     * Whether this item is disabled.
     *
     * @type {Boolean}
     * @public
     * @default false
     */
    isDisabled: false,

    /**
     * Used to keep track of whether this navigation item has childs or not
     *
     * @type {Number}
     * @private
     * @default 0
     */
    childrenCount: 0,

    /**
     * Whether we have child nav items or not
     *
     * @type {Boolean}
     * @private
     * @default false
     */
    hasChidren: (0, _computed.gt)('childrenCount', 0).readOnly(),
    isNavItemActive: (0, _object.computed)('router.currentRouteName', 'linkParams.{route,activeWhen}', function () {
      return this.get('linkParams.activeWhen') || this.get('router').isActive(this.get('linkParams.route'));
    }),
    navItemLinkClasses: (0, _object.computed)('isChild', 'hasChidren', 'isDisabled', function () {
      var classes = ['polaris-app-nav-link'];
      var itemClass = this.get('isChild') ? 'polaris-app-nav-link--child' : 'polaris-app-nav-link--parent';
      classes.push(itemClass); // For nav items that have child, we don't want to mark as active the main item, but only the child currently active

      if (this.get('hasChidren')) {
        classes.push('polaris-app-nav-link-item--has-children');
      }

      if (this.isDisabled) {
        classes.push('polaris-app-nav-link--disabled');
      }

      return classes.join(' ');
    }).readOnly(),
    onRegisterChildNavItem: function onRegisterChildNavItem() {},
    onUnregisterChildNavItem: function onUnregisterChildNavItem() {},
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.get('onRegisterChildNavItem')();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('onUnregisterChildNavItem')();
    },
    actions: {
      registerChildNavItem: function registerChildNavItem() {
        if (this.get('isChild')) {
          return;
        }

        (0, _runloop.scheduleOnce)('afterRender', this, this.incrementProperty, 'childrenCount');
      },
      unregisterChildNavItem: function unregisterChildNavItem() {
        if (this.get('isChild')) {
          return;
        }

        (0, _runloop.scheduleOnce)('afterRender', this, this.decrementProperty, 'childrenCount');
      }
    }
  });

  _exports.default = _default;
});