define("ember-smile-core/templates/components/polaris-app-nav-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mpxqzszR",
    "block": "[[[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"polaris-app-nav-item\",0,null,[[\"menuItemComponent\"],[[33,3]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"menuItemComponent\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-app-nav-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});