define("ember-changeset/helpers/changeset", ["exports", "@ember/component/helper", "ember-changeset", "ember-changeset/utils/validator-lookup", "ember-changeset/utils/is-changeset", "ember-changeset/utils/is-promise", "ember-changeset/utils/is-object"], function (_exports, _helper, _emberChangeset, _validatorLookup, _isChangeset, _isPromise, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function changeset(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        obj = _ref2[0],
        validations = _ref2[1];

    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if ((0, _isChangeset.default)(obj)) {
      return obj;
    }

    if ((0, _isObject.default)(validations)) {
      if ((0, _isPromise.default)(obj)) {
        return obj.then(function (resolved) {
          return new _emberChangeset.default(resolved, (0, _validatorLookup.default)(validations), validations, options);
        });
      }

      return new _emberChangeset.default(obj, (0, _validatorLookup.default)(validations), validations, options);
    }

    if ((0, _isPromise.default)(obj)) {
      return Promise.resolve(obj).then(function (resolved) {
        return new _emberChangeset.default(resolved, validations, {}, options);
      });
    }

    return new _emberChangeset.default(obj, validations, {}, options);
  }

  var _default = (0, _helper.helper)(changeset);

  _exports.default = _default;
});