define("ember-smile-core/helpers/rollback-changeset", ["exports", "@ember/component/helper", "@ember/debug", "@ember/utils", "ember-changeset/utils/is-changeset"], function (_exports, _helper, _debug, _utils, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rollbackChangeset = rollbackChangeset;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   *
   * Helper action to rollback a changeset entirely, or a specific
   * set of properties.
   *
   * Example:
   *    {{polaris-button
   *      ...
   *      @text="Cancel"
   *      @onClick=(rollback-changeset changeset (array "name" "email"))
   *    }}
   *
   * @export
   * @param {Changeset} changeset   The changeset. Required
   * @param {Array} properties      An array of specific properties to rollback. If not present,
   *                                the entire changeset will be rolled back.
   */
  function rollbackChangeset(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        changeset = _ref2[0],
        properties = _ref2[1];

    return function () {
      (false && !((0, _isChangeset.default)(changeset)) && (0, _debug.assert)('[rollback-changeset] Param should be a Changeset!', (0, _isChangeset.default)(changeset)));

      if ((0, _utils.isEmpty)(properties)) {
        return changeset.rollback();
      }

      properties.forEach(function (property) {
        changeset.rollbackProperty(property);
      });
      return changeset;
    };
  }

  var _default = (0, _helper.helper)(rollbackChangeset);

  _exports.default = _default;
});