define("ember-smile-core/templates/components/tab-panel/tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q35eFaOY",
    "block": "[[[10,\"button\"],[14,0,\"Tab__Button\"],[14,\"role\",\"tab\"],[15,1,[28,[37,0],[[33,1,[\"text\"]]],null]],[15,\"tabindex\",[52,[33,3],\"0\",\"-1\"]],[15,\"aria-selected\",[52,[33,3],\"true\",\"false\"]],[15,\"aria-controls\",[29,[[28,[37,0],[[33,1,[\"text\"]]],null],\"-panel\"]]],[15,\"onclick\",[28,[37,4],[[30,0],[33,5]],null]],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[51,[33,7]],[[[41,[48,[30,1]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],[[[41,[28,[37,10],[[33,1,[\"icon\"]],[33,1,[\"isComplete\"]]],null],[[[1,\"        \"],[10,1],[14,0,\"Tab__Button__Icon\"],[12],[1,\"\\n\"],[1,\"          \"],[1,[28,[35,11],null,[[\"keepFills\",\"source\"],[false,[52,[33,1,[\"isComplete\"]],\"smile/circle-check-mark\",[33,1,[\"icon\"]]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[1,[33,1,[\"text\"]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"    \"],[1,[34,12]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"dasherize\",\"tab\",\"if\",\"isActive\",\"action\",\"onClick\",\"unless\",\"isLoading\",\"has-block\",\"yield\",\"or\",\"polaris-icon\",\"polaris-skeleton-display-text\"]]",
    "moduleName": "ember-smile-core/templates/components/tab-panel/tab.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});