define("ember-smile-core/templates/components/auth-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "N7KZsNtK",
    "block": "[[[8,[39,0],[[16,0,[30,1]],[17,2]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,3,[\"section\"]],[[24,0,\"header-section\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"img\"],[14,0,\"header-logo\"],[15,\"src\",[30,4]],[14,\"alt\",\"Smile logo\"],[12],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,3,[\"section\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@vertical\",\"@spacing\"],[true,\"extraLoose\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,\"data-test-auth-card-heading-text\",\"\"]],[[\"@size\",\"@text\"],[\"large\",[30,0,[\"headingText\"]]]],null],[1,\"\\n\\n        \"],[8,[39,3],[[24,\"data-test-auth-card-subheading-text\",\"\"]],[[\"@size\",\"@text\"],[\"small\",[30,0,[\"subheadingText\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@cardClasses\",\"&attrs\",\"card\",\"@logoSrc\",\"&default\"],false,[\"polaris-card\",\"polaris-stack\",\"polaris-text-container\",\"polaris-display-text\",\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/auth-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});