define("@smile-io/ember-smile-polaris/components/render-content", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@smile-io/ember-smile-polaris/templates/components/render-content", "@smile-io/ember-smile-polaris/helpers/is-component-definition"], function (_exports, _component, _object, _computed, _renderContent, _isComponentDefinition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _renderContent.default,
    content: null,
    contentIsComponentHash: (0, _computed.notEmpty)('content.componentName').readOnly(),
    contentIsComponentDefinition: (0, _object.computed)('content', function () {
      return (0, _isComponentDefinition.isComponentDefinition)(this.content);
    }).readOnly()
  }).reopenClass({
    positionalParams: ['content']
  });

  _exports.default = _default;
});