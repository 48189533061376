define("@smile-io/ember-smile-polaris/templates/components/polaris-option-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XA3Tr1No",
    "block": "[[[11,\"ul\"],[16,\"role\",[30,1]],[16,0,[29,[\"Polaris-OptionList \",[30,2]]]],[17,3],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"normalizedOptions\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n\"],[41,[30,4,[\"title\"]],[[[1,\"        \"],[10,2],[14,0,\"Polaris-OptionList__Title\"],[15,\"role\",[30,1]],[12],[1,\"\\n          \"],[1,[30,4,[\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"Polaris-OptionList__Options\"],[15,1,[28,[37,3],[[30,6],[30,0,[\"id\"]]],null]],[15,\"role\",[30,1]],[15,\"aria-multiselectable\",[30,7]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,4,[\"options\"]]],null]],null],null,[[[1,\"          \"],[8,[39,4],null,[[\"@value\",\"@label\",\"@disabled\",\"@active\",\"@optionId\",\"@media\",\"@section\",\"@index\",\"@onClick\",\"@select\",\"@allowMultiple\",\"@role\"],[[30,8,[\"value\"]],[30,8,[\"label\"]],[30,8,[\"disabled\"]],[30,8,[\"active\"]],[28,[37,3],[[30,8,[\"id\"]],[28,[37,5],[[28,[37,3],[[30,6],[30,0,[\"id\"]]],null],\"-\",[30,5],\"-\",[30,9]],null]],null],[30,8,[\"media\"]],[30,5],[30,9],[28,[37,6],[[30,0,[\"handleClick\"]]],null],[28,[37,7],[[30,10],[30,8,[\"value\"]]],null],[30,7],[30,11]]],null],[1,\"\\n\"]],[8,9]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[4,5]],null],[13],[1,\"\\n\"]],[\"@role\",\"@class\",\"&attrs\",\"normalizedOption\",\"sectionIndex\",\"@id\",\"@allowMultiple\",\"option\",\"optionIndex\",\"@selected\",\"@optionRole\"],false,[\"each\",\"-track-array\",\"if\",\"or\",\"polaris-option-list/option\",\"concat\",\"fn\",\"array-contains\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-option-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});