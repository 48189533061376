define("@smile-io/ember-smile-polaris/templates/components/polaris-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hDys3Iw9",
    "block": "[[[44,[[50,[28,[37,2],[[52,[30,0,[\"isBulletListType\"]],\"ul\",\"ol\"]],null],0,null,[[\"tagName\"],[[52,[30,0,[\"isBulletListType\"]],\"ul\",\"ol\"]]]]],[[[1,\"  \"],[8,[30,1],[[16,0,[30,0,[\"listElementClass\"]]],[17,2]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[28,[37,5],null,[[\"item\"],[[50,\"polaris-list/item\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"List\",\"&attrs\",\"&default\"],false,[\"let\",\"component\",\"-element\",\"if\",\"yield\",\"hash\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});