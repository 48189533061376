define("ember-smile-core/components/polaris-app-top-bar-user-profile", ["exports", "@ember/component", "ember-smile-core/templates/components/polaris-app-top-bar-user-profile"], function (_exports, _component, _polarisAppTopBarUserProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['top-bar-item'],
    layout: _polarisAppTopBarUserProfile.default
  });

  _exports.default = _default;
});