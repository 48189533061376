define("ember-smile-core/templates/components/version-notifier", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fCE0zoAW",
    "block": "[[[8,[39,0],null,[[\"@condition\"],[[30,0,[\"versionManager\",\"shouldNotifyUser\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@named\",\"@send\"],[\"polaris-app-bottom-bar\",[50,\"footer-banner\",0,null,[[\"text\",\"primaryAction\",\"onDismiss\"],[[28,[37,3],[[30,1],\"An update is available. Refresh this page for the latest version\"],null],[28,[37,4],null,[[\"content\",\"loading\",\"onClick\"],[[28,[37,3],[[30,2],\"Refresh\"],null],[30,0,[\"isReloadingApp\"]],[28,[37,5],[[28,[37,6],[\"isReloadingApp\",[30,0]],null],[28,[37,7],[[30,0],[30,3],[30,0,[\"versionManager\",\"currentAppVersion\"]],[30,0,[\"versionManager\",\"newAppVersion\"]]],null],[28,[37,7],[[30,0],[30,0,[\"versionManager\",\"forceReloadApp\"]]],null]],null]]]],[28,[37,5],[[28,[37,7],[[30,0],[30,4],[30,0,[\"versionManager\",\"currentAppVersion\"]],[30,0,[\"versionManager\",\"newAppVersion\"]]],null],[28,[37,7],[[30,0],[30,0,[\"versionManager\",\"dismissUserNotification\"]]],null]],null]]]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@description\",\"@ctaText\",\"@onUpdate\",\"@onDismiss\"],false,[\"polaris-liquid-if\",\"to-elsewhere\",\"component\",\"or\",\"hash\",\"pipe\",\"toggle\",\"action\"]]",
    "moduleName": "ember-smile-core/templates/components/version-notifier.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});