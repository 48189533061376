define("@smile-io/ember-smile-polaris/templates/components/polaris-thumbnail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "s/UWpiPj",
    "block": "[[[11,1],[16,0,[29,[\"Polaris-Thumbnail \",[30,1],\" \",[30,0,[\"sizeClass\"]]]]],[17,2],[12],[1,\"\\n  \"],[10,\"img\"],[14,0,\"Polaris-Thumbnail__Image\"],[15,\"src\",[30,3]],[15,\"alt\",[30,4]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@source\",\"@alt\"],false,[]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-thumbnail.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});