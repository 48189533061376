define("@smile-io/ember-smile-polaris/utils/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stackedContent = stackedContent;
  var breakpoints = {
    navBarCollapsed: '769px',
    stackedContent: '1043px'
  };
  var noWindowMatches = {
    media: '',
    addListener: function addListener() {},
    removeListener: function removeListener() {},
    matches: false
  };

  function stackedContent() {
    return typeof window === 'undefined' ? noWindowMatches : window.matchMedia("(max-width: ".concat(breakpoints.stackedContent, ")"));
  }
});