define("@smile-io/ember-smile-polaris/templates/components/polaris-data-table/row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eb7RIJcK",
    "block": "[[[11,\"tr\"],[16,0,[29,[\"Polaris-DataTable__TableRow \",[30,1]]]],[24,\"data-test-data-table-row\",\"true\"],[17,2],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@height\",\"@text\",\"@contentType\",\"@fixed\",\"@truncate\"],[[28,[37,3],[[30,0,[\"bodyCellHeights\"]],[33,4]],null],[30,4],[28,[37,3],[[30,6],[30,5]],null],[28,[37,5],[[30,5],0],null],[30,7]]],null],[1,\"\\n\"]],[4,5]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@row\",\"cellText\",\"cellIndex\",\"@contentTypes\",\"@truncate\"],false,[\"each\",\"-track-array\",\"polaris-data-table/cell\",\"get\",\"index\",\"eq\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-data-table/row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});