define("ember-smile-core/components/editable-list/new-item", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-smile-core/templates/components/editable-list/new-item"], function (_exports, _component, _object, _computed, _newItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _newItem.default,

    /**
     * Allows hiding add item button.
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    hideAddItemButton: false,

    /**
     * When in edit mode, we have `Add` & ` Cancel` button. This allows to control
     * how the buttons are distributed (check `polaris-stack` for more).
     *
     * @type {String}
     * @public
     */
    distribution: 'leading',

    /**
     * Allows to completely customize the button used for `Add new item`.
     * ex: make it a primary button, etc
     *
     * @type {String|Component}
     * @public
     */
    addItemButtonComponent: 'polaris-button',
    isEditMode: (0, _computed.reads)('editableList.isEditMode'),
    itemName: (0, _computed.reads)('editableList.itemName'),
    newItemChangeset: (0, _computed.reads)('editableList.newItemChangeset'),
    placeholder: (0, _computed.reads)('editableList.itemPlaceholder'),
    helpText: (0, _computed.reads)('editableList.newItemHelpText'),
    addItemButtonText: (0, _object.computed)('itemName', function () {
      return "Add new ".concat(this.itemName);
    })
  });

  _exports.default = _default;
});