define("@smile-io/ember-smile-polaris/templates/components/polaris-subheading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Fo6askAq",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,1],[30,2],\"h3\"],null]],null],0,null,[[\"tagName\"],[[28,[37,3],[[30,1],[30,2],\"h3\"],null]]]]],[[[1,\"  \"],[8,[30,3],[[16,0,[29,[\"Polaris-Subheading \",[30,4]]]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[17,5],[4,[38,4],[[30,0,[\"setAriaLabel\"]]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@onRender\"],[[30,0,[\"updateAriaLabel\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"        \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,6]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[3]]]],[\"@htmlTag\",\"@tagName\",\"Subheading\",\"@class\",\"&attrs\",\"@text\",\"&default\"],false,[\"let\",\"component\",\"-element\",\"or\",\"did-insert\",\"render-detector\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-subheading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});