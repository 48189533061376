define("ember-smile-core/templates/components/polaris-resource-list/cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3HkF0Eab",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,3],[[33,4]],null]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"render-content\",\"component\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-resource-list/cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});