define("ember-smile-core/templates/components/resource-list-wrapper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NCB/lVDe",
    "block": "[[[6,[39,0],null,[[\"vertical\",\"wrap\"],[true,false]],[[\"default\"],[[[[41,[48,[30,2]],[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"      \"],[18,2,[[28,[37,4],null,[[\"items\",\"isLoading\",\"isShowingDummyItems\"],[[52,[33,5],[33,6],[33,7]],[33,8],[33,5]]]]]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[41,[28,[37,9],[[33,10],[33,11]],null],[[[1,\"    \"],[8,[30,1,[\"item\"]],[[24,0,\"pagination-wrapper\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,12],[[33,11]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"stack\",\"&default\"],false,[\"polaris-stack\",\"if\",\"has-block\",\"yield\",\"hash\",\"isShowingDummyItems\",\"dummyItems\",\"items\",\"isLoading\",\"and\",\"shouldShowPaginationControl\",\"paginationControl\",\"render-content\"]]",
    "moduleName": "ember-smile-core/templates/components/resource-list-wrapper.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});