define("ember-smile-core/templates/components/read-only-text-field-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S4m6VvmP",
    "block": "[[[6,[39,0],null,[[\"segmented\"],[true]],[[\"default\"],[[[[41,[33,2],[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,3],null,[[\"onClick\",\"text\"],[[28,[37,4],[\"isMasked\",[30,0]],null],[52,[33,5],\"Show\",\"Hide\"]]]]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[41,[33,6],[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[6,[39,7],null,[[\"tagName\",\"class\",\"clipboardText\",\"success\",\"error\"],[\"button\",\"Polaris-Button\",[33,8],[28,[37,9],[[30,0],[33,10]],null],[28,[37,9],[[30,0],[33,11]],null]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,12],null,[[\"source\",\"keepFills\"],[[33,13],false]]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]],null],[1,\"\\n\"],[41,[33,14],[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[28,[35,3],null,[[\"icon\",\"onClick\"],[[33,15],[28,[37,9],[[30,0],[33,14]],null]]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[1]]]]]],[\"group\"],false,[\"polaris-button-group\",\"if\",\"hideable\",\"polaris-button\",\"toggle\",\"isMasked\",\"copyable\",\"copy-button\",\"value\",\"action\",\"onCopySuccess\",\"onCopyError\",\"polaris-icon\",\"copyIcon\",\"onDelete\",\"deleteIcon\"]]",
    "moduleName": "ember-smile-core/templates/components/read-only-text-field-actions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});