define("@smile-io/ember-smile-polaris/templates/components/polaris-card/section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UXrf3Vxy",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[17,1],[12],[1,\"\\n\"],[44,[[50,\"polaris-card/section-header\",0,null,null]],[[[41,[30,3],[[[1,\"      \"],[8,[30,2],null,[[\"@title\"],[[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"      \"],[18,5,[[28,[37,5],null,[[\"header\",\"Header\"],[[30,2],[30,2]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]]]],[2]]],[13],[1,\"\\n\"]],[\"&attrs\",\"Header\",\"@title\",\"@text\",\"&default\"],false,[\"let\",\"component\",\"if\",\"has-block\",\"yield\",\"hash\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-card/section.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});