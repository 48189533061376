define("ember-smile-core/components/editable-list", ["exports", "@ember/component", "@ember/object", "@ember/string", "@ember/utils", "ember-changeset", "ember-changeset-validations/validators", "ember-changeset-validations", "ember-smile-core/templates/components/editable-list", "ember-smile-core/utils/computed/fallback-if-undefined"], function (_exports, _component, _object, _string, _utils, _emberChangeset, _validators, _emberChangesetValidations, _editableList, _fallbackIfUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Item = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Item = function Item(text, index) {
    _classCallCheck(this, Item);

    _defineProperty(this, "text", null);

    _defineProperty(this, "index", null);

    this.text = text;
    this.index = index;
  };
  /**
   * Contextual component that allows to edit an array of strings, with validations and all.
   * Composed of 2 child presentational components for:
   *  - list of items
   *  - adding new item
   *
   * Usage:
   *
   *  {{editable-list items=arrayOfString onChangeItem=(action (mut arrayOfString))}}
   *
   * Advanced usage:
   *
   * to customize more details, you can use this in block-form where you can overwrite
   * all the child details that are not customizable through this component OR just use
   * directly the yielded API to roll any custom presentation.
   *
   */


  _exports.Item = Item;

  var _default = _component.default.extend({
    tagName: '',
    layout: _editableList.default,

    /**
     * The list of strings to be edited.
     *
     * @type {String[]}
     * @public
     * @required
     */
    items: null,

    /**
     * Allows to hide text shown when there are no items.
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    hideNoItemsText: false,

    /**
     * If present, will render a title for the items section.
     *
     * @type {String|Component|Object}
     * @@default null
     * @public
     */
    itemsTitle: null,

    /**
     * Flag to toggle between edit/view mode.
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    isEditMode: false,

    /**
     * Placeholder to be used for the item text-fields.
     *
     * @type {String}
     * @default null
     * @public
     */
    itemPlaceholder: null,

    /**
     * Help text for new items.
     *
     * @type {String}
     * @default null
     * @public
     */
    newItemHelpText: null,

    /**
     * Callback triggered to toggle `isEditMode`.
     *
     * @type {Function}
     * @public
     */
    onToggleEditMode: null,

    /**
     * Flag to show a Delete button on the list view
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    canDeleteExistingItems: false,

    /**
     * Defines how each item in the list will be referred to.
     * This is also used when showing validation errors.
     *
     * @type {String}
     * @default 'item'
     * @public
     */
    itemName: (0, _fallbackIfUndefined.default)('item'),

    /**
     * Callback triggered when list of items changed.
     *
     * @type {Function}
     * @public
     * @required
     */
    onChangeItems: function onChangeItems() {},

    /**
     * Callback triggered when a new item is added to the list of items.
     *
     * @type {Function}
     * @public
     */
    onAddItem: function onAddItem() {},

    /**
     * Callback triggered when an item is removed from the list of items.
     *
     * @type {Function}
     * @public
     */
    onRemoveItem: function onRemoveItem() {},

    /**
     * Callback triggered when an item is changed.
     *
     * @type {Function}
     * @public
     */
    onChangeItem: function onChangeItem() {},
    existingItemsComponent: 'editable-list/existing-items',
    newItemComponent: 'editable-list/new-item',
    itemValidations: (0, _object.computed)('itemName', function () {
      return {
        text: (0, _validators.validatePresence)({
          presence: true,
          description: (0, _string.capitalize)(this.itemName)
        })
      };
    }),
    listAPI: (0, _object.computed)('hideNoItemsText', 'isEditMode', 'itemChangesets.[]', 'itemName', 'itemPlaceholder', 'itemsTitle', 'newItemChangeset', 'newItemHelpText', 'canDeleteExistingItems', function () {
      var _this = this;

      var actions = {
        updateItem: function updateItem() {
          return _this.updateItem.apply(_this, arguments);
        },
        removeItem: function removeItem() {
          return _this.removeItem.apply(_this, arguments);
        },
        addItem: function addItem() {
          return _this.addItem.apply(_this, arguments);
        },
        destroyNewItem: function destroyNewItem() {
          return _this.destroyNewItem.apply(_this, arguments);
        },
        toggleEditMode: function toggleEditMode() {
          return _this.toggleEditMode();
        }
      };
      return {
        isEditMode: this.isEditMode,
        itemName: this.itemName,
        itemChangesets: this.itemChangesets,
        itemsTitle: this.itemsTitle,
        hideNoItemsText: this.hideNoItemsText,
        newItemChangeset: this.newItemChangeset,
        itemPlaceholder: this.itemPlaceholder,
        newItemHelpText: this.newItemHelpText,
        canDeleteExistingItems: this.canDeleteExistingItems,
        actions: actions
      };
    }).readOnly(),
    toggleEditMode: function toggleEditMode() {
      if (typeof onToggleEditMode === 'function') {
        return this.onToggleEditMode();
      }

      this.toggleProperty('isEditMode');
    },
    setupItemChangesets: function setupItemChangesets() {
      var _this2 = this;

      var itemChangesetsCount = this.getWithDefault('itemChangesets.length', 0);
      var itemsCount = this.getWithDefault('items.length', 0);

      if (itemChangesetsCount === itemsCount) {
        return;
      }

      var itemChangesets = this.items.map(function (item, index) {
        return _this2.createItemChangeset({
          text: item,
          index: index
        });
      });
      this.set('itemChangesets', itemChangesets);
    },
    cleanupChangesets: function cleanupChangesets() {
      var itemChangesets = this.itemChangesets;

      if (!itemChangesets) {
        return;
      } // Destroy previous objects, if any


      itemChangesets.invoke('destroy');
    },
    createItemChangeset: function createItemChangeset() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$text = _ref.text,
          text = _ref$text === void 0 ? '' : _ref$text,
          _ref$index = _ref.index,
          index = _ref$index === void 0 ? null : _ref$index,
          _ref$skipValidate = _ref.skipValidate,
          skipValidate = _ref$skipValidate === void 0 ? false : _ref$skipValidate;

      var itemObj = new Item(text, index);
      return new _emberChangeset.default(itemObj, (0, _emberChangesetValidations.default)(this.itemValidations), this.itemValidations, {
        skipValidate: skipValidate
      });
    },

    /**
     * Creates a new item.
     * NOTE: Does not adds it to the list of the existing `items` just creates it
     * and allows user to edit this in a text-field. See `addItem` too.
     */
    createNewItem: function createNewItem() {
      this.set('newItemChangeset', this.createItemChangeset({
        skipValidate: true
      }));
    },
    destroyNewItem: function destroyNewItem() {
      var newItemChangeset = this.newItemChangeset;

      if ((0, _utils.isNone)(newItemChangeset)) {
        return;
      }

      newItemChangeset.destroy();
      this.set('newItemChangeset', null);
    },

    /**
     * Adds the `newItem` to the list of existing `items`
     *
     * @param {Changeset} changeset   The `newItem`'s changeset.
     */
    addItem: function addItem(changeset) {
      changeset.validate();

      if (changeset.get('isInvalid')) {
        return;
      }

      var items = _toConsumableArray(this.items);

      items.addObject(changeset.get('text'));
      this.onAddItem(changeset.get('text'));
      this.onChangeItems(items); // Create another `newItem`

      this.createNewItem();
    },

    /**
     * Removes an item
     *
     * @param {Changeset} changeset
     */
    removeItem: function removeItem(changeset) {
      var items = _toConsumableArray(this.items);

      items.removeAt(changeset.get('index'));
      this.onRemoveItem(changeset.get('index'), changeset.get('text'));
      this.onChangeItems(items);
      changeset.destroy();
    },

    /**
     * Updates an item
     *
     * @param {Changeset} changeset   The item changeset
     * @param {String}    value       The new value
     */
    updateItem: function updateItem(changeset, value) {
      changeset.set('text', value);
      changeset.validate();

      if (changeset.get('isInvalid')) {
        return;
      }

      var items = _toConsumableArray(this.items);

      items.set(changeset.get('index').toString(), value);
      this.onChangeItem(changeset.get('index'), changeset.get('text'));
      this.onChangeItems(items);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.createNewItem();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupItemChangesets();
    },
    willDestroyElement: function willDestroyElement() {
      // Clean up
      this.cleanupChangesets();
      this.destroyNewItem();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});