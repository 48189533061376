define("ember-svg-jar/inlined/avatar-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-2{fill:#2db167}</style></defs><path d=\"M0 0h70.02v100H0z\" fill=\"#8dc958\"/><path class=\"cls-2\" d=\"M69.92 0v56.32L49 67l-.3 25.07V100H100V0z\"/><path d=\"M59.27 58.29a5.22 5.22 0 00-9.43 4.48\" fill=\"#245b48\"/><path class=\"cls-2\" d=\"M27.12 9.33h44.53v2.19H27.12zm-12.41 9.49h56.94v2.19H14.71z\"/><circle cx=\"19.66\" cy=\"44.8\" r=\"11.22\" fill=\"#ebedf1\" opacity=\".2\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});