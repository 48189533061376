define("@smile-io/ember-smile-polaris/templates/components/polaris-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j8J+7+2A",
    "block": "[[[11,\"form\"],[24,\"data-test-form\",\"\"],[16,0,[30,1]],[17,2],[16,\"acceptCharset\",[30,3]],[16,\"action\",[30,4]],[16,\"autocomplete\",[30,0,[\"autoCompleteInputs\"]]],[16,\"enctype\",[30,5]],[16,\"method\",[28,[37,0],[[33,1],\"post\"],null]],[16,3,[30,6]],[16,\"novalidate\",[30,7]],[16,\"target\",[30,8]],[4,[38,2],[\"submit\",[30,0,[\"handleSubmit\"]]],null],[12],[1,\"\\n  \"],[18,10,null],[1,\"\\n\\n\"],[41,[30,9],[[[1,\"    \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,\"button\"],[14,\"aria-hidden\",\"true\"],[14,\"data-test-form-button\",\"\"],[14,4,\"submit\"],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@acceptCharset\",\"@action\",\"@encType\",\"@name\",\"@noValidate\",\"@target\",\"@implicitSubmit\",\"&default\"],false,[\"or\",\"method\",\"on\",\"yield\",\"if\",\"polaris-visually-hidden\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});