define("ember-keyboard/initializers/ember-keyboard-first-responder-inputs", ["exports", "@ember/component/text-area", "@ember/component/text-field", "ember-keyboard", "@ember/debug", "@ember/object"], function (_exports, _textArea, _textField, _emberKeyboard, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (application) {
      var config = application.resolveRegistration('config:environment') || {};
      var disableInputsInitializer = Boolean((0, _object.get)(config, 'emberKeyboard.disableInputsInitializer'));

      if (disableInputsInitializer) {
        return;
      }
    }

    (false && !(false) && (0, _debug.deprecate)('The `ember-keyboard-first-responder-inputs` initializer is deprecated and will be removed in 7.0. Please use the `on-key` modifier with your text fields instead.', false, {
      id: 'ember-keyboard.first-responder-inputs',
      until: '7.0.0',
      url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#first-responder-inputs'
    }));

    _textField.default.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);

    _textArea.default.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
  }

  var _default = {
    name: 'ember-keyboard-first-responder-inputs',
    initialize: initialize
  };
  _exports.default = _default;
});