define("ember-smile-core/templates/components/polaris-app-top-bar-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FvuAK+Fz",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,1,[\"activator\"]],null,null,[[\"default\"],[[[[1,\"    \"],[46,[33,2],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"    \"],[46,[33,3],null,[[\"onSelect\"],[[28,[37,4],[[30,0],[30,1,[\"close\"]]],null]]],null],[1,\"\\n\"]],[]]]]]],[1]]]]]],[\"popover\"],false,[\"polaris-popover\",\"component\",\"profileTriggerComponent\",\"profileMenuComponent\",\"action\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-app-top-bar-profile.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});