define("ember-svg-jar/inlined/polaris/confetti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M1.65 18.329a2.22 2.22 0 002.33.517L13 14 6 7l-4.867 9a2.22 2.22 0 00.517 2.329\" fill=\"#FFF\"/><path d=\"M6.707 6.293a1.003 1.003 0 00-1.587.232l-4.866 9a.986.986 0 00-.06.133 3.23 3.23 0 00.75 3.378 3.23 3.23 0 003.377.749c.045-.016.09-.036.132-.058l9.021-4.846a.998.998 0 00.233-1.588l-7-7zM3.58 17.926a1.222 1.222 0 01-1.525-1.524l4.187-7.746 5.101 5.1-7.763 4.17zM15 4a1 1 0 011 1 1 1 0 102 0 1 1 0 011-1 1 1 0 100-2 1 1 0 01-1-1 1 1 0 10-2 0 1 1 0 01-1 1 1 1 0 100 2M1 4a1 1 0 011 1 1 1 0 102 0 1 1 0 011-1 1 1 0 100-2 1 1 0 01-1-1 1 1 0 10-2 0 1 1 0 01-1 1 1 1 0 100 2m18 12a1 1 0 01-1-1 1 1 0 10-2 0 1 1 0 01-1 1 1 1 0 100 2 1 1 0 011 1 1 1 0 102 0 1 1 0 011-1 1 1 0 100-2m-7-7a.997.997 0 00.707-.293l1-1a.999.999 0 10-1.414-1.414l-1 1A.999.999 0 0012 9m6.684.052l-3 1a1 1 0 10.633 1.896l3-1a1 1 0 10-.633-1.896m-10-4.104a1.001 1.001 0 001.265-.632l1-3A1 1 0 009.052.683l-1 3a1 1 0 00.632 1.265\" fill=\"#919EAB\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});