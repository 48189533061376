define("@smile-io/ember-smile-polaris/templates/components/polaris-page/action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vBIyWG3X",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[30,0,[\"cssClasses\"]]],[16,\"aria-label\",[30,1]],[16,\"disabled\",[30,2]],[17,3],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"handleClick\"]]],null]],null],[4,[38,0],[\"mouseup\",[30,0,[\"handleMouseUpByBlurring\"]]],null],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,4],[30,5]],null],[[[1,\"    \"],[10,1],[14,0,\"Polaris-Header-Action__ActionContent\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[8,[39,4],null,[[\"@icon\"],[[30,4]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,1],[12],[1,[30,6]],[13],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"        \"],[8,[39,4],null,[[\"@icon\"],[\"caret-down\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,6]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@accessibilityLabel\",\"@disabled\",\"&attrs\",\"@icon\",\"@disclosure\",\"@text\"],false,[\"on\",\"stop-propagation\",\"if\",\"or\",\"polaris-page/action-icon\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-page/action.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});