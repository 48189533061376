define("ember-smile-core/templates/components/smile-s3-uploader", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "488MJ4Cw",
    "block": "[[[18,1,[[28,[37,1],null,[[\"hasValidationErrors\",\"files\",\"acceptedFiles\",\"processingFiles\",\"rejectedFiles\",\"invalidFiles\",\"progress\",\"isProcessingFiles\",\"invalidFilesBanner\",\"dropFiles\",\"reset\"],[[28,[37,2],[[33,3]],null],[28,[37,2],[[33,4]],null],[28,[37,2],[[33,5]],null],[28,[37,2],[[33,6]],null],[28,[37,2],[[33,7]],null],[28,[37,2],[[33,8]],null],[28,[37,2],[[33,9]],null],[28,[37,2],[[33,10,[\"isRunning\"]]],null],[50,[33,12],0,null,[[\"withinContentContainer\",\"rejectedFiles\",\"invalidFiles\"],[[33,13],[28,[37,2],[[33,7]],null],[28,[37,2],[[33,8]],null]]]],[28,[37,14],[[30,0],\"drop\"],null],[28,[37,14],[[30,0],\"resetUploader\"],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"readonly\",\"hasValidationErrors\",\"files\",\"acceptedFiles\",\"processingFiles\",\"rejectedFiles\",\"invalidFiles\",\"progress\",\"processFiles\",\"component\",\"invalidFilesComponent\",\"withinContentContainer\",\"action\"]]",
    "moduleName": "ember-smile-core/templates/components/smile-s3-uploader.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});