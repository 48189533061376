define("ember-smile-core/components/polaris-modal-target", ["exports", "@ember/component", "@ember/service", "ember-smile-core/templates/components/polaris-modal-target"], function (_exports, _component, _service, _polarisModalTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ANIMATION_DURATION = 400;

  var _default = _component.default.extend({
    tagName: '',
    layout: _polarisModalTarget.default,
    elsewhere: (0, _service.inject)('ember-elsewhere'),

    /*
     * Animations for modal
     */
    modalAnimation: modalAnimation,
    targetName: 'polaris-modal'
  });

  _exports.default = _default;

  function modalAnimation() {
    return this.lookup('explode').call(this, {
      // Uncomment below for a down effect, though seems not ideal
      // pick: '.polaris-modal-overlay',
      // use: ['fade' , {  maxOpacity: 0.8, duration: ANIMATION_DURATION / 2 } ]
      // }, {
      // pick: '.polaris-modal-dialog',
      // use: ['toDown', { duration: ANIMATION_DURATION }]
      // }, {
      pick: '.polaris-modal-container',
      use: ['fade', {
        maxOpacity: 0.8,
        duration: ANIMATION_DURATION / 2
      }]
    });
  }
});