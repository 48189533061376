define("ember-svg-jar/inlined/dispute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 10h2V6H9v4zm0 4h2v-2H9v2zm-7-4c0 4.411 3.589 8 8 8a7.939 7.939 0 004.111-1.15l2.494 1.069a1 1 0 001.314-1.313l-1.069-2.495A7.939 7.939 0 0018 10c0-4.411-3.589-8-8-8s-8 3.589-8 8z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});