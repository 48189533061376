define("ember-smile-core/components/tab-panel-card/tab-panel", ["exports", "@ember/component", "ember-smile-core/templates/components/tab-panel-card/tab-panel"], function (_exports, _component, _tabPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _tabPanel.default,

    /**
     * @public
     */
    tabs: null,

    /**
     * @public
     */
    initialTab: null,

    /**
     * When true, will show skeleton UI
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    isLoading: false
  });

  _exports.default = _default;
});