define("@smile-io/ember-smile-polaris/templates/components/polaris-layout/annotation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "N/d+gkbT",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-Layout__Annotation \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@text\"],[[30,3]]],null],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"      \"],[10,0],[14,\"data-test-annotation-description\",\"\"],[14,0,\"Polaris-Layout__AnnotationDescription\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasStringDescription\"]],[[[1,\"          \"],[10,2],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[41,[30,4],[[[1,\"          \"],[1,[28,[35,3],[[30,4]],null]],[1,\"\\n        \"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@title\",\"@description\"],false,[\"polaris-text-container\",\"polaris-heading\",\"if\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-layout/annotation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});