define("@smile-io/ember-smile-polaris/templates/components/polaris-action-list/section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OSBa723A",
    "block": "[[[44,[[50,[28,[37,2],[[52,[30,1],\"li\",\"\"]],null],0,null,[[\"tagName\"],[[52,[30,1],\"li\",\"\"]]]]],[[[1,\"  \"],[8,[30,2],[[16,0,[52,[33,4],\"Polaris-ActionList__Section\",\"\"]]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[52,[51,[30,3,[\"title\"]]],\"Polaris-ActionList__Section--withoutTitle\"]],[12],[1,\"\\n\"],[41,[30,3,[\"title\"]],[[[1,\"        \"],[10,2],[14,0,\"Polaris-ActionList__Title\"],[12],[1,\"\\n          \"],[1,[30,3,[\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"Polaris-ActionList__Actions\"],[15,\"role\",[30,0,[\"sectionRole\"]]],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,3,[\"items\"]]],null]],null],null,[[[1,\"          \"],[8,[39,8],null,[[\"@text\",\"@helpText\",\"@url\",\"@destructive\",\"@disabled\",\"@icon\",\"@image\",\"@ellipsis\",\"@active\",\"@role\",\"@badge\",\"@onAction\"],[[30,4,[\"text\"]],[30,4,[\"helpText\"]],[30,4,[\"url\"]],[30,4,[\"destructive\"]],[30,4,[\"disabled\"]],[30,4,[\"icon\"]],[30,4,[\"image\"]],[30,4,[\"ellipsis\"]],[30,4,[\"active\"]],[30,5],[30,4,[\"badge\"]],[28,[37,9],[[30,0,[\"handleAction\"]],[30,4]],null]]],null],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@hasMultipleSections\",\"ActionListSection\",\"@section\",\"item\",\"@actionRole\"],false,[\"let\",\"component\",\"-element\",\"if\",\"hasMultipleSections\",\"unless\",\"each\",\"-track-array\",\"polaris-action-list/item\",\"fn\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-action-list/section.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});