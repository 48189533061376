define("ember-smile-core/templates/components/task-loader", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1+1ymuCq",
    "block": "[[[18,1,[[28,[37,1],null,[[\"results\",\"isLoading\"],[[33,2],[33,3]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"results\",\"isLoading\"]]",
    "moduleName": "ember-smile-core/templates/components/task-loader.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});