define("@smile-io/ember-smile-polaris/templates/components/polaris-empty-state/details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HhcPh6Vs",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-EmptyState__Details \",[30,1]]]],[24,\"data-test-empty-state-details\",\"\"],[17,2],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-empty-state-heading\",\"\"]],[[\"@size\",\"@text\"],[\"medium\",[30,3]]],null],[1,\"\\n    \"],[10,0],[14,\"data-test-empty-state-content\",\"\"],[14,0,\"Polaris-EmptyState__Content\"],[12],[1,\"\\n\"],[41,[48,[30,8]],[[[1,\"        \"],[18,8,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"    \"],[10,0],[14,0,\"Polaris-EmptyState__Actions\"],[12],[1,\"\\n      \"],[8,[39,5],[[16,\"data-test-empty-state-actions\",true]],[[\"@alignment\"],[\"center\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@primary\",\"@size\",\"@text\",\"@disabled\",\"@loading\",\"@onClick\"],[true,\"large\",[30,5,[\"text\"]],[30,5,[\"disabled\"]],[30,5,[\"loading\"]],[30,5,[\"onAction\"]]]],null],[1,\"\\n\\n\"],[41,[30,7],[[[1,\"          \"],[8,[30,6,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@plain\",\"@text\",\"@onClick\"],[true,[30,7,[\"text\"]],[30,7,[\"onAction\"]]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"]],[6]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@heading\",\"@text\",\"@primaryAction\",\"stack\",\"@secondaryAction\",\"&default\"],false,[\"polaris-text-container\",\"polaris-display-text\",\"if\",\"has-block\",\"yield\",\"polaris-stack\",\"polaris-button\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-empty-state/details.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});