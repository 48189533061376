define("ember-svg-jar/inlined/avatar-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-2{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:5px}</style></defs><path d=\"M0 0h100v100H0z\" fill=\"#ffedb9\"/><path class=\"cls-2\" d=\"M64.63 17.33a17 17 0 015 29.72 16.75 16.75 0 01-5 2.62\"/><path d=\"M0 0h69.02v100H0z\" fill=\"#ffc04d\"/><circle class=\"cls-2\" cx=\"45.11\" cy=\"33.49\" r=\"16.98\" transform=\"rotate(-37.02 45.124 33.493)\"/><path d=\"M69.02 34.48l19.47 38.74-19.47 1.53V34.48z\" fill=\"#5d6cc1\"/><path class=\"cls-2\" d=\"M61.6 33.67a10.17 10.17 0 0115.4.08\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});