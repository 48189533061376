define("ember-smile-core/templates/components/read-only-text-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "for/OoYD",
    "block": "[[[44,[[50,\"read-only-text-field-actions\",0,null,[[\"copyable\",\"hideable\",\"value\",\"onDelete\",\"isMasked\",\"onCopySuccess\",\"onCopyError\"],[[33,2],[33,3],[33,4],[33,5],[28,[37,6],[[33,7]],null],[28,[37,8],[[30,0],[33,9]],null],[28,[37,8],[[30,0],[33,10]],null]]]]],[[[44,[[50,\"polaris-text-field\",0,null,[[\"readOnly\",\"label\",\"value\",\"type\",\"helpText\",\"connectedRight\"],[true,[33,11],[33,4],[52,[33,7],\"password\"],[28,[37,13],[[33,14],[33,15]],null],[50,[30,1],0,null,null]]]]],[[[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[30,2],[30,1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[46,[30,2],null,null,null],[1,\"\\n\"]],[]]]],[2]]]],[1]]]],[\"fieldActions\",\"textField\",\"&default\"],false,[\"let\",\"component\",\"copyable\",\"hideable\",\"value\",\"onDelete\",\"mut\",\"isMasked\",\"action\",\"onCopySuccess\",\"onCopyError\",\"label\",\"if\",\"or\",\"helpText\",\"hint\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/read-only-text-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});