define("ember-smile-core/components/smile-s3-uploader/invalid-files-banner", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-smile-core/templates/components/smile-s3-uploader/invalid-files-banner"], function (_exports, _component, _object, _computed, _invalidFilesBanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _invalidFilesBanner.default,

    /**
     * List of rejected files (by the dropzone component, only reason right now
     * is invalid file type)
     */
    rejectedFiles: null,

    /**
     * List of invalid files (ex: wrong image size)
     */
    invalidFiles: null,

    /**
     * Passes this to the banner. Set to true if used inside a modal/card component
     * TODO drop once appProvider is implemented in `ember-polaris`
     */
    withinContentContainer: false,

    /**
     * Collection of all non-uploadable files
     */
    nonUploadableFiles: (0, _computed.union)('rejectedFiles', 'invalidFiles'),

    /**
     * Formats accepted uploaded file types in a friendly manner for error messages.
     * e.g Input: 'text/csv,application/vnd.ms-excel,application/csv,application/zip,.csv,.zip'
     * Output: '.csv or .zip'
     * Note:  we only display types that begin with a period.
     * For proper Polaris DropZone function, `acceptedFiles` must keep the remaining file
     * types and avoid using only file extensions. This is because of a bug in the Polaris drop-zone
     * component, where, while dragging the file over the dropzone, it will wrongly show an Invalid
     * file type error message but still allow uploading. Issue is that fileName is undefined
     * while dragging the file here
     * https://github.com/smile-io/ember-smile-polaris/blob/f9c52677f1fb1b4d9778394af929603629ba7557/addon/utils/drop-zone.js#L33
     */
    readableAcceptedFileTypes: (0, _object.computed)('acceptedFiles', function () {
      var _this$acceptedFiles = this.acceptedFiles,
          acceptedFiles = _this$acceptedFiles === void 0 ? [] : _this$acceptedFiles;
      var displayedFileTypes = acceptedFiles.split(',').filter(function (type) {
        return type.charAt(0) === '.';
      });

      if (displayedFileTypes.length <= 1) {
        return displayedFileTypes[0];
      }

      var lastValue = displayedFileTypes.pop();
      var readableTypes = displayedFileTypes.join(', ');
      return "".concat(readableTypes, " or ").concat(lastValue);
    })
  });

  _exports.default = _default;
});