define("@smile-io/ember-smile-polaris/templates/components/polaris-card/section-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CxiNke57",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-Card__SectionHeader \",[30,1]]]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[41,[28,[37,1],[[30,3]],null],[[[1,\"      \"],[1,[28,[35,2],[[30,3]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],null,[[\"@text\"],[[30,3]]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"    \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,4]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@title\",\"@text\",\"&default\"],false,[\"if\",\"is-component-definition\",\"render-content\",\"polaris-subheading\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-card/section-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});