define("@smile-io/ember-smile-polaris/templates/components/polaris-text-style", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EnDDvCvL",
    "block": "[[[44,[[50,[28,[37,2],[[52,[28,[37,4],[[30,0,[\"elementTagName\"]],\"code\"],null],\"code\",\"span\"]],null],0,null,[[\"tagName\"],[[52,[28,[37,4],[[30,0,[\"elementTagName\"]],\"code\"],null],\"code\",\"span\"]]]]],[[[1,\"  \"],[8,[30,1],[[16,0,[30,0,[\"textStyleClasses\"]]],[16,\"data-test-text-style\",[28,[37,5],[[30,2],\"\"],null]],[17,3]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"      \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"TextStyle\",\"@dataTestTextStyle\",\"&attrs\",\"@text\",\"&default\"],false,[\"let\",\"component\",\"-element\",\"if\",\"eq\",\"or\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-text-style.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});