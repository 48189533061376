define("ember-svg-jar/inlined/avatar-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-5{fill:#fff}</style></defs><path d=\"M-.02 0h100v100h-100z\" fill=\"#5d6cc1\"/><path d=\"M.39 0h69.02v100H.39z\" fill=\"#6dcace\"/><path d=\"M24.18 31.21v3.47A10.43 10.43 0 0034.4 45.21a10.43 10.43 0 0010.22-10.53v-3.47\" fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"5\"/><path d=\"M20.11 49.07a16.22 16.22 0 110 32.44\" fill=\"#ebedf1\" opacity=\".2\"/><path class=\"cls-5\" d=\"M69.44 18.83L90 71H69.44V18.83z\"/><path class=\"cls-5\" d=\"M57.59 71a6 6 0 0112 0z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});