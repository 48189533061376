define("ember-svg-jar/inlined/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.707 6.707a.997.997 0 01-1.414 0L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a.999.999 0 11-1.414-1.414l3-3a.999.999 0 011.414 0l3 3a.999.999 0 010 1.414zM17 18H3a1 1 0 110-2h14a1 1 0 110 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});