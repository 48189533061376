define("ember-svg-jar/inlined/polaris/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 11h2V9h-2v2zM7 7h6V4H7v3zm0 9h6v-2H7v2zm10-9h-2V3a1 1 0 00-1-1H6a1 1 0 00-1 1v4H3a1 1 0 00-1 1v7a1 1 0 001 1h2v1a1 1 0 001 1h8a1 1 0 001-1v-1h2a1 1 0 001-1V8a1 1 0 00-1-1z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});