define("@smile-io/ember-smile-polaris/templates/components/polaris-date-picker/weekday", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PeEKvg0K",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-DatePicker__Weekday \",[30,1],\" \",[52,[30,2],\"Polaris-DatePicker__Weekday--current\"]]]],[24,\"data-test-date-picker-weekday\",\"\"],[17,3],[16,\"aria-label\",[30,4]],[12],[1,\"\\n  \"],[1,[30,5]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@current\",\"&attrs\",\"@label\",\"@title\"],false,[\"if\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-date-picker/weekday.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});