define("@smile-io/ember-smile-polaris/templates/components/polaris-popover", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tqRSdDRE",
    "block": "[[[11,1],[4,[38,0],[[30,0,[\"setRef\"]]],null],[12],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@verticalPosition\",\"@renderInPlace\",\"@onOpen\",\"@onClose\"],[[30,0,[\"verticalPosition\"]],[30,1],[30,0,[\"open\"]],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,7,[[28,[37,3],null,[[\"activator\",\"content\",\"open\",\"close\",\"toggle\"],[[50,[30,2,[\"Trigger\"]],0,null,[[\"style\"],[[30,0,[\"triggerStyle\"]]]]],[50,\"polaris-popover/content\",0,null,[[\"contentComponent\",\"contentClass\",\"uniqueId\",\"sectioned\",\"fullWidth\",\"fullHeight\"],[[30,2,[\"Content\"]],[30,3],[30,2,[\"uniqueId\"]],[30,4],[30,5],[30,6]]]],[28,[37,5],[[30,2,[\"actions\",\"open\"]]],null],[28,[37,5],[[30,2,[\"actions\",\"close\"]]],null],[28,[37,5],[[30,2,[\"actions\",\"toggle\"]]],null]]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@renderInPlace\",\"dd\",\"@contentClass\",\"@sectioned\",\"@fullWidth\",\"@fullHeight\",\"&default\"],false,[\"did-insert\",\"basic-dropdown\",\"yield\",\"hash\",\"component\",\"fn\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-popover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});