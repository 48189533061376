define("ember-smile-core/templates/components/constrained-image", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2bpsnwVs",
    "block": "[[[10,0],[14,0,\"constrained-image\"],[15,5,[36,0]],[12],[1,\"\\n  \"],[10,\"img\"],[14,0,\"image\"],[15,\"src\",[36,1]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"imageContainerStyle\",\"source\"]]",
    "moduleName": "ember-smile-core/templates/components/constrained-image.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});