define("ember-smile-core/components/polaris-color-preview", ["exports", "@ember/component", "@ember/object", "@ember/string", "@ember/utils", "ember-smile-core/templates/components/polaris-color-preview"], function (_exports, _component, _object, _string, _utils, _polarisColorPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['polaris-color-preview'],
    layout: _polarisColorPreview.default,

    /**
     * Input color to display preview for.
     *
     * @type {String}
     * @default '#000000'
     * @public
     */
    color: '#000000',

    /**
     * Color to display in preview. This will be the
     * most recent valid value of the `color` property.
     *
     * @type {String}
     * @default '#000000'
     * @private
     */
    previewColor: '#000000',
    previewStyle: (0, _object.computed)('previewColor', function () {
      return (0, _string.htmlSafe)("background-color: ".concat(this.get('previewColor')));
    }).readOnly(),
    isValidColor: function isValidColor(color) {
      return (0, _utils.isPresent)(color) && (0, _utils.typeOf)(color) === 'string' && // Taken from https://gist.github.com/polotek/1584364 and modified slightly to:
      //  - only accept letters a-f (original accepted all letters),
      //  - require the pound sign at the start.
      color.match(/^#(([0-9a-fA-F]{3}){1,3})$/);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var color = this.get('color');

      if (this.isValidColor(color)) {
        this.set('previewColor', color);
      }
    }
  });

  _exports.default = _default;
});