define("ember-smile-core/helpers/hex-to-hsb", ["exports", "@ember/component/helper", "@smile-io/ember-smile-polaris/utils/color"], function (_exports, _helper, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hexToHsbHelper = hexToHsbHelper;
  _exports.default = void 0;

  function hexToHsbHelper(params
  /*, hash*/
  ) {
    return (0, _color.hexToHsb)(params[0]);
  }

  var _default = (0, _helper.helper)(hexToHsbHelper);

  _exports.default = _default;
});