define("@smile-io/ember-smile-polaris/templates/components/polaris-color-picker/slidable", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yT5W1fsB",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-ColorPicker__Slidable \",[30,1]]]],[4,[38,0],[\"mousedown\",[30,0,[\"startDrag\"]]],null],[4,[38,0],[\"touchstart\",[30,0,[\"startDrag\"]]],null],[4,[38,1],[[30,0,[\"setElementAndTriggerHeightChanged\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-ColorPicker__Dragger\"],[15,5,[30,0,[\"draggerStyle\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\"],false,[\"on\",\"did-insert\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-color-picker/slidable.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});