define("ember-smile-core/components/polaris-power-select-multiple-with-create", ["exports", "ember-power-select-with-create/components/power-select-multiple-with-create", "ember-smile-core/templates/components/polaris-power-select-with-create"], function (_exports, _powerSelectMultipleWithCreate, _polarisPowerSelectWithCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerSelectMultipleWithCreate.default.extend({
    layout: _polarisPowerSelectWithCreate.default,
    triggerClass: 'Polaris-Select',
    triggerComponent: 'polaris-power-select-multiple/trigger'
  });

  _exports.default = _default;
});