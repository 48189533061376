define("ember-smile-core/templates/components/polaris-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3hrvbpRO",
    "block": "[[[1,[28,[35,0],null,[[\"named\",\"send\"],[\"polaris-modal\",[28,[37,1],null,[[\"body\",\"onOutsideClick\"],[[50,[33,3],0,null,[[\"model\",\"modalParams\",\"onConfirm\",\"onCloseModal\"],[[33,4],[33,5],[28,[37,6],[[30,0],\"confirm\"],null],[28,[37,6],[[30,0],[33,7]],null]]]],[28,[37,6],[[30,0],[33,7]],null]]]]]]]],[1,\"\\n\"]],[],false,[\"to-elsewhere\",\"hash\",\"component\",\"modalPath\",\"model\",\"modalParams\",\"action\",\"onClose\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});