define("ember-smile-core/components/polaris-app-top-bar-profile", ["exports", "@ember/component", "ember-smile-core/templates/components/polaris-app-top-bar-profile"], function (_exports, _component, _polarisAppTopBarProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['top-bar-item', 'top-bar-profile', 'top-bar-button'],
    layout: _polarisAppTopBarProfile.default,

    /**
     * Component with user profile content. This triggers the menu dropdown.
     *
     * @type {String}
     * @public
     * @default null
     */
    profileTriggerComponent: null,

    /**
     * Component with menu contents. This will be rendered in a `{{polaris-popover}}` component.
     *
     * @type {String}
     * @public
     * @default null
     */
    profileMenuComponent: null
  });

  _exports.default = _default;
});