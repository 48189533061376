define("ember-smile-core/templates/components/polaris-app-top-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5Mk2/68+",
    "block": "[[[10,0],[14,0,\"top-bar-container\"],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[28,[37,3],null,[[\"branding\",\"profile\"],[[50,[33,5],0,null,[[\"homeRoute\",\"logoIcon\"],[[33,6],[33,7]]]],[50,[33,8],0,null,[[\"profileTriggerComponent\",\"profileMenuComponent\"],[[33,9],[33,10]]]]]]]]],[1,\"\\n\"]],[]],[[[41,[33,7],[[[1,\"      \"],[46,[33,5],null,[[\"homeRoute\",\"logoIcon\"],[[33,6],[33,7]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"top-bar-item top-bar-item-fill\"],[12],[1,\"\\n      \"],[8,[39,11],[[24,0,\"top-bar-item top-bar-button top-bar-nav-toggle\"]],[[\"@plain\",\"@onClick\"],[true,[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,12],null,[[\"color\",\"source\"],[[28,[37,13],[[33,14],\"white\"],null],[33,15]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n\"],[41,[33,16],[[[1,\"        \"],[46,[33,16],null,null,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[33,17],[[[1,\"      \"],[46,[33,8],null,[[\"profileTriggerComponent\",\"profileMenuComponent\"],[[33,9],[33,10]]],null],[1,\"\\n\"]],[]],null]],[]]],[13],[1,\"\\n\"]],[\"@onToggleNav\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"brandingComponent\",\"homeRoute\",\"logoIcon\",\"profileComponent\",\"profileTriggerComponent\",\"profileMenuComponent\",\"polaris-button\",\"polaris-icon\",\"or\",\"navToggleIconColor\",\"navToggleIcon\",\"topBarContentComponent\",\"hasProfileComponents\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-app-top-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});