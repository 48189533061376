define("@smile-io/ember-smile-polaris/templates/components/polaris-skeleton-display-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aRxpDjmx",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-SkeletonDisplayText__DisplayText \",[30,1],\" \",[30,0,[\"sizeClass\"]]]]],[17,2],[12],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\"],false,[]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-skeleton-display-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});