define("@smile-io/ember-smile-polaris/templates/components/polaris-skeleton-page/action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DvIUWbbx",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-SkeletonPage__Action \",[30,1]]]],[16,5,[30,0,[\"style\"]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],[[16,\"data-test-skeleton-page-action-text\",true]],[[\"@lines\"],[1]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\"],false,[\"polaris-skeleton-body-text\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-skeleton-page/action.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});