define("@smile-io/ember-smile-polaris/templates/components/polaris-button-group/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vuYw90B8",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[24,\"data-test-button-group-item\",\"\"],[24,\"role\",\"button\"],[17,1],[4,[38,0],[\"focus\",[30,0,[\"handleFocus\"]]],null],[4,[38,0],[\"blur\",[30,0,[\"handleBlur\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,2]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@text\",\"&default\"],false,[\"on\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-button-group/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});