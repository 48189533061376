define("@smile-io/ember-smile-polaris/templates/components/polaris-setting-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "V3+g2u9/",
    "block": "[[[8,[39,0],[[17,1]],[[\"@sectioned\"],[true]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"Polaris-SettingAction\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-SettingAction__Setting\"],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,2]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"Polaris-SettingAction__Action\"],[12],[1,\"\\n\"],[41,[30,0,[\"mainAction\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@text\",\"@disabled\",\"@loading\",\"@primary\",\"@onClick\"],[[30,0,[\"mainAction\",\"text\"]],[30,0,[\"mainAction\",\"disabled\"]],[30,0,[\"mainAction\",\"loading\"]],[30,3],[30,0,[\"mainAction\",\"onAction\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@text\",\"@enabled\",\"&default\"],false,[\"polaris-card\",\"if\",\"has-block\",\"yield\",\"polaris-button\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-setting-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});