define("ember-smile-core/components/polaris-modal", ["exports", "@ember/component", "@ember/object", "rsvp", "ember-smile-core/templates/components/polaris-modal", "ember-smile-core/helpers/component-exists"], function (_exports, _component, _object, _rsvp, _polarisModal, _componentExists) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to render Polaris-style modals.
   * Modals are just simple Ember components that live in `polaris-modals/` folder.
   *
   * Basic usage:
   *    If you just need a one-time usage modal
   *      {{polaris-modal
   *        model=model
   *        modalParams=(hash
   *          See `polaris-modals/default` for all the options that can be used.
   *        )
   *        onConfirm=(action ...)
   *        onClose=(action ...)
   *      }}
   *
   *
   *    If you have a modal component, here's how you render it:
   *
   *      {{polaris-modal "delete-activity-rule"
   *        model=activityRule
   *        onConfirm=(action "handleConfirmaction")
   *        onClose=(action "handleModalCloseAction")
   *      }}
   *
   * Add a new modal:
   *      `ember g polaris-modal <modal-name>`
   *
   *  and render it
   *      {{polaris-modal <modal-name>
   *        onConfirm=(action ...)
   *        onClose=(action ...)
   *      }}
   *
   *
   * Advanced usage:
   *
   *    When your modal is more complex, you can build it upfront, so you don't have to
   *    pass things through the `modalParams` object.
   *
   *    {{polaris-modal (component "polaris-modals/my-component" ALL_THE_PARAMS_AND_ACTIONS)
   *        onConfirm=(action ...)
   *        onClose=(action ...)
   *    }}
   */
  var PolarisModal = _component.default.extend({
    tagName: '',
    layout: _polarisModal.default,

    /**
     * The name of the modal (except the prepending `polaris-modals/`)
     */
    modal: null,

    /*
     * Pass the main object to your modal as `model`.
     * If you need to pass more data, use `modalParams` with a hash helper instead.
     */
    model: null,

    /*
     * Pass any properties required to the modal through this object. Use it with a `hash`
     * helper.
     */
    modalParams: null,
    modalPath: (0, _object.computed)('modal', function () {
      var componentName = "polaris-modals/".concat(this.get('modal') || 'default'); // If a component exists with this name, good

      if ((0, _componentExists.componentExists)(componentName, this)) {
        return componentName;
      } // If we get here, instead of a modal we have an actual component, so just return that


      return this.get('modal');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Hide body overflow to prevent scrolling behind the modal.


      document.querySelector('body').classList.add('polaris-modal-open');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments); // Reset the body overflow to how it was before we opened.


      document.querySelector('body').classList.remove('polaris-modal-open');
    },
    actions: {
      confirm: function confirm() {
        if (this.get('onConfirm')) {
          return this.get('onConfirm').apply(void 0, arguments);
        }

        return _rsvp.resolve.apply(void 0, arguments);
      }
    }
  });

  PolarisModal.reopenClass({
    positionalParams: ['modal']
  });
  var _default = PolarisModal;
  _exports.default = _default;
});