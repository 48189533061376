define("@smile-io/ember-smile-polaris/components/key-event-listener", ["exports", "@ember/component", "@ember/debug", "@ember/utils", "ember-keyboard", "@smile-io/ember-smile-polaris/templates/components/key-event-listener"], function (_exports, _component, _debug, _utils, _emberKeyboard, _keyEventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A container component that helps in handling keyboard events
   *
   * @component key-event-listener
   */
  var _default = _component.default.extend(_emberKeyboard.EKMixin, _emberKeyboard.EKOnInsertMixin, {
    tagName: '',
    layout: _keyEventListener.default,

    /**
     * The key (or key combination) that triggers the keyDown/keyPress/keyUp event
     * @type {KeyboardEvent.code} https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
     * @default null
     * @public
     * @required
     */
    key: null,

    /**
     * Corresponds to the native `onKeyPress` event that gets
     * triggered when `key` is pressed
     *
     * @type {Function}
     * @default null
     * @public
     */
    onKeyPress: null,

    /**
     * Corresponds to the native `onKeyDown` event that gets
     * triggered when `key` is pressed
     *
     * @type {Function}
     * @default null
     * @public
     */
    onKeyDown: null,

    /**
     * Corresponds to the native `onKeyUp` event that gets
     * triggered when `key` is pressed
     *
     * @type {Function}
     * @default null
     * @public
     */
    onKeyUp: null,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(false) && (0, _debug.deprecate)("[KeyEventListener] This component is deprecated! Please use ember-keyboard modifiers instead!", false, {
        id: 'ember-polaris.key-event-listener',
        until: '7.0.0'
      }));
      (false && !((0, _utils.isPresent)(this.key)) && (0, _debug.assert)('ember-polaris::key-event-listener `key` should be passed', (0, _utils.isPresent)(this.key)));
      (false && !(this.onKeyUp || this.onKeyDown || this.onKeyPress) && (0, _debug.assert)('ember-polaris::key-event-listener One of `onKeyPress`, `onKeyDown` or `onKeyUp` should be passed', this.onKeyUp || this.onKeyDown || this.onKeyPress));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.onKeyUp) {
        this.on((0, _emberKeyboard.keyUp)(this.key), this.onKeyUp);
      }

      if (this.onKeyDown) {
        this.on((0, _emberKeyboard.keyDown)(this.key), this.onKeyDown);
      }

      if (this.onKeyPress) {
        this.on((0, _emberKeyboard.keyPress)(this.key), this.onKeyPress);
      }
    }
  });

  _exports.default = _default;
});