define("ember-smile-core/components/centered-callout-card", ["exports", "@smile-io/ember-smile-polaris/components/polaris-callout-card", "ember-smile-core/templates/components/centered-callout-card"], function (_exports, _polarisCalloutCard, _centeredCalloutCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Contains the same items as `polaris-callout-card` but in a vertical, centered layout.
   */
  var _default = _polarisCalloutCard.default.extend({
    classNames: ['centered-callout-card'],
    layout: _centeredCalloutCard.default
  });

  _exports.default = _default;
});