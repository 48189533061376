define("ember-smile-core/templates/components/polaris-app-nav-heading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2SWArZEY",
    "block": "[[[41,[33,1],[[[1,\"  \"],[11,0],[24,0,\"polaris-app-nav-heading-label\"],[24,\"role\",\"button\"],[4,[38,2],[[30,0],[28,[37,2],[[30,0],\"clickLabel\"],null]],null],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"text\"],[[33,4]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"polaris-app-nav-heading-label\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"text\"],[[33,4]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,1],[[[1,\"  \"],[8,[39,5],[[24,0,\"polaris-app-nav-heading-icon\"]],[[\"@plain\",\"@icon\",\"@onClick\"],[true,[30,2],[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,3,null],[1,\"\\n\"]],[\"@onClickIcon\",\"@icon\",\"&default\"],false,[\"if\",\"hasLabelAction\",\"action\",\"polaris-subheading\",\"label\",\"polaris-button\",\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-app-nav-heading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});