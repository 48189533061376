define("ember-svg-jar/inlined/avatar-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#8dc958}.cls-3{fill:#fff}</style></defs><path class=\"cls-1\" d=\"M0 0h100v100.48H0z\"/><path d=\"M83 68v32l-14-.16v.16H0V0h69v68h14z\" fill=\"#2db167\"/><path class=\"cls-1\" d=\"M69.29 41.69H20.66s-.09-3-.17 7.15 7 18.51 17.35 18.59l31.45.57z\"/><path class=\"cls-3\" d=\"M68.7 12.4l11.54 29.29H68.7V12.4z\"/><path class=\"cls-3\" d=\"M62.22 41.69a3.34 3.34 0 116.69 0z\"/><path d=\"M41.45 18.06a2.57 2.57 0 00-5.14 0M33.33 24a2.57 2.57 0 10-5.14 0m20.36 2.58a2.57 2.57 0 10-5.14 0\" fill=\"#245b48\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});