define("@smile-io/ember-smile-polaris/utils/drop-zone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fileAccepted = fileAccepted;
  _exports.getDataTransferFiles = getDataTransferFiles;
  _exports.largeSizeWidthLimit = _exports.mediumSizeWidthLimit = _exports.smallSizeWidthLimit = void 0;
  var dragEvents = ['dragover', 'dragenter', 'drop']; // Width limits based on which dropzone size is computed

  var smallSizeWidthLimit = 100;
  _exports.smallSizeWidthLimit = smallSizeWidthLimit;
  var mediumSizeWidthLimit = 160;
  _exports.mediumSizeWidthLimit = mediumSizeWidthLimit;
  var largeSizeWidthLimit = 300;
  _exports.largeSizeWidthLimit = largeSizeWidthLimit;

  function fileAccepted(file, accept) {
    return file.type === 'application/x-moz-file' || accepts(file, accept);
  }

  function getDataTransferFiles(event) {
    if (isDragEvent(event) && event.dataTransfer) {
      var dt = event.dataTransfer;

      if (dt.files && dt.files.length) {
        return Array.from(dt.files);
      } else if (dt.items && dt.items.length) {
        // Chrome is the only browser that allows to read the file list on drag
        // events and uses `items` instead of `files` in this case.
        return Array.from(dt.items);
      }
    } else if (isChangeEvent(event) && event.target.files) {
      // Return files from event when a file was selected from an upload dialog
      return Array.from(event.target.files);
    }

    return [];
  }

  function accepts(file, acceptedFiles) {
    if (file && acceptedFiles) {
      var fileName = file.name || '';
      var mimeType = file.type || '';
      var baseMimeType = mimeType.replace(/\/.*$/, '');
      var acceptedFilesArray = Array.isArray(acceptedFiles) ? acceptedFiles : acceptedFiles.split(',');
      return acceptedFilesArray.some(function (type) {
        var validType = type.trim();

        if (validType.charAt(0) === '.') {
          return fileName.toLowerCase().endsWith(validType.toLowerCase());
        } else if (/\/\*$/.test(validType)) {
          // This is something like a image/* mime type
          return baseMimeType === validType.replace(/\/.*$/, '');
        }

        return mimeType === validType;
      });
    }

    return true;
  }

  function isDragEvent(event) {
    return dragEvents.indexOf(event.type) > 0;
  }

  function isChangeEvent(event) {
    // NOTE: Polaris only checks `event.hasOwnProperty('target')` but that fails
    // for some strange reason...
    return Object.prototype.hasOwnProperty.call(event, 'target') || Boolean(event.target);
  }
});