define("@smile-io/ember-smile-polaris/templates/components/polaris-page-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jEdQVCur",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-PageActions \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@spacing\",\"@distribution\"],[\"tight\",[52,[30,0,[\"showSecondaryActions\"]],\"equalSpacing\",\"trailing\"]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"showSecondaryActions\"]],[[[1,\"      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[41,[28,[37,5],[[30,4]],null],[[[1,\"            \"],[46,[30,4],null,null,null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,7],null,[[\"@text\",\"@disabled\",\"@loading\",\"@destructive\",\"@icon\",\"@onClick\"],[[30,4,[\"text\"]],[30,4,[\"disabled\"]],[30,4,[\"loading\"]],[30,4,[\"destructive\"]],[30,4,[\"icon\"]],[30,4,[\"onAction\"]]]],null],[1,\"\\n\"]],[]]]],[4]],null],[1,\"      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5],[[[1,\"      \"],[8,[39,7],null,[[\"@primary\",\"@disabled\",\"@loading\",\"@text\",\"@onClick\"],[true,[30,5,[\"disabled\"]],[30,5,[\"loading\"]],[30,5,[\"text\"]],[30,5,[\"onAction\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@secondaryActions\",\"secondaryAction\",\"@primaryAction\"],false,[\"polaris-stack\",\"if\",\"polaris-button-group\",\"each\",\"-track-array\",\"is-component-definition\",\"component\",\"polaris-button\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-page-actions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});