define("@smile-io/ember-smile-polaris/templates/components/polaris-color-picker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vIlmsghP",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-ColorPicker \",[30,1]]]],[17,2],[4,[38,0],[[30,0,[\"setPickerSize\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-ColorPicker__MainColor\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-ColorPicker__ColorLayer\"],[15,5,[30,0,[\"colorLayerStyle\"]]],[12],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@draggerX\",\"@draggerY\",\"@onChange\"],[[30,0,[\"draggerX\"]],[30,0,[\"draggerY\"]],[28,[37,2],[[30,0,[\"draggerMoved\"]]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@hue\",\"@onChange\"],[[30,3,[\"hue\"]],[28,[37,2],[[30,0,[\"handleHueChange\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"    \"],[8,[39,5],null,[[\"@color\",\"@alpha\",\"@onChange\"],[[30,3],[30,3,[\"alpha\"]],[28,[37,2],[[30,0,[\"handleAlphaChange\"]]],null]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@color\",\"@allowAlpha\"],false,[\"did-insert\",\"polaris-color-picker/slidable\",\"fn\",\"polaris-color-picker/hue-picker\",\"if\",\"polaris-color-picker/alpha-picker\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-color-picker.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});