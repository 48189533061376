define("ember-svg-jar/inlined/smile/test-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.942 11.97L9.335 7.648V3.285H10a.662.662 0 00.667-.657V.657A.662.662 0 0010.001 0H3.335a.662.662 0 00-.667.657v1.971c0 .363.298.657.667.657H4v4.364L.362 12.01a1.94 1.94 0 00-.135 2.04 1.997 1.997 0 001.774 1.06h9.334c.749 0 1.429-.406 1.774-1.06.345-.655.294-1.437-.167-2.082zM4.002 1.313h5.333v.657H4v-.657zm1.18 6.988a.653.653 0 00.153-.418V3.285H8v.657H6.668v1.314h1.333v.657h-2v1.314h2v.657c0 .152.054.3.152.418l1.298 1.553H3.885l1.298-1.553zm6.744 5.142a.665.665 0 01-.591.353H2a.666.666 0 01-.591-.353c-.116-.218-.098-.479.013-.64l1.365-1.635h7.761l1.332 1.595c.143.201.16.462.045.68z\" fill=\"#919EAB\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});