define("@smile-io/ember-smile-polaris/templates/components/polaris-resource-list/loading-overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5Ip2QDT+",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"Polaris-ResourceList__SpinnerContainer\"],[15,5,[30,2]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@accessibilityLabel\",\"@size\"],[\"Items are loading\",[30,3]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-ResourceList__LoadingOverlay\"],[12],[13],[1,\"\\n\"]],[]],null]],[\"@loading\",\"@spinnerStyle\",\"@spinnerSize\"],false,[\"if\",\"polaris-spinner\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-resource-list/loading-overlay.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});