define("@smile-io/ember-smile-polaris/templates/components/polaris-pagination", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3orK8Mbk",
    "block": "[[[11,\"nav\"],[16,\"aria-label\",[30,0,[\"accessibilityLabel\"]]],[16,0,[29,[\"Polaris-Pagination \",[30,1],\" \",[52,[30,2],\"Polaris-Pagination--plain\"]]]],[17,3],[12],[1,\"\\n  \"],[11,\"button\"],[24,\"data-test-prev-btn\",\"true\"],[24,0,\"Polaris-Pagination__Button\"],[24,\"aria-label\",\"Previous\"],[16,\"disabled\",[30,0,[\"isPreviousDisabled\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,4],[30,0,[\"onPrevious\"]]],null]],null],[4,[38,1],[\"mouseup\",[30,0,[\"handleMouseUpByBlurring\"]]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@source\"],[\"arrow-left\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"button\"],[24,\"data-test-next-btn\",\"true\"],[24,0,\"Polaris-Pagination__Button\"],[24,\"aria-label\",\"Next\"],[16,\"disabled\",[30,0,[\"isNextDisabled\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,5],[30,0,[\"onNext\"]]],null]],null],[4,[38,1],[\"mouseup\",[30,0,[\"handleMouseUpByBlurring\"]]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@source\"],[\"arrow-right\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isPreviousKeyListenerEnabled\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,6]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],[[30,7],[30,4]],[[\"event\"],[\"keyup\"]]]],[1,\"\\n\"]],[7]],null]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isNextKeyListenerEnabled\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,8]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],[[30,9],[30,5]],[[\"event\"],[\"keyup\"]]]],[1,\"\\n\"]],[9]],null]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"@plain\",\"&attrs\",\"@onPrevious\",\"@onNext\",\"@previousKeys\",\"key\",\"@nextKeys\",\"key\"],false,[\"if\",\"on\",\"or\",\"polaris-icon\",\"each\",\"-track-array\",\"on-key\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-pagination.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});