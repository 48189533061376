define("ember-smile-core/templates/components/flash-messages-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Dakghtb1",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"flashMessages\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@flash\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null]],[\"flash\"],false,[\"each\",\"-track-array\",\"flash-message\"]]",
    "moduleName": "ember-smile-core/templates/components/flash-messages-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});