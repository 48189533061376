define("@smile-io/ember-smile-polaris/templates/components/polaris-action-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qFTXXmpN",
    "block": "[[[44,[[50,[28,[37,2],[[52,[30,0,[\"hasMultipleSections\"]],\"ul\",\"div\"]],null],0,null,[[\"tagName\"],[[52,[30,0,[\"hasMultipleSections\"]],\"ul\",\"div\"]]]]],[[[1,\"  \"],[8,[30,1],[[24,0,\"Polaris-ActionList\"],[17,2]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"finalSections\"]]],null]],null],null,[[[1,\"      \"],[8,[39,6],null,[[\"@section\",\"@hasMultipleSections\",\"@actionRole\",\"@onActionAnyItem\"],[[30,3],[30,0,[\"hasMultipleSections\"]],[30,4],[30,0,[\"onActionAnyItem\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"ActionList\",\"&attrs\",\"section\",\"@actionRole\"],false,[\"let\",\"component\",\"-element\",\"if\",\"each\",\"-track-array\",\"polaris-action-list/section\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-action-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});