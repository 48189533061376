define("@smile-io/ember-smile-polaris/templates/components/polaris-description-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2E3Zv/zu",
    "block": "[[[11,\"dl\"],[16,0,[29,[\"Polaris-DescriptionList \",[30,1]]]],[17,2],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[10,\"dt\"],[14,0,\"Polaris-DescriptionList__Term\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,4,[\"term\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"dd\"],[14,0,\"Polaris-DescriptionList__Description\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,4,[\"description\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[4]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@items\",\"item\"],false,[\"each\",\"-track-array\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-description-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});