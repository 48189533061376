define("ember-template-compiler/lib/plugins/transform-in-element", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformInElement;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that handles the public `{{in-element}}` as per RFC287, and deprecates but still
    continues support for the private `{{-in-element}}`.
  
    Transforms:
  
    ```handlebars
    {{#-in-element someElement}}
      {{modal-display text=text}}
    {{/-in-element}}
    ```
  
    into:
  
    ```handlebars
    {{#in-element someElement}}
      {{modal-display text=text}}
    {{/in-element}}
    ```
  
    And issues a deprecation message.
  
    Issues a build time assertion for:
  
    ```handlebars
    {{#in-element someElement insertBefore="some-none-null-value"}}
      {{modal-display text=text}}
    {{/in-element}}
    ```
  
    @private
    @class TransformInElement
  */
  function transformInElement(env) {
    var moduleName = env.meta.moduleName;
    var b = env.syntax.builders;
    return {
      name: 'transform-in-element',
      visitor: {
        BlockStatement: function BlockStatement(node) {
          if (!(0, _utils.isPath)(node.path)) return;

          if (node.path.original === 'in-element') {
            var originalValue = node.params[0];

            if (originalValue && !env.isProduction) {
              var subExpr = b.sexpr('-in-el-null', [originalValue]);
              node.params.shift();
              node.params.unshift(subExpr);
            }

            node.hash.pairs.forEach(function (pair) {
              if (pair.key === 'insertBefore') {
                (true && !(pair.value.type === 'NullLiteral' || pair.value.type === 'UndefinedLiteral') && (0, _debug.assert)("Can only pass null to insertBefore in in-element, received: " + JSON.stringify(pair.value), pair.value.type === 'NullLiteral' || pair.value.type === 'UndefinedLiteral'));
              }
            });
          } else if (node.path.original === '-in-element') {
            var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
            (true && !(false) && (0, _debug.deprecate)("The use of the private `{{-in-element}}` is deprecated, please refactor to the public `{{in-element}}`. " + sourceInformation, false, {
              id: 'glimmer.private-in-element',
              until: '3.25.0',
              for: 'ember-source',
              since: {
                enabled: '3.20.0'
              }
            }));
            node.path.original = 'in-element';
            node.path.parts = ['in-element']; // replicate special hash arguments added here:
            // https://github.com/glimmerjs/glimmer-vm/blob/ba9b37d44b85fa1385eeeea71910ff5798198c8e/packages/%40glimmer/syntax/lib/parser/handlebars-node-visitors.ts#L340-L363

            var needsInsertBefore = true;
            var hash = node.hash;
            hash.pairs.forEach(function (pair) {
              if (pair.key === 'insertBefore') {
                (true && !(pair.value.type === 'NullLiteral' || pair.value.type === 'UndefinedLiteral') && (0, _debug.assert)("Can only pass a null or undefined literals to insertBefore in -in-element, received: " + JSON.stringify(pair.value), pair.value.type === 'NullLiteral' || pair.value.type === 'UndefinedLiteral'));
                needsInsertBefore = false;
              }
            }); // Maintain compatibility with previous -in-element behavior (defaults to append, not clear)

            if (needsInsertBefore) {
              var nullLiteral = b.literal('NullLiteral', null);
              var nextSibling = b.pair('insertBefore', nullLiteral);
              hash.pairs.push(nextSibling);
            }
          }
        }
      }
    };
  }
});