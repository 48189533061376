define("@smile-io/ember-smile-polaris/templates/components/render-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6PsmhBsV",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],null,[[\"componentName\",\"props\"],[[33,3,[\"componentName\"]],[33,3,[\"props\"]]]]]],[1,\"\\n\"]],[]],[[[41,[33,4],[[[1,\"  \"],[46,[33,3],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"contentIsComponentHash\",\"component-proxy\",\"content\",\"contentIsComponentDefinition\",\"component\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/render-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});