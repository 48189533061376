define("ember-smile-core/components/polaris-modals/default", ["exports", "@ember/object", "ember-smile-core/components/polaris-modals/base", "ember-smile-core/templates/components/polaris-modals/default", "@ember/object/computed"], function (_exports, _object, _base, _default2, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    layout: _default2.default,

    /*
     * Confirm button type.
     */
    primary: true,
    destructive: false,

    /*
     * Modal title.
     */
    title: (0, _computed.readOnly)('modalParams.title'),

    /**
     * Text to render as modal body, if not using in block form.
     */
    body: (0, _computed.readOnly)('modalParams.body'),

    /**
     * Whether the primary action of this modal is running.
     */
    isActionRunning: (0, _computed.readOnly)('modalParams.isActionRunning'),

    /*
     * When false, the top-right close icon is hidden.
     * Default: true.
     */
    showCloseIcon: (0, _object.computed)('modalParams.showCloseIcon', function () {
      return this.getWithDefault('modalParams.showCloseIcon', true);
    }),

    /*
     * When false, the cancel button is hidden.
     * Default: true.
     */
    showCancelBtn: (0, _object.computed)('modalParams.showCancelBtn', function () {
      return this.getWithDefault('modalParams.showCancelBtn', true);
    }),

    /*
     * When false, the confirm button is hidden.
     * Default: true.
     */
    showConfirmBtn: (0, _object.computed)('modalParams.showConfirmBtn', function () {
      return this.getWithDefault('modalParams.showConfirmBtn', true);
    }),

    /**
     * Confirm button CTA text.
     * Default: 'Confirm'
     */
    confirmBtnText: (0, _object.computed)('modalParams.confirmBtnText', function () {
      return this.getWithDefault('modalParams.confirmBtnText', 'Confirm');
    }),

    /*
     * @private
     */
    setConfirmBtnType: function setConfirmBtnType() {
      var confirmBtnType = this.get('modalParams.confirmBtnType');
      var btnTypes = ['primary', 'destructive'];
      var btnTypeSet = false;

      for (var _i = 0, _btnTypes = btnTypes; _i < _btnTypes.length; _i++) {
        var btnType = _btnTypes[_i];
        var isBtnTypeSet = btnType === confirmBtnType;

        if (isBtnTypeSet) {
          btnTypeSet = true;
        }

        this.set(btnType, isBtnTypeSet);
      }

      if (!btnTypeSet) {
        this.set('primary', true);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setConfirmBtnType();
    }
  });

  _exports.default = _default;
});