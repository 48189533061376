define("ember-template-compiler/lib/plugins/transform-attrs-into-args", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformAttrsIntoArgs;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   {{attrs.foo.bar}}
    ```
  
    to
  
    ```handlebars
   {{@foo.bar}}
    ```
  
    as well as `{{#if attrs.foo}}`, `{{deeply (nested attrs.foobar.baz)}}`,
    `{{this.attrs.foo}}` etc
  
    @private
    @class TransformAttrsToProps
  */
  function transformAttrsIntoArgs(env) {
    var b = env.syntax.builders;
    var moduleName = env.meta.moduleName;
    var stack = [[]];
    return {
      name: 'transform-attrs-into-args',
      visitor: {
        Program: {
          enter: function enter(node) {
            var parent = stack[stack.length - 1];
            stack.push(parent.concat(node.blockParams));
          },
          exit: function exit() {
            stack.pop();
          }
        },
        PathExpression: function PathExpression(node) {
          if (isAttrs(node, stack[stack.length - 1])) {
            var path = b.path(node.original.substr(6));
            (true && !(false) && (0, _debug.deprecate)("Using {{attrs}} to reference named arguments has been deprecated. {{attrs." + path.original + "}} should be updated to {{@" + path.original + "}}. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), false, {
              id: 'attrs-arg-access',
              url: 'https://deprecations.emberjs.com/v3.x/#toc_attrs-arg-access',
              until: '4.0.0',
              for: 'ember-source',
              since: {
                enabled: '3.26.0'
              }
            }));
            path.original = "@" + path.original;
            path.data = true;
            return path;
          }
        }
      }
    };
  }

  function isAttrs(node, symbols) {
    var name = node.parts[0];

    if (symbols.indexOf(name) !== -1) {
      return false;
    }

    if (name === 'attrs') {
      if (node.this === true) {
        node.parts.shift();
        node.original = node.original.slice(5);
      }

      return true;
    }

    return false;
  }
});