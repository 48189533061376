define("ember-smile-core/templates/components/centered-callout-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rQZElrfd",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"vertical\",\"alignment\"],[true,\"center\"]],[[\"default\"],[[[[41,[33,3],[[[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"        \"],[10,\"img\"],[14,0,\"image\"],[15,\"src\",[36,3]],[14,\"alt\",\"\"],[12],[13],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"text-wrapper\"]],[[\"@vertical\",\"@alignment\",\"@spacing\"],[true,\"center\",\"tight\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,4],null,[[\"text\"],[[33,5]]]]],[1,\"\\n\\n\"],[6,[39,6],null,null,[[\"default\"],[[[[41,[48,[30,3]],[[[1,\"          \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[34,9]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"    \"]],[]]]]],[1,\"\\n\\n\"],[6,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[44,[[50,\"polaris-button\",0,null,[[\"text\",\"primary\",\"onClick\"],[[33,12,[\"text\"]],[33,12,[\"primary\"]],[28,[37,13],[[30,0],[33,12,[\"onAction\"]]],null]]]]],[[[41,[30,2],[[[41,[33,14],[[[6,[39,15],null,null,[[\"default\"],[[[[1,\"            \"],[46,[30,2],null,null,null],[1,\"\\n\\n            \"],[1,[28,[35,16],null,[[\"plain\",\"text\",\"onClick\"],[true,[33,14,[\"text\"]],[28,[37,13],[[30,0],[33,14,[\"onAction\"]]],null]]]]],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"          \"],[46,[30,2],null,null,null],[1,\"\\n\"]],[]]]],[]],null]],[2]]]],[]]]]]],[1]]]]]],[]]]]]],[\"stack\",\"primaryButton\",\"&default\"],false,[\"polaris-card/section\",\"polaris-stack\",\"if\",\"illustration\",\"polaris-heading\",\"title\",\"polaris-text-container\",\"has-block\",\"yield\",\"text\",\"let\",\"component\",\"primaryAction\",\"action\",\"secondaryAction\",\"polaris-button-group\",\"polaris-button\"]]",
    "moduleName": "ember-smile-core/templates/components/centered-callout-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});