define("@smile-io/ember-smile-polaris/templates/components/polaris-resource-list/filter-control/date-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yo4UWM6y",
    "block": "[[[8,[39,0],null,[[\"@dataTestSelect\",\"@label\",\"@labelHidden\",\"@placeholder\",\"@options\",\"@value\",\"@onChange\"],[\"date-selector\",\"Select a value\",true,\"Select a filter...\",[30,0,[\"dateOptions\"]],[28,[37,1],[[30,0,[\"dateFilterOption\"]],\"\"],null],[30,0,[\"handleDateFilterOptionsChange\"]]]],null],[1,\"\\n\\n\"],[41,[33,3],[[[1,\"  \"],[10,0],[14,0,\"Polaris-FilterControl-DateSelector__DateTextField\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@label\",\"@placeholder\",\"@autoComplete\",\"@prefix\",\"@value\",\"@error\",\"@onChange\",\"@onBlur\"],[\"Date\",\"YYYY-MM-DD\",false,[50,\"polaris-icon\",0,null,[[\"source\",\"color\"],[\"calendar\",\"skyDark\"]]],[30,0,[\"dateTextFieldValue\"]],[30,0,[\"userInputDateError\"]],[30,0,[\"handleDateFieldChange\"]],[30,0,[\"handleDateBlur\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-FilterControl-DateSelector__DatePicker\"],[12],[1,\"\\n    \"],[8,[39,6],null,[[\"@selected\",\"@month\",\"@year\",\"@onChange\",\"@onMonthChange\"],[[30,0,[\"selectedDate\"]],[30,0,[\"datePickerMonth\"]],[30,0,[\"datePickerYear\"]],[30,0,[\"handleDatePickerChange\"]],[30,0,[\"handleDatePickerMonthChange\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"polaris-select\",\"or\",\"if\",\"showDatePredicate\",\"polaris-text-field\",\"component\",\"polaris-date-picker\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-resource-list/filter-control/date-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});