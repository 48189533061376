define("ember-smile-core/components/tab-panel-card", ["exports", "@ember/component", "ember-smile-core/templates/components/tab-panel-card"], function (_exports, _component, _tabPanelCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _tabPanelCard.default,

    /**
     * When true, will show skeleton UI
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    isLoading: false,
    header: 'tab-panel-card/header',
    tabPanel: 'tab-panel-card/tab-panel'
  });

  _exports.default = _default;
});