define("ember-smile-core/components/polaris-resource-list/cell", ["exports", "@ember/component", "@ember/object", "@ember/string", "ember-smile-core/templates/components/polaris-resource-list/cell"], function (_exports, _component, _object, _string, _cell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: ['style'],
    classNames: ['Polaris-ResourceList-Cell'],
    layout: _cell.default,

    /**
     * Component to render inside the cell.
     *
     * @property component
     * @type {String|Component|Object}
     * default null
     * @public
     */
    component: null,

    /**
     * Width of the cell.
     *
     * @property width
     * @type {String}
     * default 'auto'
     * @public
     */
    width: 'auto',

    /**
     * Horizontal alignment of the cell content.
     * Can be any value supported by `text-align`.
     *
     * @property alignment
     * @type {String}
     * @default 'inherit'
     * @public
     */
    alignment: 'inherit',
    style: (0, _object.computed)('width', 'alignment', function () {
      return (0, _string.htmlSafe)("\n      flex-basis: ".concat(this.get('width'), ";\n      text-align: ").concat(this.get('alignment'), ";\n    "));
    }).readOnly()
  });

  _exports.default = _default;
});