define("ember-smile-core/templates/components/no-op", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RyQEeUZi",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/no-op.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});