define("@smile-io/ember-smile-polaris/templates/components/polaris-text-field/spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bZRBKQJs",
    "block": "[[[11,0],[24,\"data-test-text-field-spinner\",\"\"],[24,0,\"Polaris-TextField__Spinner\"],[24,\"aria-hidden\",\"\"],[17,1],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[11,0],[24,\"data-test-text-field-spinner-incr-button\",\"\"],[24,\"role\",\"button\"],[24,0,\"Polaris-TextField__Segment\"],[24,\"tabindex\",\"-1\"],[4,[38,0],[\"click\",[28,[37,1],[[30,2],1],null]],null],[4,[38,0],[\"mousedown\",[28,[37,1],[[30,0,[\"handleMouseDown\"]],[28,[37,1],[[30,2],1],null]],null]],null],[4,[38,0],[\"mouseup\",[30,0,[\"onMouseUp\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-TextField__SpinnerIcon\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@source\"],[\"caret-up\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,0],[24,\"data-test-text-field-spinner-decr-button\",\"\"],[24,\"role\",\"button\"],[24,0,\"Polaris-TextField__Segment\"],[24,\"tabindex\",\"-1\"],[4,[38,0],[\"click\",[28,[37,1],[[30,2],-1],null]],null],[4,[38,0],[\"mousedown\",[28,[37,1],[[30,0,[\"handleMouseDown\"]],[28,[37,1],[[30,2],-1],null]],null]],null],[4,[38,0],[\"mouseup\",[30,0,[\"onMouseUp\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-TextField__SpinnerIcon\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@source\"],[\"caret-down\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@onChange\"],false,[\"on\",\"fn\",\"polaris-icon\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-text-field/spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});