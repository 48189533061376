define("ember-smile-core/components/file-uploader", ["exports", "@ember/component", "ember-smile-core/templates/components/file-uploader"], function (_exports, _component, _fileUploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['smile-uploader'],
    layout: _fileUploader.default,

    /**
     * List of accepted file types
     *
     * @type {String}
     * @public
     */
    acceptedFiles: null,

    /**
     * URL for presigning uploads.
     *
     * @type {String}
     * @public
     */
    signingUrl: null,

    /**
     * Hash to pass as the `extra` part of the file processing options hash.
     *
     * @type {Options}
     * @public
     */
    fileProcessingOptions: null,

    /**
     * Auth token to pass with the file upload.
     *
     * @type {String}
     * @public
     */
    authToken: null,

    /**
     * Disables the file selection controls.
     *
     * @type {Boolean}
     * @public
     */
    disabled: false,

    /**
     * Action invoked on upload error.
     *
     * @type {Function}
     * @public
     */
    onError: function onError() {},

    /**
     * Action fired when an image is selected.
     *
     * @type {Function}
     * @public
     */
    onDrop: function onDrop() {},
    actions: {
      onUploadComplete: function onUploadComplete(_ref) {
        var uploadedKey = _ref.uploadedKey,
            uploadedUrl = _ref.uploadedUrl;
        this.onDrop({
          fileUrl: uploadedUrl,
          fileS3Key: uploadedKey
        });
      }
    }
  });

  _exports.default = _default;
});