define("@smile-io/ember-smile-polaris/templates/components/polaris-data-table/cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "X6uDQtya",
    "block": "[[[41,[28,[37,1],[[30,1],[30,2]],null],[[[41,[30,1],[[[1,\"    \"],[10,\"th\"],[14,\"data-polaris-header-cell\",\"true\"],[14,\"scope\",\"col\"],[15,0,[30,0,[\"cellClassNames\"]]],[15,\"aria-sort\",[30,3]],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"        \"],[10,\"button\"],[15,0,[30,0,[\"headerClassNames\"]]],[15,\"onclick\",[28,[37,3],[[30,0,[\"onSort\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"Polaris-DataTable__Icon\"],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@source\",\"@accessibilityLabel\"],[[30,0,[\"source\"]],[30,0,[\"sortAccessibilityLabel\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[1,[28,[35,5],[[30,4]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,5],[[30,4]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"th\"],[14,\"scope\",\"row\"],[15,0,[30,0,[\"cellClassNames\"]]],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n      \"],[1,[28,[35,5],[[30,4]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[10,\"td\"],[15,0,[30,0,[\"cellClassNames\"]]],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n    \"],[1,[28,[35,5],[[30,4]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@header\",\"@fixed\",\"@sortDirection\",\"@text\"],false,[\"if\",\"or\",\"sortable\",\"fn\",\"polaris-icon\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-data-table/cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});