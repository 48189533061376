define("@smile-io/ember-smile-polaris/templates/components/polaris-progress-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BVaBtoRi",
    "block": "[[[11,0],[24,\"data-test-progress-bar\",\"\"],[16,0,[29,[\"Polaris-ProgressBar \",[30,1],\" \",[30,0,[\"sizeClass\"]]]]],[17,2],[12],[1,\"\\n  \"],[10,\"progress\"],[14,\"data-test-progress-bar-progress\",\"\"],[14,0,\"Polaris-ProgressBar__Progress\"],[14,\"max\",\"100\"],[15,2,[30,0,[\"parsedProgress\"]]],[12],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,\"data-test-progress-bar-indicator\",\"\"],[14,0,\"Polaris-ProgressBar__Indicator\"],[15,5,[36,0]],[12],[1,\"\\n    \"],[10,1],[14,\"data-test-progress-bar-indicator-label\",\"\"],[14,0,\"Polaris-ProgressBar__Label\"],[12],[1,\"\\n      \"],[1,[30,0,[\"parsedProgress\"]]],[1,\"%\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\"],false,[\"progressStyle\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-progress-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});