define("@smile-io/ember-smile-polaris/templates/components/polaris-choice", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "47kQ3gbF",
    "block": "[[[44,[[50,\"polaris-choice/label\",0,null,[[\"inputId\",\"label\",\"labelHidden\",\"disabled\"],[[30,1],[30,2],[30,0,[\"labelHidden\"]],[30,0,[\"disabled\"]]]]]],[[[41,[30,0,[\"hasDescription\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[8,[30,3],[[16,0,[28,[37,3],[[30,4],[30,0,[\"choiceClass\"]]],null]],[17,5]],null,[[\"default\"],[[[[1,\"\\n        \"],[18,8,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[10,0],[14,0,\"Polaris-Choice__Descriptions\"],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRenderError\"]],[[[1,\"          \"],[10,0],[14,0,\"Polaris-Choice__Error\"],[12],[1,\"\\n            \"],[8,[39,5],null,[[\"@fieldID\",\"@message\"],[[30,1],[30,6]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,6],[[[1,\"          \"],[10,0],[14,\"data-test-choice-help-text\",\"\"],[14,0,\"Polaris-Choice__HelpText\"],[15,1,[30,0,[\"helpTextId\"]]],[12],[1,\"\\n            \"],[1,[28,[35,7],[[30,7]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,3],[[16,0,[28,[37,3],[[30,4],[30,0,[\"choiceClass\"]]],null]],[17,5]],null,[[\"default\"],[[[[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[3]]]],[\"@inputId\",\"@label\",\"ChoiceLabel\",\"@choiceClass\",\"&attrs\",\"@error\",\"@helpText\",\"&default\"],false,[\"let\",\"component\",\"if\",\"or\",\"yield\",\"polaris-inline-error\",\"helpText\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-choice.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});