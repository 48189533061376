define("@smile-io/ember-smile-polaris/templates/components/polaris-form-layout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Sco1UDdF",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-FormLayout \",[30,1]]]],[24,\"data-test-form-layout\",\"\"],[17,2],[4,[38,0],[[30,0,[\"setupAutoWrapper\"]]],null],[4,[38,1],[[30,0,[\"teardownAutoWrapper\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,[[28,[37,5],null,[[\"group\",\"item\"],[[50,\"polaris-form-layout/group\",0,null,null],[50,\"polaris-form-layout/item\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,3]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@text\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-form-layout.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});