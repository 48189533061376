define("@smile-io/ember-smile-polaris/templates/components/polaris-option-list/checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Db84ZNXo",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-OptionList-Checkbox \",[30,1],\" \",[52,[30,2],\"Polaris-OptionList-Checkbox--active\"]]]],[17,3],[12],[1,\"\\n  \"],[11,\"input\"],[16,1,[28,[37,1],[[30,4],[30,0,[\"checkboxId\"]]],null]],[16,3,[30,5]],[16,2,[30,6]],[16,\"checked\",[30,7]],[16,\"disabled\",[30,8]],[24,0,\"Polaris-OptionList-Checkbox__Input\"],[16,\"aria-checked\",[30,7]],[16,\"role\",[30,9]],[24,4,\"checkbox\"],[4,[38,2],[\"change\",[28,[37,1],[[30,10],[30,0,[\"onChange\"]]],null]],null],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-OptionList-Checkbox__Backdrop\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-OptionList-Checkbox__Icon\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@source\"],[\"checkmark\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@active\",\"&attrs\",\"@checkboxId\",\"@name\",\"@value\",\"@checked\",\"@disabled\",\"@role\",\"@onChange\"],false,[\"if\",\"or\",\"on\",\"polaris-icon\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-option-list/checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});