define("ember-smile-core/templates/components/polaris-app", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BwfnuYM2",
    "block": "[[[6,[39,0],null,[[\"class\",\"open\",\"locked\",\"translucentOverlay\",\"dismissOnClick\",\"dismissOnEsc\",\"width\"],[[33,1],[33,2],[28,[37,3],[[28,[37,4],[[33,5]],null]],null],[28,[37,3],[[33,5]],null],false,false,240]],[[\"default\"],[[[[41,[33,7],[[[1,\"    \"],[1,[28,[35,8],null,[[\"homeRoute\",\"logoIcon\",\"topBarContentComponent\",\"profileTriggerComponent\",\"profileMenuComponent\",\"profileComponent\",\"brandingComponent\",\"navToggleIcon\",\"navToggleIconColor\",\"onToggleNav\"],[[33,9],[33,10],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16],[33,17],[28,[37,18],[[30,0],[30,1,[\"state\",\"actions\",\"toggle\"]]],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,19],[[[6,[30,1,[\"menu\"]],null,[[\"itemTagName\",\"class\",\"tagName\",\"dismissOnItemClick\"],[\"li\",\"polaris-app-nav\",\"aside\",[28,[37,3],[[33,5]],null]]],[[\"default\"],[[[[1,\"      \"],[46,[33,21],null,[[\"nav\"],[[28,[37,22],null,[[\"heading\",\"group\"],[[50,\"polaris-app-nav-heading\",0,null,null],[50,\"polaris-app-nav-group\",0,null,[[\"menuItemComponent\"],[[30,2,[\"item\"]]]]]]]]]],null],[1,\"\\n\"]],[2]]]]]],[]],null],[1,\"\\n\"],[6,[30,1,[\"outlet\"]],null,[[\"class\",\"tagName\"],[\"polaris-app-main\",\"main\"]],[[\"default\"],[[[[1,\"    \"],[18,3,null],[1,\"\\n\"]],[]]]]],[1,\"\\n  \"],[1,[34,24]],[1,\"\\n\"]],[1]]]]]],[\"burger\",\"menu\",\"&default\"],false,[\"burger-menu\",\"appClasses\",\"isNavOpen\",\"readonly\",\"not\",\"isOnMobile\",\"if\",\"isTopBarVisible\",\"polaris-app-top-bar\",\"homeRoute\",\"logoIcon\",\"topBarContentComponent\",\"profileTriggerComponent\",\"profileMenuComponent\",\"profileComponent\",\"brandingComponent\",\"navToggleIcon\",\"navToggleIconColor\",\"action\",\"isNavEnabled\",\"component\",\"navContentComponent\",\"hash\",\"yield\",\"polaris-app-bottom-bar\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-app.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});