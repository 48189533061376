define("@smile-io/ember-smile-polaris/templates/components/polaris-stack/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dxV3Mkl/",
    "block": "[[[11,0],[16,\"data-test-stack-item\",true],[16,0,[30,0,[\"cssClasses\"]]],[17,1],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,3]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"text\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-stack/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});