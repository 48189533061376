define("ember-svg-jar/inlined/smile/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path d=\"M9.998 12.12a2.122 2.122 0 000-4.242 2.12 2.12 0 000 4.242zm0 1.818A3.94 3.94 0 1110 6.06a3.94 3.94 0 010 7.878zM20 7.575v4.848h-1.871a8.415 8.415 0 01-.67 1.604l1.327 1.325-3.425 3.43-1.329-1.326a8.39 8.39 0 01-1.606.672V20H7.578v-1.871a8.417 8.417 0 01-1.606-.671l-1.326 1.328-3.43-3.426 1.327-1.328a8.394 8.394 0 01-.672-1.609H0V7.575h1.87a8.418 8.418 0 01.672-1.606L1.216 4.642l3.427-3.428L5.97 2.541a8.386 8.386 0 011.606-.67V0h4.848v1.87a8.384 8.384 0 011.603.67l1.33-1.328 3.427 3.427-1.328 1.328c.28.513.506 1.051.673 1.608H20zm-1.818 1.818H16.679l-.148-.727a6.616 6.616 0 00-.978-2.337l-.41-.62 1.07-1.07-.857-.857-1.072 1.071-.62-.41a6.574 6.574 0 00-2.33-.973l-.728-.148V1.818H9.394v1.504l-.728.148c-.83.168-1.62.5-2.334.974l-.62.411-1.069-1.07-.856.857 1.07 1.07-.412.62a6.607 6.607 0 00-.976 2.334l-.148.727H1.818v1.212h1.503l.148.728c.17.832.502 1.62.977 2.335l.412.62-1.07 1.07.856.856 1.07-1.07.62.41a6.606 6.606 0 002.334.976l.728.149v1.503h1.211v-1.506l.729-.147a6.578 6.578 0 002.333-.976l.62-.41 1.07 1.068.855-.856-1.07-1.068.41-.62a6.61 6.61 0 00.977-2.335l.148-.727h1.503V9.393z\" id=\"a\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#555\" fill-rule=\"nonzero\" xlink:href=\"#a\"/><g mask=\"url(#b)\" fill=\"#919EAB\"><path d=\"M0 0h20v20H0z\"/></g></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});