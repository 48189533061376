define("ember-smile-core/templates/components/editable-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RAStx4oC",
    "block": "[[[44,[[28,[37,1],[[33,2],[28,[37,3],null,[[\"existing-items\",\"new-item\"],[[50,[33,5],0,null,[[\"class\",\"editableList\"],[\"editable-list-item\",[33,2]]]],[50,[33,6],0,null,[[\"class\",\"editableList\"],[\"editable-list-item\",[33,2]]]]]]]],null]],[[[41,[30,1],[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,1,[\"existing-items\"]]],[1,\"\\n    \"],[1,[30,1,[\"new-item\"]]],[1,\"\\n\"]],[]]]],[]],null]],[1]]]],[\"editableList\",\"&default\"],false,[\"let\",\"assign\",\"listAPI\",\"hash\",\"component\",\"existingItemsComponent\",\"newItemComponent\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/editable-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});