define("ember-smile-core/components/polaris-tabs", ["exports", "@ember/component", "ember-smile-core/templates/components/polaris-tabs"], function (_exports, _component, _polarisTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['polaris-tabs-component'],
    layout: _polarisTabs.default,
    tabs: null,
    fitted: false,
    withBorderTop: false,
    actions: {// TODO check why this breaks the link and always reloads the page when using query params
      // tabClicked(tab, event) {
      //   Remove the focus from the clicked tab.
      //   event.currentTarget.blur();
      // },
    }
  });

  _exports.default = _default;
});