define("@smile-io/ember-smile-polaris/-private/drop-zone-state", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var dropZone = {
    overlayTextFile: 'Drop file to upload',
    overlayTextImage: 'Drop image to upload',
    errorOverlayTextFile: 'File type is not valid',
    errorOverlayTextImage: 'Image type is not valid'
  };

  var DropZoneState =
  /**
   * Whether is a file or an image
   *
   * @type {String}
   * @default 'file'
   */

  /**
   * The size of the dropzone
   * @type {String}
   * @default 'extraLarge'
   */

  /**
   * True, when dragging in progress
   * @type {Boolean}
   * @default false
   */

  /**
   * True, when dropzone has errors
   * @type {Boolean}
   * @default false
   */

  /**
   * Number of files
   * @type {Number}
   * @default 0
   */
  function DropZoneState() {
    _classCallCheck(this, DropZoneState);

    _defineProperty(this, "type", 'file');

    _defineProperty(this, "size", 'extraLarge');

    _defineProperty(this, "dragging", false);

    _defineProperty(this, "error", false);

    _defineProperty(this, "numFiles", 0);

    var suffix = (0, _string.capitalize)(this.type);
    this.overlayText = dropZone["overlayText".concat(suffix)];
    this.errorOverlayText = dropZone["errorOverlayText".concat(suffix)];
  };

  _exports.default = DropZoneState;
});