define("@smile-io/ember-smile-polaris/templates/components/polaris-form-layout/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Uq6WxXIZ",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-FormLayout__Item \",[30,1]]]],[24,\"data-test-form-layout-item\",\"\"],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-form-layout/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});