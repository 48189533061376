define("liquid-fire/components/liquid-versions", ["exports", "jquery", "@ember/array", "@ember/service", "@ember/component", "@ember/object", "liquid-fire/ember-internals", "liquid-fire/templates/components/liquid-versions"], function (_exports, _jquery, _array, _service, _component, _object, _emberInternals, _liquidVersions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _liquidVersions.default,
    tagName: "",
    transitionMap: (0, _service.inject)('liquid-fire-transitions'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.appendVersion();
    },
    appendVersion: function appendVersion() {
      var versions = this.versions;
      var firstTime = false;
      var newValue = this.getAttr('value');
      var oldValue;
      var versionEquality = this.get('versionEquality') || defaultEqualityCheck;

      if (!versions) {
        firstTime = true;
        versions = (0, _array.A)();
      } else {
        if (versions[0]) {
          oldValue = versions[0].value;
        }
      }

      if (!firstTime && versionEquality(oldValue, newValue)) {
        if (versions[0] && versionEquality !== defaultEqualityCheck) {
          // When using custom equality checkers, we may consider values
          // equal for our purposes that are not `===`. In that case, we
          // still need to thread updated values through to our children
          // so they have their own opportunity to react.
          (0, _object.set)(versions[0], 'value', newValue);
        }

        return;
      }

      this.notifyContainer('willTransition', versions);
      var newVersion = {
        value: newValue
      };
      versions.unshiftObject(newVersion);
      this.firstTime = firstTime;

      if (firstTime) {
        (0, _object.set)(this, 'versions', versions);
      }

      if (!(newValue || this.get('renderWhenFalse') || firstTime)) {
        this._transition();
      }
    },
    _transition: function _transition() {
      var _this = this;

      var versions = (0, _object.get)(this, 'versions');
      var transition;
      var firstTime = this.firstTime;
      this.firstTime = false;
      this.notifyContainer('afterChildInsertion', versions);
      transition = (0, _object.get)(this, 'transitionMap').transitionFor({
        versions: versions,
        parentElement: (0, _jquery.default)((0, _emberInternals.containingElement)(this)),
        use: (0, _object.get)(this, 'use'),
        rules: (0, _object.get)(this, 'rules'),
        matchContext: (0, _object.get)(this, 'matchContext') || {},
        // Using strings instead of booleans here is an
        // optimization. The constraint system can match them more
        // efficiently, since it treats boolean constraints as generic
        // "match anything truthy/falsy" predicates, whereas string
        // checks are a direct object property lookup.
        firstTime: firstTime ? 'yes' : 'no'
      });

      if (this._runningTransition) {
        this._runningTransition.interrupt();
      }

      this._runningTransition = transition;
      transition.run().then(function (wasInterrupted) {
        // if we were interrupted, we don't handle the cleanup because
        // another transition has already taken over.
        if (!wasInterrupted) {
          _this.finalizeVersions(versions);

          _this.notifyContainer("afterTransition", versions);
        }
      }, function (err) {
        _this.finalizeVersions(versions);

        _this.notifyContainer("afterTransition", versions);

        throw err;
      });
    },
    finalizeVersions: function finalizeVersions(versions) {
      versions.replace(1, versions.length - 1);
    },
    notifyContainer: function notifyContainer(method, versions) {
      var target = (0, _object.get)(this, 'notify');

      if (target && !target.get('isDestroying')) {
        target.send(method, versions);
      }
    },
    actions: {
      childDidRender: function childDidRender(child) {
        var version = (0, _object.get)(child, 'version');
        (0, _object.set)(version, 'view', child);

        this._transition();
      }
    }
  }); // All falsey values are considered equal, everything else gets strict
  // equality.


  _exports.default = _default;

  function defaultEqualityCheck(a, b) {
    return !a && !b || a === b;
  }
});