define("ember-smile-core/utils/decorators/arg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arg = arg;

  /**
   * Decorator to set a default value for named arguments
   *
   * @arg myAttribute = 'default-value';
   *
   * Note: This already exists in the v7 branch of ember-polaris, so
   * we should remove this decorator once v7 ships and just pull this
   * util from ember-polaris.
   * https://smileio.atlassian.net/browse/PROD-1969
   */
  function arg(target, key, descriptor) {
    return {
      get: function get() {
        var argValue = this.args[key];
        return argValue !== undefined ? argValue : descriptor.initializer ? descriptor.initializer.call(this) : undefined;
      }
    };
  }
});