define("@smile-io/ember-smile-polaris/templates/components/polaris-tag", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "by7cS7nU",
    "block": "[[[11,1],[16,0,[29,[\"Polaris-Tag \",[30,1],\" \",[52,[30,2],\"Polaris-Tag--disabled\"]]]],[16,\"data-test-tag\",true],[17,3],[4,[38,1],[[30,0,[\"setTagText\"]]],null],[12],[1,\"\\n  \"],[10,1],[14,\"data-test-tag-text\",\"\"],[15,\"title\",[30,0,[\"tagText\"]]],[14,0,\"Polaris-Tag__TagText\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@onRender\"],[[30,0,[\"updateTagText\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"        \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"    \"],[11,\"button\"],[24,\"data-test-tag-button\",\"\"],[24,0,\"Polaris-Tag__Button\"],[16,\"aria-label\",[30,0,[\"buttonLabel\"]]],[16,\"disabled\",[30,2]],[24,4,\"button\"],[4,[38,5],[\"click\",[30,5]],null],[4,[38,5],[\"mouseup\",[30,0,[\"handleMouseUpByBlurring\"]]],null],[12],[1,\"\\n      \"],[8,[39,6],[[24,\"data-test-tag-button-icon\",\"\"]],[[\"@source\"],[\"cancel-small\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"@disabled\",\"&attrs\",\"@text\",\"@onRemove\",\"&default\"],false,[\"if\",\"did-insert\",\"render-detector\",\"has-block\",\"yield\",\"on\",\"polaris-icon\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-tag.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});