define("@smile-io/ember-smile-polaris/utils/color", ["exports", "@ember/utils", "@smile-io/ember-smile-polaris/utils/math"], function (_exports, _utils, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hsbaToRgba = hsbaToRgba;
  _exports.rgbaToHsb = rgbaToHsb;
  _exports.rgbaString = rgbaString;
  _exports.rgbToHex = rgbToHex;
  _exports.hsbToHex = hsbToHex;
  _exports.rgbaToHex = rgbaToHex;
  _exports.hexToRgb = hexToRgb;
  _exports.hexToHsb = hexToHsb;
  _exports.hsbaToHex = hsbaToHex;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /*
   * Implements https://en.wikipedia.org/wiki/HSL_and_HSV#From_HSV
   * to convert a hue/saturation/brightness color to RGB.
   *
   * If an alpha value is present in the input, it will be passed through,
   * otherwise a default value of 1 will be included in the output.
   *
   * Examples:
   *   hsbaToRgba({ hue: 70, saturation: 0.3, brightness: 0.8 })
   *     => { red: 194, green: 204, blue: 143, alpha: 1 }
   */
  function hsbaToRgba(color) {
    var hue = color.hue,
        saturation = color.saturation,
        brightness = color.brightness,
        _color$alpha = color.alpha,
        alpha = _color$alpha === void 0 ? 1 : _color$alpha;
    var chroma = brightness * saturation;
    var huePrime = hue / 60;
    var hueDelta = 1 - Math.abs(huePrime % 2 - 1);
    var intermediateValue = chroma * hueDelta;
    var red = 0;
    var green = 0;
    var blue = 0;

    if (huePrime >= 0 && huePrime <= 1) {
      red = chroma;
      green = intermediateValue;
      blue = 0;
    }

    if (huePrime >= 1 && huePrime <= 2) {
      red = intermediateValue;
      green = chroma;
      blue = 0;
    }

    if (huePrime >= 2 && huePrime <= 3) {
      red = 0;
      green = chroma;
      blue = intermediateValue;
    }

    if (huePrime >= 3 && huePrime <= 4) {
      red = 0;
      green = intermediateValue;
      blue = chroma;
    }

    if (huePrime >= 4 && huePrime <= 5) {
      red = intermediateValue;
      green = 0;
      blue = chroma;
    }

    if (huePrime >= 5 && huePrime <= 6) {
      red = chroma;
      green = 0;
      blue = intermediateValue;
    }

    var chromaBrightnessDelta = brightness - chroma;
    red += chromaBrightnessDelta;
    green += chromaBrightnessDelta;
    blue += chromaBrightnessDelta;
    return {
      red: Math.round(red * 255),
      green: Math.round(green * 255),
      blue: Math.round(blue * 255),
      alpha: alpha
    };
  }
  /*
   * Implements https://en.wikipedia.org/wiki/HSL_and_HSV
   * to convert an rgba color to hsba.
   *
   * If an alpha value is present in the input, it will be passed through,
   * otherwise a default value of 1 will be included in the output.
   *
   * Examples:
   *   rgbaToHsb({ red: 194, green: 204, blue: 143, alpha: 1 })
   *     => { hue: 70, saturation: 0.3, brightness: 0.8 }
   */


  function rgbaToHsb(color) {
    var red = color.red,
        green = color.green,
        blue = color.blue,
        _color$alpha2 = color.alpha,
        alpha = _color$alpha2 === void 0 ? 1 : _color$alpha2;
    var r = red / 255;
    var g = green / 255;
    var b = blue / 255;
    var largestComponent = Math.max(r, g, b);
    var smallestComponent = Math.min(r, g, b);
    var delta = largestComponent - smallestComponent;
    var saturation = largestComponent === 0 ? 0 : delta / largestComponent;
    var huePercentage = 0;

    switch (largestComponent) {
      case r:
        huePercentage = (g - b) / delta + (g < b ? 6 : 0);
        break;

      case g:
        huePercentage = (b - r) / delta + 2;
        break;

      case b:
        huePercentage = (r - g) / delta + 4;
        break;
    }

    var hue = Math.round(huePercentage / 6 * 360);
    return {
      hue: (0, _math.clamp)(hue, 0, 360) || 0,
      saturation: (0, _math.clamp)(saturation, 0, 1),
      brightness: (0, _math.clamp)(largestComponent, 0, 1),
      alpha: alpha
    };
  }
  /*
   * Converts an RGB(a) color represented as an object into a valid CSS `rgb` / `rgba`
   * color.
   *
   * Examples:
   *   rgbaString({ red: 194, green: 204, blue: 143, alpha: 1 })
   *     => rga()
   *
  
   */


  function rgbaString(color) {
    var red = color.red,
        green = color.green,
        blue = color.blue,
        alpha = color.alpha;

    if ((0, _utils.isPresent)(alpha)) {
      return "rgba(".concat(red, ", ").concat(green, ", ").concat(blue, ", ").concat(alpha, ")");
    }

    return "rgb(".concat(red, ", ").concat(green, ", ").concat(blue, ")");
  }

  function rgbToHex(_ref) {
    var red = _ref.red,
        green = _ref.green,
        blue = _ref.blue;
    return "#".concat(componentToHex(red)).concat(componentToHex(green)).concat(componentToHex(blue));
  }

  function componentToHex(component) {
    var hex = component.toString(16);
    return hex.length === 1 ? "0".concat(hex) : hex;
  }

  function hsbToHex(color) {
    return rgbToHex(hsbaToRgba(color));
  }
  /*
   * Converts an object representation of an RGB(a) color to HEX.
   *
   * NOTE: This is shamelessly copied from https://github.com/sindresorhus/rgb-hex
   *
   * Examples:
   *
   * rgbHex({ 65, 131, 196 });
   * => '4183c4'
   *
   * rgbHex(65, 131, 196);
   * => '4183c4'
   *
   * rgbHex('rgb(40, 42, 54)');
   * => '282a36'
   *
   * rgbHex(65, 131, 196, 0.2);
   * => '4183c433'
   *
   * rgbHex(40, 42, 54, '75%');
   * => '282a36bf'
   *
   * rgbHex('rgba(40, 42, 54, 75%)');
   * => '282a36bf'
   */


  function rgbaToHex(red, green, blue, alpha) {
    var isPercent = "".concat(red).concat(alpha || '').toString().includes('%');

    if (typeof red === 'string') {
      var res = red.match(/(0?\.?\d{1,3})%?\b/g).map(Number);

      var _res = _slicedToArray(res, 4);

      red = _res[0];
      green = _res[1];
      blue = _res[2];
      alpha = _res[3];
    } else if (_typeof(red) === 'object') {
      var _red = red;
      red = _red.red;
      green = _red.green;
      blue = _red.blue;
      alpha = _red.alpha;
    } else if ((0, _utils.isPresent)(alpha)) {
      alpha = parseFloat(alpha);
    }

    if (typeof red !== 'number' || typeof green !== 'number' || typeof blue !== 'number' || red > 255 || green > 255 || blue > 255) {
      throw new TypeError('Expected three numbers below 256');
    }

    if (typeof alpha === 'number') {
      if (!isPercent && alpha >= 0 && alpha <= 1) {
        alpha = Math.round(255 * alpha);
      } else if (isPercent && alpha >= 0 && alpha <= 100) {
        alpha = Math.round(255 * alpha / 100);
      } else {
        throw new TypeError("Expected alpha value (".concat(alpha, ") as a fraction or percentage"));
      }

      alpha = (alpha | 1 << 8).toString(16).slice(1);
    } else {
      alpha = '';
    }

    return (blue | green << 8 | red << 16 | 1 << 24).toString(16).slice(1) + alpha;
  }

  function hexToRgb(hex) {
    if (typeof hex !== 'string') {
      throw new TypeError('Expected a string');
    }

    hex = hex.replace(/^#/, '');

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    var num = parseInt(hex, 16);
    return {
      red: num >> 16,
      green: num >> 8 & 255,
      blue: num & 255
    };
  }

  function hexToHsb(hex) {
    return rgbaToHsb(hexToRgb(hex));
  }

  function hsbaToHex(color) {
    return rgbaToHex(hsbaToRgba(color));
  }
});