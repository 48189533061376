define("ember-svg-jar/inlined/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Page 1</title><g fill=\"#919EAB\" fill-rule=\"evenodd\"><path d=\"M10.833 6.667V5A.833.833 0 0010 4.167h-.833v-.834h2.5l-.001 3.334h1.667V3.333c0-.919-.746-1.666-1.666-1.666h-2.5V.833A.833.833 0 008.333 0H5a.833.833 0 00-.833.833v.834h-2.5C.747 1.667 0 2.413 0 3.333V15c0 .92.747 1.667 1.667 1.667h6.666V15H1.667V3.333h2.5v.834h-.834A.833.833 0 002.5 5v8.333c0 .461.373.834.833.834h5V12.5H4.167V5.833h5v.834h1.666zM7.5 4.167H5.833v-2.5H7.5v2.5z\"/><path d=\"M19.167 8.333h-8.334a.834.834 0 00-.833.834v10c0 .46.373.833.833.833h8.334c.46 0 .833-.373.833-.833v-10a.833.833 0 00-.833-.834zm-.834 10h-6.666V10h6.666v8.333z\"/><path d=\"M12.5 13.333h5V15h-5zM14.167 10.833H17.5V12.5h-3.333zM12.5 15.833h5V17.5h-5z\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});