define('ember-context/index', ['exports', 'ember-context/components/context-provider', 'ember-context/components/context-consumer'], function (exports, _contextProvider, _contextConsumer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createContext;


  /**
   * Traverses the provided view's ancestor tree
   * to find the nearest provider component of the
   * appropriate type.
   */
  function findProviderAncestorView(view, providerId) {
    var ancestorView = view.get('parentView');

    while (ancestorView) {
      // Check if ancestor view is context provider.
      if (ancestorView.get('_providerId') === providerId) {
        return ancestorView;
      }

      ancestorView = ancestorView.get('parentView');
    }

    return null;
  }

  /**
   * React-style createContext function.
   * Takes a default context value and returns a tuple of
   * Provider and Consumer components, and a ConsumerMixin
   * used for emulation of React's `Class.contextType`.
   */
  function createContext(defaultValue) {
    var id = new Date().getTime();
    var providerId = 'provider' + id;

    var Provider = _contextProvider.default.extend({
      _providerId: providerId,

      value: Ember.computed(function () {
        return defaultValue;
      })
    });

    var ConsumerMixin = Ember.Mixin.create({
      _providerInstance: Ember.computed(function () {
        return findProviderAncestorView(this, providerId);
      }).readOnly(),

      context: Ember.computed('_providerInstance.value', function () {
        var providerInstance = this.get('_providerInstance');

        (false && !(!!providerInstance) && Ember.assert('Context consumer missing provider instance', !!providerInstance));


        return providerInstance && providerInstance.get('value');
      }).readOnly()
    });

    var Consumer = _contextConsumer.default.extend(ConsumerMixin);

    return {
      Provider: Provider,
      Consumer: Consumer,
      ConsumerMixin: ConsumerMixin
    };
  }
});