define("ember-smile-core/components/image-uploader", ["exports", "@ember/component", "@ember/object", "@ember/utils", "ember-smile-core/templates/components/image-uploader"], function (_exports, _component, _object, _utils, _imageUploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['smile-uploader'],
    layout: _imageUploader.default,

    /**
     * Minimum width for the selected image.
     *
     * @property {imgWidth}
     * @type {Number|null}
     * @default null
     * @public
     */
    imgWidth: null,

    /**
     * Minimum height for the selected image.
     *
     * @property {imgHeight}
     * @type {Number|null}
     * @default null
     * @public
     */
    imgHeight: null,

    /**
     * Valid file types to display in case of error.
     */
    acceptedFiles: '.jpg,.png',

    /**
     * URL for presigning uploads.
     *
     * @type {String}
     * @public
     */
    signingUrl: null,

    /**
     * Hash to pass as the `extra` part of the file processing options hash.
     *
     * @type {Options}
     * @public
     */
    fileProcessingOptions: null,

    /**
     * Auth token to pass with the file upload.
     *
     * @type {String}
     * @public
     */
    authToken: null,

    /**
     * Disables the file selection controls.
     * @property disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    /**
     * URL for any existing image (if present).
     * @property {iconUrl}
     * @type {String}
     * @default null
     * @public
     */
    iconUrl: null,

    /**
     * Whether or not the user should be able to
     * remove the image.
     * @property {isImageRemovable}
     * @type {Boolean}
     * @default false
     * @public
     */
    isImageRemovable: false,

    /**
     * Callback to validate the image.
     *
     * @property {onValidateImage}
     * @type {Function}
     * @default no-op
     * @public
     */
    onValidateImage: function onValidateImage() {},

    /**
     * Action invoked on upload error.
     *
     * @property {onError}
     * @type {Function}
     * @default no-op
     * @public
     */
    onError: function onError() {},

    /**
     * Action fired when image is removed.
     *
     * @property {onRemoveImage}
     * @type {Function}
     * @default no-op
     * @public
     */
    onRemoveImage: function onRemoveImage() {},

    /**
     * Action fired when an image is selected.
     *
     * @property {onDrop}
     * @type {Function}
     * @default no-op
     * @public
     */
    onDrop: function onDrop() {},
    dropZoneClasses: (0, _object.computed)('iconUrl', function () {
      var classes = ['image-uploader-drop-zone'];

      if ((0, _utils.isPresent)(this.iconUrl)) {
        classes.push('has-preview');
      }

      return classes.join(' ');
    }),
    actions: {
      onRemoveImage: function onRemoveImage(uploaderResetAction) {
        uploaderResetAction();
        this.onRemoveImage(); // TODO: polaris-drop-zone doesn't support removal
        // of images based on its current design. So we're
        // resetting it ourselves.

        this.element.querySelector('input[type="file"]').value = '';
      },
      onUploadComplete: function onUploadComplete(_ref) {
        var uploadedKey = _ref.uploadedKey,
            uploadedUrl = _ref.uploadedUrl;
        this.onDrop({
          iconUrl: uploadedUrl,
          iconS3Key: uploadedKey
        });
      }
    }
  });

  _exports.default = _default;
});