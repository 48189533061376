define("ember-smile-core/templates/components/tab-panel-card/tab-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pRj8Zyjf",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"tabs\",\"initialTab\",\"isLoading\",\"onMarkTabComplete\"],[[33,2],[33,3],[33,4],[28,[37,5],[[30,0],[33,6]],null]]],[[\"default\"],[[[[1,\"    \"],[18,3,[[30,1],[30,2]]],[1,\"\\n\"]],[1,2]]]]]],[]]]]]],[\"panelContent\",\"tab\",\"&default\"],false,[\"polaris-card/section\",\"tab-panel\",\"tabs\",\"initialTab\",\"isLoading\",\"action\",\"onMarkTabComplete\",\"yield\"]]",
    "moduleName": "ember-smile-core/templates/components/tab-panel-card/tab-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});