define("ember-smile-core/components/resource-list-wrapper", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-smile-core/templates/components/resource-list-wrapper"], function (_exports, _component, _object, _computed, _utils, _resourceListWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Wrapper component to provide consistent appearance and behaviour
   * between Smile resource lists. This component adds the following
   * to the default Polaris resource list component:
   *  - provides dummy items to render during loading;
   *  - allows centering shortcut actions vertically within rows;
   *  - renders optional pagination control with correct positioning.
   */
  var _default = _component.default.extend({
    classNames: ['resource-list-wrapper'],
    classNameBindings: ['shouldCenterShortcutActions:with-centered-shortcut-actions'],
    layout: _resourceListWrapper.default,

    /**
     * @property isLoading
     * @type {Boolean}
     * @default false
     * @public
     */
    isLoading: false,

    /**
     * @property items
     * @type {Object[]}
     * @default null
     * @public
     */
    items: null,

    /**
     * Flag to override default shortcut action vertical alignment
     * to center them within the resource list row.
     * Defaults to `true` since that's currently our most common use case,
     * pass in `false` to use Polaris's default alignment.
     *
     * @property shouldCenterShortcutActions
     * @type {Boolean}
     * @default true
     * @public
     */
    shouldCenterShortcutActions: true,

    /**
     * @property paginationControl
     * @type {Component|Object}
     * @default null
     * @public
     */
    paginationControl: null,

    /**
     * @property shouldShowPaginationControl
     * @type {Boolean}
     * @public
     */
    shouldShowPaginationControl: (0, _computed.notEmpty)('items'),
    dummyItems: (0, _object.computed)(function () {
      return new Array(3);
    }),
    isShowingDummyItems: (0, _object.computed)('items.length', 'isLoading', function () {
      var items = this.items,
          isLoading = this.isLoading;
      return isLoading && (0, _utils.isEmpty)(items);
    })
  });

  _exports.default = _default;
});