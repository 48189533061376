define("ember-smile-core/templates/components/chip-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LrS02CT0",
    "block": "[[[10,1],[14,0,\"Chip-Button__Text\"],[12],[1,[34,0]],[13],[1,\"\\n\"]],[],false,[\"text\"]]",
    "moduleName": "ember-smile-core/templates/components/chip-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});