define("@smile-io/ember-smile-polaris/templates/components/polaris-display-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Uowa2kIJ",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,1],[30,2],\"p\"],null]],null],0,null,[[\"tagName\"],[[28,[37,3],[[30,1],[30,2],\"p\"],null]]]]],[[[1,\"  \"],[8,[30,3],[[16,0,[29,[\"Polaris-DisplayText \",[30,0,[\"sizeClassName\"]],\" \",[30,4]]]],[24,\"data-test-display-text\",\"\"],[17,5]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"      \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,6]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[3]]]],[\"@htmlTag\",\"@tagName\",\"DisplayText\",\"@class\",\"&attrs\",\"@text\",\"&default\"],false,[\"let\",\"component\",\"-element\",\"or\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-display-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});