define("@smile-io/ember-smile-polaris/templates/components/polaris-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2qKYInup",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,0,[30,0,[\"linkClass\"]]]],[[\"@url\",\"@external\",\"@onClick\"],[[30,1],[30,2],[30,0,[\"handleClick\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,3]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[16,0,[30,0,[\"linkClass\"]]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"handleClick\"]]],null]],null],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,3]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"@url\",\"@external\",\"@text\",\"&default\"],false,[\"if\",\"polaris-unstyled-link\",\"has-block\",\"yield\",\"on\",\"stop-propagation\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});