define("@smile-io/ember-smile-polaris/templates/components/polaris-action-list/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "heReYTjb",
    "block": "[[[10,\"li\"],[15,\"role\",[30,1]],[15,\"aria-selected\",[30,0,[\"active\"]]],[12],[1,\"\\n  \"],[11,\"button\"],[16,0,[30,0,[\"itemClasses\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"onAction\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-ActionList__Content\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,0],[14,0,\"Polaris-ActionList__Image\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@keepFills\",\"@source\"],[false,[30,2]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"        \"],[10,0],[14,\"role\",\"presentation\"],[14,0,\"Polaris-ActionList__Image\"],[15,5,[30,0,[\"imageBackgroundStyle\"]]],[12],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]],[1,\"\\n      \"],[10,0],[14,0,\"Polaris-ActionList__Text\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"          \"],[10,0],[12],[1,\"\\n            \"],[10,0],[12],[1,[30,5]],[13],[1,\"\\n            \"],[8,[39,3],null,[[\"@variation\",\"@text\"],[\"subdued\",[30,4]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@role\",\"@icon\",\"@image\",\"@helpText\",\"@text\"],false,[\"on\",\"if\",\"polaris-icon\",\"polaris-text-style\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-action-list/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});