define("@smile-io/ember-smile-polaris/templates/components/polaris-footer-help", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8CX29hU+",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-FooterHelp \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-FooterHelp__Content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"Polaris-FooterHelp__Icon\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@source\",\"@color\",\"@backdrop\"],[\"help\",\"teal\",true]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"Polaris-FooterHelp__Text\"],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,3]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@text\",\"&default\"],false,[\"polaris-icon\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-footer-help.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});