define("ember-svg-jar/inlined/polaris/save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17 4h-3a1 1 0 100 2h2v10H4V4h3.586L9 5.414v5.172L7.707 9.293a1 1 0 00-1.414 1.414l3 3a.996.996 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V5a.997.997 0 00-.293-.707l-2-2A.994.994 0 008 2H3a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1V5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});