define("ember-svg-jar/inlined/sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.817 18.182c-3.685 0-6.808-2.451-7.828-5.808L6.362 10H.908v5.455l1.66-1.661C4.068 17.43 7.647 20 11.818 20c4.893 0 8.968-3.535 9.826-8.182h-1.855c-.83 3.638-4.086 6.364-7.971 6.364m9.248-11.976C19.567 2.57 15.99 0 11.817 0 6.924 0 2.849 3.535 1.991 8.182h1.855c.83-3.638 4.086-6.364 7.971-6.364 3.686 0 6.808 2.451 7.828 5.808L17.272 10h5.454V4.546l-1.66 1.66z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "20",
      "viewBox": "0 0 23 20"
    }
  };
  _exports.default = _default;
});