define("ember-smile-core/components/read-only-text-field-actions", ["exports", "@ember/component", "@ember/object/computed", "ember-smile-core/templates/components/read-only-text-field-actions"], function (_exports, _component, _computed, _readOnlyTextFieldActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _readOnlyTextFieldActions.default,

    /**
     * When true, will render a copy button
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    copyable: false,

    /**
     * When true, will show a togglable Show/Hide button.
     * Text field value is hidden on initial render, by default.
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    hideable: false,

    /**
     * When present, a delete button will be rendered and this callback will be invoked
     * when the button is pressed
     *
     * @type {Function}
     * @default no-op
     * @public
     */
    onDelete: null,

    /**
     * The value that will be copied
     *
     * @type {String}
     * @default null
     * @public
     */
    value: null,

    /**
     * When true, the text field's value is masked
     *
     * @type {Boolean}
     * @public
     */
    isMasked: (0, _computed.reads)('hideable'),

    /**
     * Callback for when the copy was successful
     *
     * @type {Function}
     * @default no-op
     * @public
     */
    onCopySuccess: function onCopySuccess() {},

    /**
     * Callback for when the copy was unsuccessful
     *
     * @type {Function}
     * @default no-op
     * @public
     */
    onCopyError: function onCopyError() {},
    deleteIcon: 'delete',
    copyIcon: 'smile/clipboard'
  });

  _exports.default = _default;
});