define("ember-smile-core/components/polaris-app-top-bar-branding", ["exports", "@ember/component", "ember-smile-core/templates/components/polaris-app-top-bar-branding"], function (_exports, _component, _polarisAppTopBarBranding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['top-bar-branding', 'top-bar-item'],
    layout: _polarisAppTopBarBranding.default
  });

  _exports.default = _default;
});