define("@smile-io/ember-smile-polaris/templates/components/polaris-form-layout/group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "K6Gpjn1E",
    "block": "[[[11,0],[16,0,[29,[[30,1],\" \",[52,[30,2],\"Polaris-FormLayout--condensed\",\"Polaris-FormLayout--grouped\"]]]],[24,\"data-test-form-layout-group\",\"\"],[16,\"aria-labelledby\",[30,0,[\"titleID\"]]],[16,\"aria-describedby\",[30,0,[\"helpTextID\"]]],[17,3],[24,\"role\",\"group\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,0],[15,1,[30,0,[\"titleID\"]]],[14,0,\"Polaris-FormLayout__Title\"],[14,\"data-test-form-layout-group-title\",\"\"],[12],[1,\"\\n      \"],[1,[30,4]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[11,0],[24,0,\"Polaris-FormLayout__Items\"],[24,\"data-test-form-layout-group-items\",\"\"],[4,[38,1],[[30,0,[\"setupAutoWrapper\"]]],null],[4,[38,2],[[30,0,[\"teardownAutoWrapper\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"      \"],[18,7,[[28,[37,5],null,[[\"item\"],[[50,\"polaris-form-layout/item\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"    \"],[10,0],[15,1,[30,0,[\"helpTextID\"]]],[14,0,\"Polaris-FormLayout__HelpText\"],[14,\"data-test-form-layout-group-helpText\",\"\"],[12],[1,\"\\n      \"],[1,[28,[35,7],[[30,6]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"@condensed\",\"&attrs\",\"@title\",\"@text\",\"@helpText\",\"&default\"],false,[\"if\",\"did-insert\",\"will-destroy\",\"has-block\",\"yield\",\"hash\",\"component\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-form-layout/group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});