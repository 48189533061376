define("@smile-io/ember-smile-polaris/templates/components/wrapper-element", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uWgVeAdP",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/wrapper-element.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});