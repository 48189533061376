define("@smile-io/ember-smile-polaris/templates/components/polaris-visually-hidden", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "n4QWLVld",
    "block": "[[[11,1],[16,0,[29,[\"Polaris-VisuallyHidden \",[30,1]]]],[17,2],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,3]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@text\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-visually-hidden.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});