define("@smile-io/ember-smile-polaris/templates/components/polaris-layout/annotated-section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "85mmciqb",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-Layout__AnnotatedSection \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-Layout__AnnotationWrapper\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@description\"],[[30,3],[30,4]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"        \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@title\",\"@description\",\"@text\",\"&default\"],false,[\"polaris-layout/annotation\",\"polaris-layout/annotation-content\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-layout/annotated-section.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});