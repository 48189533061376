define("ember-svg-jar/inlined/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>CustomersIcon@2x</title><g fill=\"#919EAB\" fill-rule=\"evenodd\"><path d=\"M7.778 14c0-1.841 1.288-3 3.333-3 2.046 0 3.333 1.159 3.333 3H7.778zM13.333 8c0 1.104-.995 2-2.222 2-1.227 0-2.222-.896-2.222-2s.995-2 2.222-2c1.227 0 2.222.896 2.222 2\"/><path d=\"M4.444 18v-2h1.112v-2H4.444v-3h1.112V9H4.444V6h1.112V4H4.444V2h13.334v16H4.445zM17.778 0H4.444C3.22 0 2.222.896 2.222 2v2H0v2h2.222v3H0v2h2.222v3H0v2h2.222v2c0 1.103.997 2 2.222 2h13.334C19.003 20 20 19.103 20 18V2c0-1.104-.997-2-2.222-2z\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});