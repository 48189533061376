define("@smile-io/ember-smile-polaris/utils/dates", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getYearForRange = getYearForRange;
  _exports.getMonthForRange = getMonthForRange;
  _exports.abbreviationForWeekday = abbreviationForWeekday;
  _exports.getWeeksForMonth = getWeeksForMonth;
  _exports.dateIsInRange = dateIsInRange;
  _exports.dateIsSelected = dateIsSelected;
  _exports.isSameDay = isSameDay;
  _exports.getNewRange = getNewRange;
  _exports.getNextDisplayMonth = getNextDisplayMonth;
  _exports.getNextDisplayYear = getNextDisplayYear;
  _exports.getPreviousDisplayMonth = getPreviousDisplayMonth;
  _exports.getPreviousDisplayYear = getPreviousDisplayYear;
  _exports.isDateAfter = isDateAfter;
  _exports.isDateBefore = isDateBefore;
  _exports.getWeekdaysOrdered = getWeekdaysOrdered;
  _exports.monthsArray = _exports.weekdaysArray = _exports.weekdays = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var weekdays = {
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday'
  };
  _exports.weekdays = weekdays;
  var weekdaysArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  _exports.weekdaysArray = weekdaysArray;
  var monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  _exports.monthsArray = monthsArray;

  function getYearForRange(_ref) {
    var start = _ref.start,
        end = _ref.end;

    if ((0, _utils.isPresent)(start)) {
      return start.getFullYear();
    }

    if ((0, _utils.isPresent)(end)) {
      return end.getFullYear();
    }

    return new Date().getFullYear();
  }

  function getMonthForRange(_ref2) {
    var start = _ref2.start,
        end = _ref2.end;

    if ((0, _utils.isPresent)(start)) {
      return start.getMonth();
    }

    if ((0, _utils.isPresent)(end)) {
      return end.getMonth();
    }

    return new Date().getMonth();
  }

  function abbreviationForWeekday(weekday) {
    return weekdays[weekday].substring(0, 2);
  }

  var WEEK_LENGTH = 7;

  function getWeeksForMonth(month, year, weekStartsOn) {
    var firstOfMonth = new Date(year, month, 1);
    var firstDayOfWeek = firstOfMonth.getDay();
    var weeks = [[]];
    var currentWeek = weeks[0];
    var currentDate = firstOfMonth;
    var orderedWeekday = getWeekdaysOrdered(weekStartsOn);

    for (var i = 0; i < orderedWeekday.indexOf(weekdaysArray[firstDayOfWeek]); i++) {
      currentWeek.push(null);
    }

    while (currentDate.getMonth() === month) {
      if (currentWeek.length === WEEK_LENGTH) {
        currentWeek = [];
        weeks.push(currentWeek);
      }

      currentWeek.push(currentDate);
      currentDate = new Date(year, month, currentDate.getDate() + 1);
    }

    while (currentWeek.length < 7) {
      currentWeek.push(null);
    }

    return weeks;
  }

  function dateIsInRange(day, range) {
    if ((0, _utils.isNone)(day)) {
      return false;
    }

    var start = range.start,
        end = range.end;
    return (0, _utils.isPresent)(start) && day > start && (0, _utils.isPresent)(end) && day < end;
  }

  function dateIsSelected(day, range) {
    if ((0, _utils.isNone)(day)) {
      return false;
    }

    var start = range.start,
        end = range.end;
    return (0, _utils.isPresent)(start) && isSameDay(start, day) || (0, _utils.isPresent)(end) && isSameDay(end, day);
  }

  function isSameDay(day1, day2) {
    return day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();
  }

  function getNewRange(range, selected) {
    if ((0, _utils.isNone)(range)) {
      return {
        start: selected,
        end: selected
      };
    }

    var start = range.start,
        end = range.end;

    if ((0, _utils.isPresent)(end) && start !== end) {
      return {
        start: selected,
        end: selected
      };
    }

    if ((0, _utils.isPresent)(start)) {
      if (selected < start) {
        return {
          start: selected,
          end: selected
        };
      }

      return {
        start: start,
        end: selected
      };
    }

    if ((0, _utils.isPresent)(end)) {
      if (selected < end) {
        return {
          start: selected,
          end: end
        };
      }

      return {
        start: start || end,
        end: selected
      };
    }

    return {
      start: selected,
      end: selected
    };
  }

  function getNextDisplayMonth(month) {
    if (month === 11) {
      return 0;
    }

    return month + 1;
  }

  function getNextDisplayYear(month, year) {
    if (month === 11) {
      return year + 1;
    }

    return year;
  }

  function getPreviousDisplayMonth(month) {
    if (month === 0) {
      return 11;
    }

    return month - 1;
  }

  function getPreviousDisplayYear(month, year) {
    if (month === 0) {
      return year - 1;
    }

    return year;
  }

  function isDateAfter(date, dateToCompare) {
    return date.getTime() > dateToCompare.getTime();
  }

  function isDateBefore(date, dateToCompare) {
    return date.getTime() < dateToCompare.getTime();
  }

  function getWeekdaysOrdered(weekStartsOn) {
    var weekDays = [].concat(weekdaysArray);
    var restOfDays = weekDays.splice(weekStartsOn);
    return [].concat(_toConsumableArray(restOfDays), _toConsumableArray(weekDays));
  }
});