define("ember-smile-core/templates/components/footer-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+78bvUeu",
    "block": "[[[10,0],[12],[1,[30,1]],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@primary\",\"@text\",\"@loading\",\"@onClick\"],[[30,2,[\"primary\"]],[30,2,[\"content\"]],[30,2,[\"loading\"]],[28,[37,1],[[30,0],[30,2,[\"onClick\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"  \"],[10,0],[14,0,\"dismiss-footer-banner\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@disabled\",\"@color\",\"@icon\",\"@accessibilityLabel\",\"@plain\",\"@onClick\"],[[30,2,[\"loading\"]],\"white\",\"cancel-small\",\"Dismiss notification\",true,[28,[37,1],[[30,0],[30,3]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@text\",\"@primaryAction\",\"@onDismiss\"],false,[\"polaris-button\",\"action\",\"if\"]]",
    "moduleName": "ember-smile-core/templates/components/footer-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});