define("@smile-io/ember-smile-polaris/templates/components/polaris-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "x5uPPVyQ",
    "block": "[[[11,1],[16,0,[30,0,[\"cssClasses\"]]],[24,\"data-test-icon\",\"\"],[16,\"data-test-keep-fills\",[30,0,[\"keepFills\"]]],[17,1],[16,\"aria-label\",[30,2]],[12],[1,\"\\n\"],[41,[30,0,[\"showPlaceholder\"]],[[[1,\"    \"],[10,0],[14,0,\"Polaris-Icon__Placeholder\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,1],[[30,0,[\"iconSource\"]]],[[\"class\",\"focusable\",\"aria-hidden\",\"id\"],[\"Polaris-Icon__Svg\",\"false\",\"true\",[30,0,[\"svgElementId\"]]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@accessibilityLabel\"],false,[\"if\",\"svg-jar\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});