define("@smile-io/ember-smile-polaris/templates/components/polaris-unstyled-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BsvJESA3",
    "block": "[[[11,3],[24,\"data-test-unstyled-link\",\"\"],[24,\"data-polaris-unstyled\",\"true\"],[16,\"data-test\",[30,1]],[16,0,[30,2]],[16,1,[30,3]],[17,4],[16,6,[30,5]],[16,\"download\",[30,6]],[16,\"tabindex\",[30,7]],[16,\"aria-label\",[30,8]],[16,\"aria-describedby\",[30,9]],[16,\"target\",[30,0,[\"target\"]]],[16,\"rel\",[30,0,[\"rel\"]]],[4,[38,0],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,0],[\"focus\",[30,0,[\"onFocus\"]]],null],[4,[38,0],[\"blur\",[30,0,[\"onBlur\"]]],null],[4,[38,0],[\"mouseup\",[30,0,[\"onMouseUp\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,10]],[[[1,\"    \"],[18,10,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,4]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@dataTestId\",\"@class\",\"@id\",\"&attrs\",\"@url\",\"@download\",\"@tabIndex\",\"@ariaLabel\",\"@ariaDescribedBy\",\"&default\"],false,[\"on\",\"if\",\"has-block\",\"yield\",\"text\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-unstyled-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});