define("ember-smile-core/components/toggleable-link", ["exports", "@ember/component", "ember-smile-core/utils/noop", "ember-smile-core/templates/components/toggleable-link"], function (_exports, _component, _noop, _toggleableLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component renders a Polaris link consisting of
   * a chevron (down- or up-facing) with some text.
   */
  var _default = _component.default.extend({
    classNames: ['toggleable-link-component'],
    classNameBindings: ['isToggled:toggled'],
    layout: _toggleableLink.default,

    /**
     * Text to show next to the chevron.
     *
     * @public
     * @type {String}
     * @default null
     */
    text: null,

    /**
     * Specifies whether the link is toggled or not.
     * This will usually be used to expand a section to show content,
     * so when this is falsy a downwards chevron will be rendered.
     * When it's truthy, an upwards chevron will be shown instead.
     *
     * @public
     * @type {boolean}
     * @default false
     */
    isToggled: false,

    /**
     * When true, will show the icon before text. By default,
     * will show it after.
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    isIconBeforeText: false,

    /**
     * Action to call when the link is clicked.
     *
     * @public
     * @type {function}
     * @default noop
     */
    onToggle: _noop.default
  });

  _exports.default = _default;
});