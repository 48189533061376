define("ember-smile-core/utils/normalize-error-response", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeErrorResponse;

  /**
   * Utility to format error payloads returned by our API into a
   * JSON-API format expected by `ember-data`
   *
   * Ex: we have a bunch of diffrent errors returned by the API as
   * { error: { message: 'Error message' }}
   */
  function normalizeErrorResponse(status, headers, payload) {
    if (payload && payload.error) {
      var jsonFormatterError = {
        status: payload.error.status || status,
        // We usually return error under `message` key, for JSON-API should be `detail`
        detail: payload.error.message,
        meta: (0, _polyfills.assign)({}, payload.error.meta, {
          request_id: payload.error.request_id
        })
      };
      payload.errors = [jsonFormatterError];
      delete payload.error;
    } else if (payload.errors && status === 422) {
      // 422's have the request_id inside tha main `payload` object
      payload.errors.forEach(function (err) {
        err.meta = err.meta || {};
        err.meta.request_id = payload.request_id; // TODO #error-handling drop this once API is including `/data/attributes/` prefix

        if (err.source && err.source.pointer && err.source.pointer.indexOf('/data/attributes') === -1) {
          err.source.pointer = "/data/attributes/".concat(err.source.pointer);
        }
      });
    }

    return payload;
  }
});