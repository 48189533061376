define("@smile-io/ember-smile-polaris/templates/components/polaris-avatar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EKPJtXqy",
    "block": "[[[11,1],[24,\"role\",\"img\"],[16,\"aria-label\",[30,0,[\"label\"]]],[16,0,[29,[\"Polaris-Avatar \",[52,[30,0,[\"hasImage\"]],\"Polaris-Avatar--hasImage\"],\" \",[30,0,[\"styleClass\"]],\" \",[30,0,[\"sizeClass\"]],\" \",[30,0,[\"hiddenClass\"]],\" \",[30,1]]]],[17,2],[4,[38,1],[[30,0,[\"resetImage\"]],[30,3]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldShowInitials\"]],[[[1,\"    \"],[10,1],[14,0,\"Polaris-Avatar__Initials\"],[12],[1,\"\\n      \"],[10,\"svg\"],[14,0,\"Polaris-Avatar__Svg\"],[14,\"viewBox\",\"0 0 48 48\"],[12],[1,\"\\n        \"],[10,\"text\"],[14,\"x\",\"50%\"],[14,\"y\",\"50%\"],[14,\"dy\",\"0.35em\"],[14,\"fill\",\"currentColor\"],[14,\"font-size\",\"26\"],[14,\"text-anchor\",\"middle\"],[12],[1,\"\\n          \"],[1,[30,4]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"shouldShowImage\"]],[[[1,\"    \"],[10,\"img\"],[14,0,\"Polaris-Avatar__Image\"],[15,\"src\",[30,0,[\"finalSource\"]]],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[15,\"onload\",[28,[37,2],[[30,0,[\"handleLoad\"]]],null]],[15,\"onerror\",[28,[37,2],[[30,0,[\"handleError\"]]],null]],[12],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@source\",\"@initials\"],false,[\"if\",\"did-update\",\"fn\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-avatar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});