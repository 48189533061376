define("@smile-io/ember-smile-polaris/templates/components/polaris-button/content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hwiENPUB",
    "block": "[[[10,1],[14,0,\"Polaris-Button__Content\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,1],[14,0,\"Polaris-Button__Spinner\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@size\",\"@accessibilityLabel\",\"@color\"],[\"small\",\"Loading\",[30,0,[\"spinnerColor\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,1],[14,0,\"Polaris-Button__Icon\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,2]],null],[[[1,\"        \"],[1,[28,[35,3],[[30,2]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],null,[[\"@source\"],[[52,[30,1],\"placeholder\",[30,2]]]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,5],[[30,3],[48,[30,5]]],null],[[[1,\"    \"],[10,1],[14,0,\"Polaris-Button__Text\"],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"        \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[34,8]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,1],[14,0,\"Polaris-Button__Icon\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@source\"],[[52,[30,1],\"placeholder\",\"caret-down\"]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@loading\",\"@icon\",\"@text\",\"@disclosure\",\"&default\"],false,[\"if\",\"polaris-spinner\",\"is-component-definition\",\"render-content\",\"polaris-icon\",\"or\",\"has-block\",\"yield\",\"text\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-button/content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});