define("ember-smile-core/components/actionable-list", ["exports", "@ember/component", "ember-smile-core/templates/components/actionable-list"], function (_exports, _component, _actionableList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Wrapper around a list of actionable items.
   */
  var _default = _component.default.extend({
    classNames: ['actionable-list'],
    layout: _actionableList.default
  });

  _exports.default = _default;
});