define("@smile-io/ember-smile-polaris/templates/components/polaris-connected/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WKZBZunp",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[17,1],[4,[38,0],[\"focus\",[30,0,[\"handleFocus\"]]],null],[4,[38,0],[\"blur\",[30,0,[\"handleBlur\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-connected/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});