define("@smile-io/ember-smile-polaris/templates/components/polaris-data-table/heading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rm52cgzN",
    "block": "[[[8,[39,0],null,[[\"@header\",\"@height\",\"@text\",\"@contentType\",\"@fixed\",\"@truncate\",\"@defaultSortDirection\",\"@sorted\",\"@sortable\",\"@sortDirection\",\"@onSort\"],[true,[30,0,[\"height\"]],[30,1],[28,[37,1],[[30,2],[30,3]],null],[30,0,[\"isFixed\"]],[30,4],[52,[30,5],[30,6]],[52,[30,5],[28,[37,3],[[30,7],[30,3]],null]],[52,[30,5],[28,[37,1],[[30,5],[30,3]],null]],[52,[30,5],[30,0,[\"direction\"]]],[52,[30,5],[28,[37,4],[[30,8],[30,3]],null]]]],null],[1,\"\\n\"]],[\"@heading\",\"@contentTypes\",\"@headingIndex\",\"@truncate\",\"@sortable\",\"@defaultSortDirection\",\"@sortedColumnIndex\",\"@defaultOnSort\"],false,[\"polaris-data-table/cell\",\"get\",\"if\",\"eq\",\"fn\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-data-table/heading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});