define("ember-smile-core/templates/components/flash-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bFZx6QzF",
    "block": "[[[11,0],[16,0,[29,[\"flash-message \",[30,0,[\"alertType\"]],\" \",[52,[30,0,[\"exiting\"]],\"exiting\"],\" \",[52,[30,0,[\"active\"]],\"active\"]]]],[24,\"role\",\"alert\"],[24,\"aria-live\",\"polite\"],[24,\"data-test-flash-message\",\"true\"],[17,1],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[4,[38,2],[[30,0,[\"onDidInsert\"]]],null],[4,[38,3],[[30,0,[\"onWillDestroy\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"flashIconSVG\"]],[[[1,\"    \"],[10,1],[14,0,\"alert-icon\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@source\"],[[30,0,[\"flashIconSVG\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,1],[14,0,\"alert-body\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"alert-message\"],[14,\"data-test-flash-message-text\",\"\"],[12],[1,[28,[35,5],[[30,2,[\"message\"]]],null]],[13],[1,\"\\n\\n\"],[41,[30,2,[\"action\",\"text\"]],[[[1,\"      \"],[10,\"button\"],[14,0,\"flash-message-action\"],[15,\"onclick\",[28,[37,6],[[33,7]],null]],[14,4,\"button\"],[12],[1,\"\\n        \"],[1,[30,2,[\"action\",\"text\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showCloseButton\"]],[[[1,\"    \"],[10,\"button\"],[14,0,\"close-button\"],[15,\"onclick\",[28,[37,6],[[30,0,[\"onClose\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@source\"],[\"cancel\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@flash\"],false,[\"if\",\"on\",\"did-insert\",\"will-destroy\",\"polaris-icon\",\"html-safe\",\"fn\",\"flashAction\"]]",
    "moduleName": "ember-smile-core/templates/components/flash-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});