define("ember-svg-jar/inlined/polaris/circle-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"10\" cy=\"10\" r=\"9\" fill=\"#FFF\"/><path fill=\"#919EAB\" d=\"M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m1-5v-3a1 1 0 00-1-1H9a1 1 0 100 2v3a1 1 0 001 1h1a1 1 0 100-2m-1-5.9a1.1 1.1 0 100-2.2 1.1 1.1 0 000 2.2\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});