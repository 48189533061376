define("ember-smile-core/helpers/is-validating-changeset", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Helper that returns `true` while `changeset`'s `property` has any
   * async validators running, or if `property` is not present, if the
   * changeset has any async validators running.
   *
   * Example:
   *    {{polaris-text-field
   *      ...
   *      suffix=(if (is-validating-changeset changeset property="email") (component "polaris-spinner"))
   *    }}
   *
   * @export
   * @param {Array} [changeset]   The changeset. Required
   * @param {Object} {property}   The specific property to check if it has any async validators running.
   *                              If not present, entire changeset will be checked.
   */
  var _default = _helper.default.extend({
    subscribeToEvents: function subscribeToEvents(changeset) {
      var _this = this;

      if (this._subscribed) {
        return;
      }

      changeset.on('beforeValidation', function () {
        _this.recompute();
      });
      changeset.on('afterValidation', function () {
        _this.recompute();
      });
      this._subscribed = true;
    },
    compute: function compute(_ref, _ref2) {
      var _ref3 = _slicedToArray(_ref, 1),
          changeset = _ref3[0];

      var property = _ref2.property;
      this.subscribeToEvents(changeset);
      return changeset.isValidating(property);
    }
  });

  _exports.default = _default;
});