define("@smile-io/ember-smile-polaris/templates/components/polaris-color-picker/hue-picker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uEi2S90R",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-ColorPicker__HuePicker \",[30,1]]]],[4,[38,0],[[30,0,[\"setSliderHeight\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@draggerX\",\"@draggerY\",\"@onDraggerHeightChanged\",\"@onChange\"],[0,[30,0,[\"draggerY\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"draggerHeight\"]]],null]],null],[28,[37,4],[[30,0,[\"handleChange\"]]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\"],false,[\"did-insert\",\"polaris-color-picker/slidable\",\"action\",\"mut\",\"fn\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-color-picker/hue-picker.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});