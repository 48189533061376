define("ember-svg-jar/inlined/submit-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>submit_app</title><g fill=\"#919EAB\" fill-rule=\"evenodd\"><path d=\"M6 6.667c0 .89-.444 1.334-1.333 1.334-.89 0-1.334-.445-1.334-1.334s.445-1.333 1.334-1.333S6 5.778 6 6.667zM7.333 7.334L6.001 10h2.666zM4.667 8.667l-2 2.667H6V10H6z\"/><path d=\"M1.333 1.333H8V4h2.667v2.667H12v-3.61L8.943 0h-7.61C.598 0 0 .599 0 1.333v12c0 .736.598 1.334 1.333 1.334H6v-1.334H1.333v-12zM15.805 9.529l-2-2a.666.666 0 00-.943 0l-4.667 4.666a.664.664 0 00-.175.31l-.667 2.667a.667.667 0 00.809.809l2.667-.667a.661.661 0 00.309-.176l4.667-4.667a.666.666 0 000-.942zm-5.479 4.536l-1.41.352.353-1.41 2.064-2.064L12.391 12l-2.065 2.065zm3.007-3.008L12.276 10l1.057-1.057L14.391 10l-1.058 1.057z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});