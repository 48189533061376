define("ember-smile-core/templates/components/polaris-app-top-bar-branding", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0hK1nK23",
    "block": "[[[44,[[52,[30,1],[50,\"link-to\",0,[[30,1]],null],[50,[28,[37,3],[\"a\"],null],0,null,[[\"tagName\"],[\"a\"]]]]],[[[1,\"  \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],[[33,5]],[[\"class\"],[\"branding-logo\"]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@homeRoute\",\"Link\"],false,[\"let\",\"if\",\"component\",\"-element\",\"svg-jar\",\"logoIcon\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-app-top-bar-branding.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});