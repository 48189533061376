define("ember-smile-core/templates/components/polaris-modal-target", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "M9uiGUKj",
    "block": "[[[6,[39,0],null,[[\"name\"],[[33,1]]],[[\"default\"],[[[[41,[30,1],[[[6,[39,3],[[30,1]],[[\"use\",\"class\"],[[33,4],\"polaris-modal-liquid-container\"]],[[\"default\"],[[[[41,[30,2],[[[1,\"        \"],[10,0],[14,0,\"polaris-modal-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"polaris-modal-overlay\"],[15,\"onclick\",[28,[37,5],[[30,0],[30,2,[\"onOutsideClick\"]]],null]],[14,\"role\",\"button\"],[12],[13],[1,\"\\n          \"],[10,0],[15,0,[29,[\"polaris-modal-dialog \",[52,[28,[37,6],[\"isMobile\"],null],\"condensed-mode\"]]]],[12],[1,\"\\n            \"],[46,[30,2,[\"body\"]],null,null,null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[2]]]]]],[]],null]],[1]]]]]],[\"modal\",\"currentModal\"],false,[\"from-elsewhere\",\"targetName\",\"if\",\"liquid-bind\",\"modalAnimation\",\"action\",\"media\",\"component\"]]",
    "moduleName": "ember-smile-core/templates/components/polaris-modal-target.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});