define("@smile-io/ember-smile-polaris/templates/components/polaris-labelled", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JMjSLi8Y",
    "block": "[[[11,0],[16,0,[52,[30,1],\"Polaris-Labelled--hidden\"]],[16,\"data-test-labelled\",[30,0,[\"dataTestLabelled\"]]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"Polaris-Labelled__LabelWrapper\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@id\",\"@hidden\"],[[30,0,[\"id\"]],false]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"mainAction\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@plain\",\"@text\",\"@disabled\",\"@loading\",\"@accessibilityLabel\",\"@onClick\"],[true,[30,0,[\"mainAction\",\"text\"]],[30,0,[\"mainAction\",\"disabled\"]],[30,0,[\"mainAction\",\"loading\"]],[30,0,[\"mainAction\",\"accessibilityLabel\"]],[30,0,[\"mainAction\",\"onAction\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,6,null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldRenderError\"]],[[[1,\"    \"],[10,0],[14,\"data-test-labelled-error\",\"\"],[14,0,\"Polaris-Labelled__Error\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@message\",\"@fieldID\"],[[30,4],[30,0,[\"id\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,0],[14,\"data-test-label-help-text\",\"\"],[14,0,\"Polaris-Labelled__HelpText\"],[15,1,[30,0,[\"helpTextId\"]]],[12],[1,\"\\n      \"],[1,[28,[35,5],[[30,5]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@labelHidden\",\"&attrs\",\"@label\",\"@error\",\"@helpText\",\"&default\"],false,[\"if\",\"polaris-label\",\"polaris-button\",\"yield\",\"polaris-inline-error\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-labelled.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});