define("@smile-io/ember-smile-polaris/utils/id", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.labelId = labelId;
  _exports.errorId = errorId;
  _exports.helpTextId = helpTextId;
  _exports.idVariation = idVariation;
  _exports.computedIdVariation = _exports.computedHelpTextId = _exports.computedErrorId = _exports.computedLabelId = void 0;

  function makeComputedIdGenerator(idGenerator) {
    return function (idPath, variation) {
      return (0, _object.computed)(idPath, function () {
        return idGenerator(this.get(idPath), variation);
      });
    };
  }

  function labelId(id) {
    return idVariation(id, 'Label');
  }

  var computedLabelId = makeComputedIdGenerator(labelId);
  _exports.computedLabelId = computedLabelId;

  function errorId(id) {
    return idVariation(id, 'Error');
  }

  var computedErrorId = makeComputedIdGenerator(errorId);
  _exports.computedErrorId = computedErrorId;

  function helpTextId(id) {
    return idVariation(id, 'HelpText');
  }

  var computedHelpTextId = makeComputedIdGenerator(helpTextId);
  _exports.computedHelpTextId = computedHelpTextId;

  function idVariation(id, suffix) {
    return "".concat(id).concat(suffix);
  }

  var computedIdVariation = makeComputedIdGenerator(idVariation);
  _exports.computedIdVariation = computedIdVariation;
});