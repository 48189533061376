define("@smile-io/ember-smile-polaris/templates/components/polaris-choice/label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "25dTFCdV",
    "block": "[[[11,\"label\"],[16,\"for\",[30,1]],[16,0,[30,0,[\"cssClasses\"]]],[24,\"data-test-choice-label\",\"\"],[17,2],[12],[1,\"\\n  \"],[10,1],[14,0,\"Polaris-Choice__Control\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,1],[14,0,\"Polaris-Choice__Label\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,3]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@inputId\",\"&attrs\",\"@label\",\"&default\"],false,[\"yield\",\"render-content\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-choice/label.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});