define("@smile-io/ember-smile-polaris/templates/components/polaris-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "v/duZG4w",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"hasHeaderContent\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@title\",\"@titleMetadata\",\"@titleHidden\",\"@titleAlignment\",\"@icon\",\"@breadcrumbs\",\"@separator\",\"@secondaryActions\",\"@actionGroups\",\"@primaryAction\",\"@pagination\",\"@beforeTitleComponent\",\"@afterTitleComponent\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"Polaris-Page__Content\"],[12],[1,\"\\n\"],[41,[48,[30,16]],[[[1,\"      \"],[18,16,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,15]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"@titleMetadata\",\"@titleHidden\",\"@titleAlignment\",\"@icon\",\"@breadcrumbs\",\"@separator\",\"@secondaryActions\",\"@actionGroups\",\"@primaryAction\",\"@pagination\",\"@beforeTitleComponent\",\"@afterTitleComponent\",\"@text\",\"&default\"],false,[\"if\",\"polaris-page/header\",\"has-block\",\"yield\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-page.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});