define("ember-smile-core/components/no-op", ["exports", "@ember/component", "ember-smile-core/templates/components/no-op"], function (_exports, _component, _noOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Empty component that can be used when we want to wrap content conditionally.
  var _default = _component.default.extend({
    tagName: '',
    layout: _noOp.default
  });

  _exports.default = _default;
});