define("ember-template-compiler/lib/system/compile-options", ["exports", "@ember/debug", "@ember/polyfills", "ember-template-compiler/lib/plugins/index", "ember-template-compiler/lib/system/dasherize-component-name"], function (_exports, _debug, _polyfills, _index, _dasherizeComponentName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCompileOptions = buildCompileOptions;
  _exports.transformsFor = transformsFor;
  _exports.default = compileOptions;
  _exports.registerPlugin = registerPlugin;
  _exports.unregisterPlugin = unregisterPlugin;
  var USER_PLUGINS = [];

  function malformedComponentLookup(string) {
    return string.indexOf('::') === -1 && string.indexOf(':') > -1;
  }

  function buildCompileOptions(_options) {
    var options = (0, _polyfills.assign)({
      meta: {},
      isProduction: false,
      plugins: {
        ast: []
      }
    }, _options, {
      customizeComponentName: function customizeComponentName(tagname) {
        (true && !(!malformedComponentLookup(tagname)) && (0, _debug.assert)("You tried to invoke a component named <" + tagname + " /> in \"" + _options.moduleName + "\", but that is not a valid name for a component. Did you mean to use the \"::\" syntax for nested components?", !malformedComponentLookup(tagname)));
        return _dasherizeComponentName.default.get(tagname);
      }
    });

    if (!true
    /* EMBER_STRICT_MODE */
    ) {
        options.strictMode = false;
        options.locals = undefined;
      } // move `moduleName` into `meta` property


    if (options.moduleName) {
      var meta = options.meta;
      meta.moduleName = options.moduleName;
    }

    return options;
  }

  function transformsFor(options) {
    return true
    /* EMBER_STRICT_MODE */
    && options.strictMode ? _index.STRICT_MODE_TRANSFORMS : _index.RESOLUTION_MODE_TRANSFORMS;
  }

  function compileOptions(_options) {
    if (_options === void 0) {
      _options = {};
    }

    var options = buildCompileOptions(_options);
    var builtInPlugins = transformsFor(options);

    if (!_options.plugins) {
      options.plugins = {
        ast: [].concat(USER_PLUGINS, builtInPlugins)
      };
    } else {
      var potententialPugins = [].concat(USER_PLUGINS, builtInPlugins);
      var providedPlugins = options.plugins.ast.map(function (plugin) {
        return wrapLegacyPluginIfNeeded(plugin);
      });
      var pluginsToAdd = potententialPugins.filter(function (plugin) {
        return options.plugins.ast.indexOf(plugin) === -1;
      });
      options.plugins.ast = providedPlugins.concat(pluginsToAdd);
    } // TODO: Fix the types here so that this conversion isn't necessary


    return options;
  }

  function isLegacyPluginClass(plugin) {
    return plugin.prototype && typeof plugin.prototype.transform === 'function';
  }

  function wrapLegacyPluginIfNeeded(plugin) {
    if (isLegacyPluginClass(plugin)) {
      var Plugin = plugin;
      (true && !(false) && (0, _debug.deprecate)("Using class based template compilation plugins is deprecated, please update to the functional style: " + Plugin.name, false, {
        id: 'template-compiler.registerPlugin',
        until: '4.0.0',
        for: 'ember-source',
        since: {
          enabled: '3.27.0'
        }
      }));

      var pluginFunc = function pluginFunc(env) {
        var pluginInstantiated = false;
        return {
          name: plugin.name,
          visitor: {
            Program: function Program(node) {
              if (!pluginInstantiated) {
                pluginInstantiated = true;
                var instance = new Plugin(env);
                instance.syntax = env.syntax;
                return instance.transform(node);
              }
            }
          }
        };
      };

      pluginFunc.__raw = Plugin;
      return pluginFunc;
    } else {
      return plugin;
    }
  }

  function registerPlugin(type, _plugin) {
    (true && !(false) && (0, _debug.deprecate)('registerPlugin is deprecated, please pass plugins directly via `compile` and/or `precompile`.', false, {
      id: 'template-compiler.registerPlugin',
      until: '4.0.0',
      for: 'ember-source',
      since: {
        enabled: '3.27.0'
      }
    }));

    if (type !== 'ast') {
      throw new Error("Attempting to register " + _plugin + " as \"" + type + "\" which is not a valid Glimmer plugin type.");
    }

    for (var i = 0; i < USER_PLUGINS.length; i++) {
      var PLUGIN = USER_PLUGINS[i];

      if (PLUGIN === _plugin || PLUGIN.__raw === _plugin) {
        return;
      }
    }

    var plugin = wrapLegacyPluginIfNeeded(_plugin);
    USER_PLUGINS = [plugin].concat(USER_PLUGINS);
  }

  function unregisterPlugin(type, PluginClass) {
    (true && !(false) && (0, _debug.deprecate)('unregisterPlugin is deprecated, please pass plugins directly via `compile` and/or `precompile`.', false, {
      id: 'template-compiler.registerPlugin',
      until: '4.0.0',
      for: 'ember-source',
      since: {
        enabled: '3.27.0'
      }
    }));

    if (type !== 'ast') {
      throw new Error("Attempting to unregister " + PluginClass + " as \"" + type + "\" which is not a valid Glimmer plugin type.");
    }

    USER_PLUGINS = USER_PLUGINS.filter(function (plugin) {
      return plugin !== PluginClass && plugin.__raw !== PluginClass;
    });
  }
});