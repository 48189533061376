define("ember-smile-core/templates/components/auth-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "P1zjvHQp",
    "block": "[[[11,0],[24,0,\"auth-page\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"auth-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"illustration illustration-left\"],[12],[13],[1,\"\\n\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[46,[30,2],null,null,null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,\"img\"],[14,0,\"glyph\"],[15,\"src\",[30,3]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"illustration illustration-right\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@authCardComponent\",\"@smileGlyphSource\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"component\"]]",
    "moduleName": "ember-smile-core/templates/components/auth-page.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});