define("@smile-io/ember-smile-polaris/templates/components/polaris-data-table/navigation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Nt1Or6fb",
    "block": "[[[11,0],[16,0,[29,[\"Polaris-DataTable__Navigation \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@plain\",\"@icon\",\"@disabled\",\"@accessibilityLabel\",\"@onClick\"],[true,\"chevron-left\",[30,3],\"Scroll table left one column\",[28,[37,1],[[30,0,[\"navigateTableLeft\"]]],null]]],null],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,4]],null]],null],null,[[[1,\"    \"],[10,0],[15,0,[29,[\"Polaris-DataTable__Pip \",[52,[30,5,[\"isVisible\"]],\"Polaris-DataTable__Pip--visible\"]]]],[12],[13],[1,\"\\n\"]],[5]],null],[1,\"\\n  \"],[8,[39,0],null,[[\"@plain\",\"@icon\",\"@disabled\",\"@accessibilityLabel\",\"@onClick\"],[true,\"chevron-right\",[30,6],\"Scroll table right one column\",[28,[37,1],[[30,0,[\"navigateTableRight\"]]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@isScrolledFarthestLeft\",\"@columnVisibilityData\",\"column\",\"@isScrolledFarthestRight\"],false,[\"polaris-button\",\"fn\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "@smile-io/ember-smile-polaris/templates/components/polaris-data-table/navigation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});