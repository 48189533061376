define("@smile-io/ember-smile-polaris/components/wrapper-element", ["exports", "@ember/component", "@ember/utils", "@ember/string", "@smile-io/ember-smile-polaris/templates/components/wrapper-element"], function (_exports, _component, _utils, _string, _wrapperElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var blacklistedAttributeBindings = ['tagName', 'class'];
  /**
   * This is a simple utility component that lets us do things like wrap block content
   * in a conditional element, since `tagName` can't be a computed property.
   */

  var _default = _component.default.extend({
    attributeBindings: [],
    layout: _wrapperElement.default,
    blacklistedAttributeBindings: blacklistedAttributeBindings,
    updateAttributeBindings: function updateAttributeBindings() {
      if ((0, _utils.isBlank)(this.tagName)) {
        return;
      }

      var attrs = this.attrs,
          blacklistedAttributeBindings = this.blacklistedAttributeBindings;
      var newAttributeBindings = Object.keys(attrs).filter(function (attr) {
        return blacklistedAttributeBindings.indexOf(attr) === -1;
      }).map(function (attr) {
        // Handle multi-word attributes (e.g. `ariaLabel`).
        var dasherizedAttr = (0, _string.dasherize)(attr);
        return dasherizedAttr === attr ? attr : "".concat(attr, ":").concat(dasherizedAttr);
      });
      this.set('attributeBindings', newAttributeBindings);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.updateAttributeBindings();
    }
  });

  _exports.default = _default;
});